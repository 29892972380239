import { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'

export default function CommentTooltip({ comment, icon }) {
  return (
    <Fragment>
      <i
        className={icon || 'font-icons info-icon'}
        data-for={comment}
        data-tip={comment}
      />
      <ReactTooltip effect='solid' id={comment} />
    </Fragment>
  )
}

export function Tooltip({ comment, text }) {
  return (
    <Fragment>
      <span data-for={comment} data-tip={comment}>
        {text}
      </span>
      <ReactTooltip effect='solid' id={comment} />
    </Fragment>
  )
}
