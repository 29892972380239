import { useEffect, useState } from 'react'
import { calculateRoomPrice } from '../components/resort/pricing/helpers'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import filter from 'lodash/filter'
import omitBy from 'lodash/omitBy'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import {
  setLimit,
  isBelongsTo,
  getBelongsToId,
  setCourseType,
} from '../components/account/booking-details/helpers'
import API from 'api'
import moment from 'moment'
import { prepareGuestSplitting } from '../components/resort/ResortPricing'
import {
  generateSeoStringForGuests,
  getTextFromInfo,
  getTotalGuests,
  parseQuery,
  roundPrice,
} from '../utils'
import { updateDiverInclusions } from '../actions/resort-checkout-actions'
import { convertCurrency } from '../components/helpers/PriceConverter'
import { trackGA4Ecommerce } from '../gtm'
import store from 'store2'
import { getInclusionPrice } from '../components/checkout-la/hooks/useGTM'

export function parsePreselectedDivingItems() {
  const params = parseQuery()
  const divingItems = []

  Object.entries(params).forEach(([key]) => {
    if(key.indexOf('diving_') !== -1) {
      const optionKey = key.split('_')

      divingItems.push({
        pricing: optionKey[0],
        quantity: parseInt(optionKey[3], 10),
        diverNumber: parseInt(optionKey[2], 10),
        numberOfInstances: parseInt(optionKey[3], 10),
      })
    }
  })

  return divingItems
}

export function setGtmItemTitle(val) {
  let title = val.title
  const belongsTo = parseInt(val.belongsTo, 10)

  if(belongsTo === getBelongsToId('Rental equipment')) {
    title += ' Hire'
  } else if(belongsTo === getBelongsToId('Sustainability')) {
    title = 'Carbon Offset - SeaGrass Grow Program'
  } else if(val.divingItem) {
    title = 'Number of dives'
  }

  return title
}

export function setGtmDataForSelectedInclusions(
  selectedOptions,
  currency,
  shopTitle,
  shopId,
) {
  let gtmProducts = []
  let gtmGA4Products = []
  selectedOptions.forEach(function(val) {
    if(
      (val.payedInAdvance && val.quantity && !val.isRoom) ||
      val.isInsurance
    ) {
      gtmProducts.push({
        name: setGtmItemTitle(val),
        id: val.pricingId || val.extraId || val.id || shopId,
        price: roundPrice(
          convertCurrency(val.isIncluded ? 0 : val.priceGross, currency, 'USD'),
        ),
        brand: `${shopTitle}|${window.affiliateId}`,
        category: 'Dive Resort',
        quantity: val.quantity,
      })
      gtmGA4Products.push({
        item_name: setGtmItemTitle(val),
        item_id: (val.pricingId || val.extraId || val.id || shopId).toString(),
        price: Number(
          roundPrice(convertCurrency(getInclusionPrice(val), currency, 'USD')),
        ),
        item_brand: 'PADI',
        item_category: 'PADI Travel',
        item_category2: 'Dive Resort',
        item_list_name: 'PADI Travel Dive Resorts - Product Page',
        quantity: val.quantity,
      })
    }
  })

  return {
    gtmProducts,
    gtmGA4Products,
  }
}

export function useResortInclusions({
  isFixedPackage,
  packages,
  rate,
  roomId,
  shopTitle,
  shopSlug,
  shopId,
  mealPlan,
}) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [ratePrice, setRatePrice] = useState(0)
  const [resortData, setResortData] = useState({})
  const filters = useSelector((state) => ({
    date_after: get(state, 'departureDate.dateFrom'),
    date_before: get(state, 'departureDate.dateTo'),
    nights: get(state, 'departureDate.duration'),
    guests_split: prepareGuestSplitting(get(state, 'guestsInRoom.details', {})),
  }))
  const roomParams = useSelector((state) => state.guestsInRoom)
  const shopCurrency = useSelector((state) => state.shopCurrency)

  useEffect(() => {
    const queryParams = {
      ...filters,
    }

    if(isFixedPackage) {
      queryParams.packages = packages
    } else if(rate) {
      queryParams.rate = rate
    }
    let diverCoursesList = []
    let nonDiverCoursesList = []
    let studentCoursesList = []
    let optionsCurrency = shopCurrency

    Promise.all([
      API(`booking/resort/checkout/${roomId}/data/`).get(queryParams),
      API(`booking/resort/${shopSlug}/base-product/`).get(),
      API(`shop/${shopSlug}/products/`).get(),
      API('booking/insurances').get(),
    ]).then(([rooms, diveProducts, courses, insurances]) => {
      const selectedRoom = calculateRoomPrice({
        room: rooms[0],
        nights: filters.nights,
        title: shopTitle,
        isFixedPackage,
        roomParams,
        trackEvent: false,
      })
      let roomPricingIds = []

      if(isFixedPackage) {
        const roomIds = find(
          selectedRoom.rates,
          (rate) => rate.mealPlan === Number(mealPlan),
        ).ids

        roomIds.forEach((item) => {
          roomPricingIds.push({
            isRoom: true,
            pricing: item.pricing,
            quantity: item.quantity,
          })
        })
        let dives = []
        for(let i = 0; i < roomParams.divers; i++) {
          dives.push({
            numberOfInstances: selectedRoom.divesAmount,
            divingItem: true,
          })
        }
        dispatch(updateDiverInclusions(dives))
      } else {
        roomPricingIds = get(selectedRoom, 'roomPricingIds')
      }

      let selectedRatePrice = 0
      if(isFixedPackage) {
        const rate = find(
          selectedRoom.rates,
          (rate) => rate.mealPlan === Number(mealPlan),
        )
        if(rate) {
          selectedRatePrice = rate.price
        }
      } else {
        selectedRatePrice = get(selectedRoom, 'rates[0].ratePrice', 0)
      }
      setRatePrice(selectedRatePrice)

      courses.forEach((course) => {
        course.isCourse = true
        course.maxLimit = 1
        course.quantity = 0
        optionsCurrency = course.currency

        if(course.pricing) {
          course.pricingId = course.pricing.id
          course.priceGross = course.pricing.priceGross
          course.source = course.pricing.source
          course.payedInAdvance = true
        }
        if(course.pricingId && course.priceGross > 0) {
          if(course.isDiverApplicable) {
            diverCoursesList.push(course)
          }
          if(course.isNondiverApplicable) {
            nonDiverCoursesList.push(course)
          }
          if(course.isStudentApplicable) {
            studentCoursesList.push(course)
          }
        }
      })
      API(`booking/resort/inclusions/${shopSlug}/checkout/`)
        .get(queryParams)
        .then((inclusions) => {
          const params = parseQuery()
          let maxDives = diveProducts.maxPreorder * (filters.nights - 1)
          if(!maxDives) {
            maxDives = selectedRoom.divesAmount * roomParams.divers
          }
          if(maxDives > 100) {
            maxDives = 100
          }
          const filteredInclusions = filter(inclusions, function(inclusion) {
            inclusion = setCourseType(inclusion)
            inclusion.maxLimit = setLimit(20, inclusion, {
              duration: filters.nights,
              numberOfDives: maxDives,
              diversNumber: roomParams.divers,
              totalGuests: getTotalGuests(roomParams),
              studentsNumber: roomParams.students,
              nonDiversNumber: roomParams.nonDivers,
              numberOfRooms: get(roomParams, 'details.length'),
            })

            if(isBelongsTo(inclusion.belongsTo, 'Transfers')) {
              inclusion.isTransfer = true
            }
            if(isBelongsTo(inclusion.belongsTo, 'Sustainability')) {
              inclusion.isOffsetProduct = true
            }
            if(inclusion.isMandatory || inclusion.isIncluded) {
              inclusion.quantity = inclusion.maxLimit
            }
            // prepopulate inclusions if it's available in the query params
            if(params[`incl_${inclusion.pricingId}`]) {
              inclusion.quantity = parseInt(
                params[`incl_${inclusion.pricingId}`],
                10,
              )
            }

            return (
              inclusion.isBookableInAdvance &&
              inclusion.pricingId &&
              ((inclusion.isIncluded && !inclusion.isMandatory) ||
                (inclusion.payedInAdvance && inclusion.price) ||
                (inclusion.payedInAdvance === false &&
                  (inclusion.price || inclusion.extraValue)))
            )
          })
          const groupedInclusions = groupBy(
            sortBy(filteredInclusions, ['isMandatory', 'isIncluded']),
            'belongsTo',
          )
          const rentalEquipmentItems = get(
            groupedInclusions,
            `[${getBelongsToId('Rental equipment')}]`,
            [],
          )
          const extras = get(
            groupedInclusions,
            `[${getBelongsToId('DC Extras & Surcharge')}]`,
            [],
          )
          const resortInclusions = map(
            omitBy(groupedInclusions, (_, key) => {
              return (
                parseInt(key, 10) === getBelongsToId('DC Extras & Surcharge') ||
                parseInt(key, 10) === getBelongsToId('Rental equipment')
              )
            }),
          )

          const insurance = get(insurances, '[0]', {})
          let selectedRoomRate = {}
          if(isFixedPackage) {
            selectedRoomRate = find(
              selectedRoom.rates,
              (rate) => rate.mealPlan === Number(mealPlan),
            )
          } else {
            selectedRoomRate = get(selectedRoom, 'rates[0]', {})
          }
          // I didn't use fill with object, because it's not creates a copy of object.
          const diversArray = Array(roomParams.divers)
            .fill(null)
            .map(() => {
              return {
                diveInclusions: {
                  quantity: selectedRoom.divesAmount,
                  numberOfDivesIncluded: selectedRoom.divesAmount,
                  isTwoTank: diveProducts.isTwoTank,
                  maxPreorder: maxDives || undefined,
                  divingItem: true,
                  source: get(diveProducts, '[0].source', 'dive_product'),
                },
                rentalEquipment: cloneDeep(rentalEquipmentItems),
                courses: cloneDeep(diverCoursesList),
                extras: cloneDeep(extras),
              }
            })
          const nonDiversArray = Array(roomParams.nonDivers)
            .fill(null)
            .map(() => ({
              courses: cloneDeep(nonDiverCoursesList),
            }))

          const studentsArray = Array(roomParams.students)
            .fill(null)
            .map(() => ({
              courses: studentCoursesList.map((item) => {
                return {
                  ...item,
                  quantity: 1,
                  isIncluded: true,
                }
              }),
            }))
          const preselectedItems = parsePreselectedDivingItems()
          const isFreeInsurance =
            convertCurrency(
              selectedRatePrice,
              shopCurrency,
              insurance.limitPriceCurrency,
            ) /
              roomParams.totalGuests >
            Number(insurance.limitPrice)
          const insuranceItem = isFreeInsurance
            ? [
              {
                ...insurance,
                pricingId: get(insurance, 'prices[0].id'),
                price: get(insurance, 'prices[0].priceGross'),
                currency: get(insurance, 'prices[0].priceCurrency'),
                maxLimit: roomParams.divers + roomParams.students,
                source: get(insurance, 'prices[0].source'),
                isInsurance: true,
              },
            ]
            : []

          if(preselectedItems.length) {
            try {
              preselectedItems.forEach((item) => {
                if(diversArray[item.diverNumber]) {
                  diversArray[item.diverNumber].diveInclusions.quantity =
                    item.quantity
                }
              })
            } catch (e) {
              throw new Error(
                'Issue with query parameters for diving items',
                e,
              )
            }
          }
          try {
            if(params) {
              const fields = ['rentalEquipment', 'courses', 'extras']
              diversArray.forEach((diverData, index) => {
                fields.forEach((field) => {
                  if(diverData[field]) {
                    diverData[field].forEach((item) => {
                      // 10 - diver guest type
                      const quantity =
                        params[`incl_${item.pricingId}_${index}_10`]
                      if(quantity) {
                        item.quantity = parseInt(quantity, 10)
                      }
                    })
                  }
                })
              })
              nonDiversArray.forEach((nonDiverData, index) => {
                nonDiverData.courses.forEach((item) => {
                  // 20 - non-diver guest type
                  const quantity = params[`incl_${item.pricingId}_${index}_20`]
                  if(quantity) {
                    item.quantity = parseInt(quantity, 10)
                  }
                })
              })
              if(insuranceItem.length) {
                const insuranceQuantity =
                  params[`incl_${insuranceItem[0].pricingId}`]
                if(insuranceQuantity) {
                  insuranceItem[0].quantity = insuranceQuantity
                }
              }
            }
          } catch (e) {
            throw new Error('Issue with query parameters for items', e)
          }
          const ecommerceProduct = {
            variant: `${selectedRoom.title} / ${moment(filters.date_after)
              .utc()
              .format('DD MMM')}-${moment(filters.date_before)
              .utc()
              .format('DD MMM')} / Rate: ${
              selectedRoomRate.title
            } / ${generateSeoStringForGuests(roomParams)}`,
            id: selectedRoomRate.id,
            price: roundPrice(
              convertCurrency(selectedRatePrice, shopCurrency, 'USD'),
            ),
            brand: `${shopTitle}|${window.affiliateId}`,
            name: shopTitle,
            category: 'Dive Resort',
            quantity: 1,
          }

          const { rates, title, id } = selectedRoom
          const ga4EcommerceProduct = {
            item_variant: title,
            item_id: id.toString(),
            price: Number(
              roundPrice(
                convertCurrency(selectedRatePrice, shopCurrency, 'USD'),
              ),
            ),
            item_brand: 'PADI',
            item_name: shopTitle,
            item_category: 'PADI Travel',
            item_category2: 'Dive Resort',
            item_list_name: 'PADI Travel Dive Resorts - Product Page',
            rate_type: getTextFromInfo(
              rates[0].mealPlan,
              'shop',
              'RESORT_MEAL_PLANS',
            ),
            item_category3: '',
            item_category4: '',
            item_category5: '',
            quantity: 1,
          }
          const { gtmGA4Products } = setGtmDataForSelectedInclusions(
            filteredInclusions,
            shopCurrency,
            shopTitle,
            shopId,
          )
          const items = [ga4EcommerceProduct, ...gtmGA4Products]
          trackGA4Ecommerce(
            'view_cart',
            {
              currency: 'USD',
              value: items.reduce(
                (acc, item) => acc + item.price * item.quantity,
                0,
              ),
              items,
            },
            'PADI Travel - Dive Resorts',
          )

          store.session.set('ecommerceProduct', [ecommerceProduct])
          store.session.set('ga4EcommerceProduct', [ga4EcommerceProduct])

          setResortData({
            diveType: getTextFromInfo(
              selectedRoom.diveType,
              'shop',
              'DIVE_TYPE',
            ),
            packageDiveKind: selectedRoom.packageDiveKind,
            groupedInclusions,
            freeCancellationDays: selectedRoom.freeCancellationDays,
            inclusions: filteredInclusions,
            insurances: insuranceItem,
            diversArray,
            roomParams,
            dateFrom: filters.date_after,
            dateTo: filters.date_before,
            roomPricingIds,
            resortInclusions,
            diveProducts,
            nonDiversArray,
            divesAmount: selectedRoom.divesAmount,
            roomTitle: selectedRoom.title,
            mealPlan: selectedRoomRate.mealPlan,
            requirements: selectedRoom.requirements,
            studentsArray,
            diverCoursesList,
            nonDiverCoursesList,
            studentCoursesList,
            optionsCurrency,
            selectedRoom,
            packages,
            rate,
            ecommerceProduct,
            ga4EcommerceProduct,
          })
          setLoading(false)
        })
    })
  }, [])

  return {
    ratePrice,
    resortData,
    isLoading,
  }
}
