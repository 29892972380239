import { useMemo } from 'react'
import { Field } from 'react-final-form'
import { autosuggestStyles } from '../../new-forms/DropdownField'
import { FormattedMessage } from 'react-intl'
import find from 'lodash/find'
import Select from 'react-select'

const DropdownField = function({
  input,
  meta,
  isRequired,
  styles,
  title,
  options = [],
  optionLabelKey = 'title',
  optionValueKey = 'id',
  placeholder,
  isArrayCollection,
  className,
  hint,
  isClearable = true,
}) {
  const dropdownOptions = useMemo(() => {
    return isArrayCollection
      ? options.map((option) => ({ id: option[0], title: option[1] }))
      : options
  }, [options])
  const handleChange = (value) => {
    input.onChange(value ? value[optionValueKey] : null, value)
  }
  const fullValue = find(dropdownOptions, (val) => {
    return val[optionValueKey] === input.value
  })

  const translatedPlaceholder = useMemo(() => {
    if(!placeholder) {
      return (
        <FormattedMessage id='select-placeholder' defaultMessage='Select...' />
      )
    }

    return typeof placeholder === 'object' && placeholder.id ? (
      <FormattedMessage {...placeholder} />
    ) : (
      placeholder
    )
  }, [placeholder])

  const error = meta.touched && (meta.error || meta.submitError)
  const longStyle = error && error.length > 50 ? { position: 'static' } : {}
  return (
    <div
      className={`form-item-row dropdown-wrapper ${className} ${
        error ? 'error-field' : ''
      }`}
    >
      <label className='mbn'>
        <strong>
          {typeof title === 'object' && title.id ? (
            <FormattedMessage {...title} />
          ) : (
            title
          )}
          {isRequired ? <span className='asterisk'>*</span> : ''}
        </strong>
      </label>
      <div
        className='form-item-row__container relative'
        tabIndex='0'
        data-select // for focusOnError decorator
        data-name={input.name} // for focusOnError decorator
      >
        <Select
          onChange={handleChange}
          value={fullValue}
          options={dropdownOptions}
          styles={styles || autosuggestStyles}
          placeholder={translatedPlaceholder}
          isSearchable
          getOptionLabel={(option) => option[optionLabelKey]}
          getOptionValue={(option) => option[optionValueKey]}
          isClearable={isClearable}
          className='select-container'
          classNamePrefix='select-container'
        />
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && meta.touched && (
          <div className='error-text' style={longStyle}>
            {error}
          </div>
        )}
      </div>
    </div>
  )
}
export default function FormDropDown(props) {
  const { name } = props
  return <Field name={name} component={DropdownField} {...props} />
}
