/* eslint-disable standard/computed-property-even-spacing */
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import { defineMessages, useIntl, FormattedMessage } from 'react-intl'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import moment from 'moment'
import { getTotalGuests, parseQuery } from '../../../utils'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import omitBy from 'lodash/omitBy'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'

function mergeInclusions(inclusions) {
  const mergedItems = []
  inclusions.forEach((item) => {
    const addedInclusion = mergedItems.findIndex(
      (inclusion) =>
        inclusion.extraId === item.extraId && inclusion.price === item.price
    )
    if(addedInclusion !== -1) {
      mergedItems[addedInclusion].quantity += item.quantity
      mergedItems[addedInclusion].amount =
        Number(mergedItems[addedInclusion].amount) + Number(item.amount)
    } else {
      mergedItems.push(cloneDeep(item))
    }
  }, [])

  return mergedItems
}

export function mapData(data) {
  const { extras, dives, options, courses, insurances, orderDetails } = data
  const { kind: shopKind } = data.shop
  let mappedData = {}

  if(!data.rooms) {
    data.rooms = {}
  }

  // divesInfo
  mappedData.divesInfo = dives.reduce(
    (i, item) => {
      return {
        totalDives: i.totalDives + +item.numberOfInstances,
        amount: i.amount + +item.amount,
      }
    },
    { totalDives: 0, amount: 0 }
  )

  mappedData.totalPackagePrice = 0
  mappedData.itemsExtraTotal = 0
  mappedData.itemsMandatoryTotal = 0
  mappedData.totalForOptions = options.reduce((total, item) => {
    if(+item.kind === 20) {
      return total - item.absoluteitemue
    } else {
      return total + item.absoluteitemue
    }
  }, 0)

  mappedData.itemsIncluded = []
  mappedData.itemsMandatory = []
  mappedData.itemsExtra = []
  mappedData.freeInsurance = []
  if(
    !data.isPast &&
    (data.status === 10 || data.status === 110 || data.status === 90)
  ) {
    mappedData.unconfirmedOrder = true
  }
  // adding booked course products to extras array
  courses.forEach((item) => {
    if(item.guestType === 40) {
      mappedData.itemsIncluded.push({
        isIncluded: true,
        ...item,
      })
      mappedData.totalPackagePrice += +item.amount
    } else {
      mappedData.itemsExtra.push({
        amount: item.amount,
        payedInAdvance: true,
        quantity: item.quantity,
        price: item.amount / item.quantity,
        title: item.title,
        isCourse: true,
        diveProductId: item.diveProductId,
        extraId: item.diveProductId,
        guestNumber: item.guestNumber,
        guestType: item.guestType,
        pk: item.pk,
      })
      mappedData.itemsExtraTotal += +item.amount
    }
  })

  extras.forEach((item) => {
    if(item.isIncluded) {
      item = setExtraPackageType(item, shopKind)
      mappedData.itemsIncluded.push(item)
    } else if(item.isMandatory) {
      mappedData.itemsMandatory.push(item)

      if(item.payedInAdvance) {
        mappedData.itemsMandatoryTotal += +item.amount
      }
    } else {
      mappedData.itemsExtra.push(item)

      if(item.payedInAdvance) {
        mappedData.itemsExtraTotal += +item.amount
      } else {
        mappedData.itemsExtraLegend = true
      }
    }
  })

  insurances.forEach((item) => {
    const { isIncludedInPrice, title, price, quantity } = item
    mappedData[isIncludedInPrice ? 'freeInsurance' : 'itemsExtra'].push({
      title,
      quantity,
      price,
      payedInAdvance: true,
      amount: item.quantity * +item.price,
      isInsurance: true,
      isIncluded: isIncludedInPrice,
    })

    if(!isIncludedInPrice) {
      mappedData.itemsExtraTotal += +item.quantity * +item.price
    }
  })

  const guests =
    orderDetails &&
    reduce(
      orderDetails.accomodation,
      (accum, room) => {
        accum.divers += room.divers || 0
        accum.nonDivers += room.nonDivers || 0
        accum.students += room.students || 0
        return accum
      },
      { divers: 0, nonDivers: 0, students: 0 }
    )

  if(guests) {
    mappedData.totalGuests = guests.divers + guests.nonDivers + guests.students
  }

  mappedData.totalPackagePrice =
    data.rooms && +data.rooms.amount + mappedData.divesInfo.amount

  data.isPast =
    moment().utc().endOf('day') > moment(data.dateTo).utc().endOf('day')

  // payment section button text
  let firstMilestoneCharged = false
  let lastMilestoneCharged = false
  let ccFormButtonText

  if(data.payments && data.payments.length) {
    firstMilestoneCharged =
      data.payments[0].isDueNow && data.payments[0].isCharged
    // hide payment pannel when all milestones paid
    lastMilestoneCharged = data.payments[data.payments.length - 1].isCharged
  }

  if(!data.isPast && !firstMilestoneCharged && data.status === 20) {
    ccFormButtonText = (
      <FormattedMessage id='pay_now' defaultMessage='Pay now' />
    )
  } else if(data.status === 90) {
    ccFormButtonText = (
      <FormattedMessage
        id='convert_option'
        defaultMessage='Convert option to booking'
      />
    )
  }

  const accomodation = map(get(data, 'orderDetails.accomodation', {}))
  let divesIncluded
  if(shopKind === 20 && data.shop.divesPerNights) {
    divesIncluded = find(data.shop.divesPerNights, { nights: data.duration })
  }
  return {
    ...mappedData,
    ...data,
    ...guests,
    shopTitle: data.shop.title,
    shopSlug: data.shop.slug,
    shopId: data.shop.id,
    shopKind,
    divesIncluded: divesIncluded ? divesIncluded.dives : 0,
    mergedExtras: mergeInclusions(mappedData.itemsExtra),
    itemsIncluded: mergeInclusions(mappedData.itemsIncluded),
    itemsMandatory: mergeInclusions(mappedData.itemsMandatory),
    roomParams: {
      ...guests,
      totalGuests: mappedData.totalGuests,
      details: accomodation,
    },
    accomodation,
    lastMilestoneCharged,
    firstMilestoneCharged,
    ccFormButtonText,
  }
}

export function setExtraPackageType(item, shopKind) {
  if(shopKind === 20) {
    if(item.packageKind === 10) {
      item.isDiverItem = true
    } else if(item.packageKind === 20) {
      item.isNonDiverItem = true
    } else if(item.packageKind === 40) {
      item.isStudentItem = true
    }
  } else if(shopKind === 10) {
    if(item.isLiveaboardDiverItem) {
      item.isDiverItem = true
    } else {
      // for all guests
      item.isNonDiverItem = true
    }
  }
  return item
}

export function getPayablePerLabel(type, qty, shopKind) {
  if(shopKind === 10) {
    return getLaLabel(type, qty)
  }
  return getDrLabel(type, qty)
}

const messages = defineMessages({
  per: {
    id: 'per',
    defaultMessage: 'per',
  },
  for: {
    id: 'per',
    defaultMessage: 'per',
  },
  persons: {
    id: 'persons',
    defaultMessage: '{ qty, plural, one {person} =0 {person} other {persons}}',
  },
  vehicles: {
    id: 'vehicles',
    defaultMessage:
      '{ qty, plural, one {vehicle} =0 {vehicle} other {vehicles}}',
  },
  offset: {
    id: 'offset',
    defaultMessage: 'to offset',
  },
  tonneCarbon: {
    id: 'tonneCarbon',
    defaultMessage:
      '{ qty, plural, one {tonne of carbon} =0 {tonne of carbon} other {tonnes of carbons}}',
  },
  perCourse: {
    id: 'perCourse',
    defaultMessage: 'Per course',
  },
  for1Course: {
    id: 'for1course',
    defaultMessage: 'for 1 course',
  },
})

function getLaLabel(type, qty = 1) {
  const intl = useIntl()
  // eslint-disable-next-line
  const labelsArray =
    window.info.shop[
      qty > 1
        ? 'LIVEABOARD_EXTRA_PAYED_PER_PLURAL'
        : 'LIVEABOARD_EXTRA_PAYED_PER'
    ]
  let text = ''
  let labelIndex = findIndex(labelsArray, function(val) {
    return val[0] === type
  })
  let prefix = intl.formatMessage(messages.per)

  if(qty > 1) {
    prefix = intl.formatMessage(messages.for) + ' ' + qty
  }

  if(labelIndex !== -1) {
    const firstLabel = get(labelsArray, `[${labelIndex}][1]`)
    if(type <= 15) {
      text = prefix + ' ' + firstLabel.toLowerCase()
    } else if(type === 42 || type === 46 || type === 49) {
      //  transfers per person
      text = prefix + ' ' + intl.formatMessage(messages.persons, { qty })
    } else if(type === 44 || type === 48 || type === 55) {
      //  transfers per vehicle
      text = prefix + ' ' + intl.formatMessage(messages.vehicles, { qty })
    } else if(type === 110) {
      // to offset X tonnes of carbon
      if(qty > 0) {
        prefix = `${intl.formatMessage(messages.offset)} ${qty}`
      }

      text = prefix + ' ' + intl.formatMessage(messages.tonneCarbon, { qty })
    } else {
      text = prefix + ' ' + firstLabel.split('/')[0].toLowerCase()
    }
  }

  return text
}

function getDrLabel(type, qty = 1) {
  const intl = useIntl()

  // eslint-disable-next-line
  const labelsArray =
    window.info.shop[
      qty > 1 ? 'RESORT_EXTRA_PAYED_PER_PLURAL' : 'RESORT_EXTRA_PAYED_PER'
    ]
  let text = ''
  let labelIndex = findIndex(labelsArray, function(val) {
    return val[0] === type
  })
  let prefix = intl.formatMessage(messages.per)

  if(qty > 1) {
    prefix = intl.formatMessage(messages.for) + ' ' + qty
  }
  if(labelIndex !== -1) {
    const firstLabel = get(labelsArray, `[${labelIndex}][1]`)
    if(type <= 15) {
      text = prefix + ' ' + firstLabel.toLowerCase()
    } else if(type === 51 || type === 53 || type === 56) {
      //  transfers per person
      text = prefix + ' ' + intl.formatMessage(messages.persons, { qty })
    } else if(type === 52 || type === 54 || type === 57) {
      //  transfers per vehicle
      text = prefix + ' ' + intl.formatMessage(messages.vehicles, { qty })
    } else if(type === 110) {
      // to offset X tonnes of carbon
      if(qty > 1) {
        prefix = `${intl.formatMessage(messages.offset)} ${qty}`
      }

      text = prefix + ' ' + intl.formatMessage(messages.tonneCarbon, { qty })
    } else {
      text = prefix + ' ' + firstLabel.split('/')[0].toLowerCase()
    }
  }

  if(!text) {
    return intl.formatMessage(
      qty !== 1 ? messages.perCourse : messages.for1Course
    )
  }

  return text
}

export function setCourseType(item) {
  if(item.packageKind === 10) {
    item.isDiverItem = true
  } else if(item.packageKind === 20) {
    item.isNonDiverItem = true
  } else if(item.packageKind === 40) {
    item.isStudentItem = true
  }

  return item
}

export function isBelongsTo(belongsTo, groupIdentifier) {
  const item = find(window.info.inclusionGroups, { id: +belongsTo })

  return item && item.unique_identifier === groupIdentifier ? item : false
}

export function getBelongsToId(uniqueIdentifier) {
  const item = find(window.info.inclusionGroups, {
    unique_identifier: uniqueIdentifier,
  })

  return item ? item.id : null
}

export function getBelongsToTitle(belongsTo) {
  const item = find(window.info.inclusionGroups, { id: +belongsTo })

  return item ? item.title : null
}

export function getPayedOnPremiseLabel(pricing, type) {
  if(pricing.payedInAdvance === false) {
    if(type === 'LA') {
      return (
        <FormattedMessage
          id='to_be_paid_on_board'
          defaultMessage='To be paid on board'
        />
      )
    } else {
      if(+pricing.section === 10 || +pricing.section === 40) {
        return (
          <FormattedMessage
            id='to_be_paid_at_dc'
            defaultMessage='To be paid at the dive center'
          />
        )
      } else {
        return (
          <FormattedMessage
            id='to_be_paid_at_resort'
            defaultMessage='To be paid at the resort'
          />
        )
      }
    }
  }
  return null
}

export function getInclusionGroup(belongsTo) {
  return find(window.info.inclusionGroups, { id: +belongsTo }) || {}
}

export function setLimit(shopKind, inclusion, parameters, isMandatory) {
  if(shopKind === 10) {
    return setLimitLa(inclusion, parameters, isMandatory)
  }

  return setLimitDr(inclusion, parameters)
}

export function setLimitLa(
  inclusion,
  { duration, numberOfDives, diversNumber, totalGuests, cabinsNumber },
  isMandatory
) {
  let limit = 1
  let notDiverProducts = !(
    isBelongsTo(inclusion.belongsTo, 'Rental equipment') ||
    isBelongsTo(inclusion.belongsTo, 'DC Extras & Surcharge')
  )

  switch (+inclusion.payedPer) {
    // Per Day
    case 0:
      limit = duration + 1
      if(notDiverProducts) {
        limit *= totalGuests
      } else if(isMandatory) {
        limit *= diversNumber
      }
      break
    // Per Night
    case 10:
      limit = duration

      if(notDiverProducts) {
        limit *= totalGuests
      } else if(isMandatory) {
        limit *= diversNumber
      }
      break
    // Per Dive
    case 20:
      limit = numberOfDives

      if(notDiverProducts || isMandatory) {
        limit *= diversNumber
      }
      break
    // Per Trip
    case 30:
      limit = 1
      if(notDiverProducts) {
        limit *= totalGuests
      } else if(isMandatory) {
        limit *= diversNumber
      }

      break
    // Per Diving day
    case 40:
      limit = duration - 1

      if(notDiverProducts || isMandatory) {
        limit *= diversNumber
      }

      break
    // Transfers per persons
    case 42:
    case 46:
    case 49:
    case 50:
      limit = 1
      if(notDiverProducts) {
        limit *= totalGuests
      } else if(isMandatory) {
        limit *= diversNumber
      }
      break
    // Transfers per vehicle
    case 44:
    case 48:
    case 55:
      limit = 1
      break
    case 60:
      limit = 1
      if(notDiverProducts) {
        limit *= totalGuests
      } else if(isMandatory) {
        limit *= diversNumber
      }
      break
    // Per week
    case 70:
      limit = Math.ceil(duration / 7) * totalGuests
      break
    case 80:
      limit = 1
      break
    // Day/Cabin
    case 90:
      limit = (duration + 1) * cabinsNumber
      break
    // Night/Cabin
    case 100:
      limit = duration * cabinsNumber
      break
    case 110:
      // Tones of carbon
      limit = 100
      break
    default:
      break
  }
  return limit
}

export function setLimitDr(
  inclusion,
  {
    numberOfDives,
    duration,
    numberOfRooms,
    totalGuests,
    diversNumber,
    studentsNumber,
    nonDiversNumber,
  }
) {
  let limit = 1
  let notDiverProducts =
    !isBelongsTo(inclusion.belongsTo, 'DC Extras & Surcharge') &&
    !isBelongsTo(inclusion.belongsTo, 'Rental equipment')
  switch (+inclusion.payedPer) {
    // Per Day
    case 0:
      limit = duration + 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }
      break
    // Day/room
    case 5:
      limit = (duration + 1) * numberOfRooms
      break
    // Per Night
    case 10:
      limit = duration

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }
      break
    // Per Night/room
    case 15:
      limit = duration * numberOfRooms
      break
    // Per Dive
    case 20:
      limit = numberOfDives

      break
    // Per Trip
    case 30:
      limit = 1
      break
    // Per Diving day
    case 40:
      limit = duration - 1

      if(limit < 1) {
        limit = 1
      }
      if(notDiverProducts) {
        limit *= diversNumber
      }

      break
    // note: 50 is old payedPer code, don't know if it's used
    case 50:
      limit = 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }

      break
    // Transfer Per Vehicle
    case 52:
    case 54:
    case 57:
      limit = 1
      break
    // Transfer Per Person
    case 51:
    case 53:
    case 56:
      limit = 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }

      break
    // note: 55 is old payedPer code, don't know if it's used
    case 55:
      limit = 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }

      break
    // Per Stay
    case 60:
      limit = 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }
      break
    // Per activity
    case 65:
      limit = 1

      if(inclusion.packageKind) {
        limit = setMaximumLimitOfItem(
          limit,
          inclusion,
          diversNumber,
          studentsNumber,
          nonDiversNumber
        )
      } else if(notDiverProducts) {
        limit *= totalGuests
      }
      break
    // N/A
    case 70:
      limit = 1
      break
    case 110:
      // Tones of carbon
      limit = 100
      break
  }
  return limit
}

function setMaximumLimitOfItem(
  lim,
  item,
  diversNumber,
  studentsNumber,
  nonDiversNumber
) {
  if(item.isDiverItem) {
    lim *= diversNumber
  } else if(item.isNonDiverItem) {
    lim *= nonDiversNumber
  } else if(item.isStudentItem) {
    lim *= studentsNumber
  }

  return lim
}

export function initialzeInclusionsFormValues({
  inclusions,
  diverCoursesList,
  nonDiverCoursesList,
  maxDives,
  roomParams,
  duration,
  diversNumber,
  nonDiversNumber,
  studentsNumber,
  isTwoTank,
  shopKind,
  bookedInclusions,
  divesBooked,
  diveProducts,
  numberOfDivesIncluded,
  addMandatory,
  insuranceItem,
}) {
  const params = parseQuery()
  const filteredInclusions = filter(inclusions, function(inclusion) {
    inclusion = setCourseType(inclusion)
    inclusion.maxLimit = setLimit(shopKind, inclusion, {
      duration,
      numberOfDives: maxDives,
      totalGuests: getTotalGuests(roomParams),
      diversNumber,
      studentsNumber,
      nonDiversNumber,
      roomsNumber: get(roomParams, 'details.length'),
    })
    if(isBelongsTo(inclusion.belongsTo, 'Transfers')) {
      inclusion.isTransfer = true
    }
    if(isBelongsTo(inclusion.belongsTo, 'Sustainability')) {
      inclusion.isOffsetProduct = true
    }

    if(inclusion.isMandatory || inclusion.isIncluded) {
      inclusion.quantity = inclusion.maxLimit
    }

    if(inclusion.deletedItem) {
      inclusion.maxLimit = inclusion.quantity
    }

    // prepopulate inclusions if it's available in the query params
    if(params[`incl_${inclusion.pricingId}`]) {
      inclusion.quantity = parseInt(params[`incl_${inclusion.pricingId}`], 10)
    }

    if(
      !isBelongsTo(inclusion.belongsTo, 'DC Extras & Surcharge') &&
      !isBelongsTo(inclusion.belongsTo, 'Rental equipment') &&
      bookedInclusions &&
      bookedInclusions.length
    ) {
      const bookedItem = bookedInclusions.filter((bookedItem) => {
        return bookedItem.extraId === (inclusion.extraId || inclusion.id)
      })

      inclusion = setBookedInclusions(bookedItem, inclusion)
    }
    if(shopKind === 10) {
      if(addMandatory) {
        return (
          isBelongsTo(inclusion.belongsTo, 'LiveaboardCourses') === false &&
          inclusion.pricingId &&
          ((inclusion.payedInAdvance && inclusion.price) ||
            (inclusion.payedInAdvance === false &&
              (inclusion.price || inclusion.extraValue)) ||
            inclusion.isIncluded)
        )
      }
      return (
        isBelongsTo(inclusion.belongsTo, 'LiveaboardCourses') === false &&
        !inclusion.isMandatory &&
        !inclusion.isIncluded &&
        inclusion.pricingId &&
        ((inclusion.payedInAdvance && inclusion.price) ||
          (inclusion.payedInAdvance === false &&
            (inclusion.price || inclusion.extraValue)))
      )
    }
    return (
      inclusion.isBookableInAdvance &&
      !inclusion.isMandatory &&
      !inclusion.isIncluded &&
      inclusion.pricingId &&
      ((inclusion.payedInAdvance && inclusion.price) ||
        (inclusion.payedInAdvance === false &&
          (inclusion.price || inclusion.extraValue)))
    )
  })
  const groupedInclusions = groupBy(
    sortBy(filteredInclusions, ['isMandatory', 'isIncluded']),
    'belongsTo'
  )

  const rentalEquipmentItems = get(
    groupedInclusions,
    `[${getBelongsToId('Rental equipment')}]`,
    []
  )
  const extras = get(
    groupedInclusions,
    `[${getBelongsToId('DC Extras & Surcharge')}]`,
    []
  )
  const shopInclusions = map(
    omitBy(groupedInclusions, (_, key) => {
      return (
        parseInt(key, 10) === getBelongsToId('DC Extras & Surcharge') ||
        parseInt(key, 10) === getBelongsToId('Rental equipment')
      )
    })
  )

  const diversArray = Array(diversNumber)
    .fill(null)
    .map((_, index) => {
      const diveInclusions =
        shopKind === 10
          ? {}
          : {
            quantity: numberOfDivesIncluded,
            numberOfDivesIncluded: numberOfDivesIncluded,
            isTwoTank,
            maxPreorder: maxDives || undefined,
            divingItem: true,
            source: diveProducts.soruce,
          }
      const currentGuest = index + 1
      const extrasItems = cloneDeep(extras)
      const diverCourses = cloneDeep(diverCoursesList)
      const rentalEquipment = cloneDeep(rentalEquipmentItems)
      if(bookedInclusions && bookedInclusions.length) {
        [diverCourses, rentalEquipment, extrasItems].forEach((items) => {
          items.forEach((incl) => {
            const bookedItem = bookedInclusions.filter((bookedItem) => {
              return (
                bookedItem.extraId === (incl.extraId || incl.id) &&
                bookedItem.guestNumber === currentGuest
              )
            })
            incl = setBookedInclusions(bookedItem, incl)
          })
        })
      }

      if(divesBooked && divesBooked.length) {
        const currentDiverDivesInfo = divesBooked.find(
          (info) => info.diverNumber === currentGuest
        )
        if(currentDiverDivesInfo) {
          diveInclusions.pk = currentDiverDivesInfo.pk
          diveInclusions.quantity = currentDiverDivesInfo.numberOfInstances
          diveInclusions.numberOfDivesIncluded =
            currentDiverDivesInfo.numberOfInstances
        }
      }
      return {
        diveInclusions,
        rentalEquipment,
        courses: diverCourses,
        extras: extrasItems,
      }
    })

  const nonDiversArray = Array(nonDiversNumber)
    .fill(null)
    .map((_, index) => {
      const currentGuest = index + 1
      const nonDiverCourses = cloneDeep(nonDiverCoursesList)
      if(bookedInclusions && bookedInclusions.length) {
        [nonDiverCourses].forEach((items) => {
          items.forEach((incl) => {
            const bookedItem = bookedInclusions.filter((bookedItem) => {
              return (
                bookedItem.extraId === (incl.extraId || incl.id) &&
                bookedItem.guestNumber === currentGuest
              )
            })
            incl = setBookedInclusions(bookedItem, incl)
          })
        })
      }
      return {
        courses: nonDiverCourses,
      }
    })

  try {
    if(params) {
      const fields = ['rentalEquipment', 'courses', 'extras']
      diversArray.forEach((diverData, index) => {
        fields.forEach((field) => {
          if(diverData[field]) {
            diverData[field].forEach((item) => {
              // 10 - diver guest type
              const quantity = params[`incl_${item.pricingId}_${index}_10`]
              if(quantity) {
                item.quantity = parseInt(quantity, 10)
              }
            })
          }
        })
      })
      nonDiversArray.forEach((nonDiverData, index) => {
        nonDiverData.courses.forEach((item) => {
          // 20 - non-diver guest type
          const quantity = params[`incl_${item.pricingId}_${index}_20`]
          if(quantity) {
            item.quantity = parseInt(quantity, 10)
          }
        })
      })
      if(insuranceItem?.length) {
        const insuranceQuantity = params[`incl_${insuranceItem[0].pricingId}`]
        if(insuranceQuantity) {
          insuranceItem[0].quantity = insuranceQuantity
        }
      }
    }
  } catch (e) {
    throw new Error('Issue with query parameters for items', e)
  }

  return {
    shopInclusions,
    diversArray,
    nonDiversArray,
    groupedInclusions,
    studentsArray: Array(studentsNumber).fill({}),
    inclusions: filteredInclusions,
  }
}

function setBookedInclusions(bookedItem, inclusion) {
  if(bookedItem && bookedItem.length) {
    inclusion.quantity = 0
    inclusion.bookedPrice = 0
    inclusion.priceByQuantity = {}
    inclusion.pks = []
    inclusion.bookedItems = []
    const sortedBookedItems = sortBy(bookedItem, 'pk')
    sortedBookedItems.forEach((item) => {
      for(
        let i = inclusion.quantity + 1;
        i <= item.quantity + inclusion.quantity;
        i++
      ) {
        inclusion.priceByQuantity[i] = item.price
      }
      inclusion.quantity += item.quantity
      inclusion.pk = item.pk
      inclusion.pks.push(item.pk)
      inclusion.bookedItems.push(item)
      inclusion.guestType = item.guestType
    })
    inclusion.initialQuantity = inclusion.quantity
  }

  return inclusion
}
