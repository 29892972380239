import './wishlist-btn.scss'

export default function WishlistButton({
  isInWishlist,
  wishlisthLeftAlign,
  id,
  shopSlug,
  type = 'Shop',
}) {
  return (
    <span
      className={`font-icons heart-icon heart${
        wishlisthLeftAlign ? ' left-align' : ''
      }${isInWishlist ? ' active' : ''}`}
      data-activity-id={id}
      data-type={type}
      data-wishlist-id={isInWishlist}
      data-shop-slug={shopSlug}
      data-in-wishlist={isInWishlist || undefined}
    />
  )
}
