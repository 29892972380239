import { Component, createRef } from 'react'
import Modal from 'react-modal'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

class InclusionDescriptionPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inclusionsExpanded: false,
      exclusionsExpanded: false,
    }

    this.slickRef = createRef()
  }

  toggleSection = (type) => {
    this.setState({
      [type]: !this.state[type],
    })
  }

  render() {
    const activity = this.props.activity

    if(!activity) {
      return ''
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        className='Modal react-itinerary-modal'
        overlayClassName='Overlay'
        contentLabel='Activity description'
      >
        <div className='itinerary-page-new'>
          <div className='itinerary-page__modal-header'>
            <span className='btn-close' onClick={this.props.closeModal}>
              &times;
            </span>
          </div>
          <div className='itinerary-popup-content'>
            <div className='main-title'>
              <h2>{this.props.activityTitle}</h2>
              <p className='sub activity-duration'>
                <i className='icon-clock font-icons' />
                <FormattedMessage
                  id='activity.duration'
                  defaultMessage='Duration'
                />
                <span>
                  :&nbsp;{activity.duration.duration}{' '}
                  {activity.duration.durationType.title}
                </span>
                {activity.duration.duration > 1 && 's'}
              </p>
              {activity.numberOfDives && (
                <p className='sub activity-dives'>
                  <i className='dive-center-icon font-icons' />
                  <FormattedMessage
                    id='activity.including_dives'
                    values={{
                      qty: activity.numberOfDives,
                    }}
                    defaultMessage='Including: { qty, plural, one {# dive} other {# dives}}'
                  />
                </p>
              )}
            </div>
            {this.props.thumbnails.length && (
              <div className='activity-image'>
                <img
                  src={get(this.props.thumbnails, 'FeaturedDealsBigMobileLand')}
                  alt='image'
                />
              </div>
            )}
            <div className='itinerary-section'>
              <p>{activity.shortDescription}</p>
              {activity.highlights && (
                <ul className='circled-list'>
                  {activity.highlights.map((highlight, index) => (
                    <li key={index}>{highlight}</li>
                  ))}
                </ul>
              )}
            </div>

            {activity.requirements && (
              <div className='itinerary-section'>
                <h4>
                  <FormattedMessage
                    id='activity.requirements'
                    defaultMessage='Requirements'
                  />
                </h4>
                <ul className='req-list'>
                  <li>
                    <FormattedMessage
                      id='activity.min-age'
                      defaultMessage='Min age'
                    />
                    <span>
                      :&nbsp;
                      {activity.requirements.minAge
                        ? activity.requirements.minAge + 'y'
                        : 'n/a'}
                    </span>
                  </li>
                  <li>
                    <FormattedMessage
                      id='activity.min-certification'
                      defaultMessage='Min certification'
                    />
                    <span>
                      :&nbsp;
                      {get(
                        activity.requirements.minCertification,
                        'title',
                        'n/a',
                      )}
                    </span>
                  </li>
                  <li>
                    <FormattedMessage
                      id='activity.min-dives-count'
                      defaultMessage='Min # logged dives'
                    />
                    <span>
                      :&nbsp;
                      {get(activity.requirements.minDivesCount, 'title', 'n/a')}
                    </span>
                  </li>
                </ul>
                <p>
                  {activity.otherRequirements && activity.otherRequirements}
                </p>
              </div>
            )}

            <div className='itinerary-section collapsed-section'>
              <h4
                onClick={() => this.toggleSection('inclusionsExpanded')}
                className={this.state.inclusionsExpanded ? 'active' : ''}
              >
                <FormattedMessage
                  id='whats_included'
                  defaultMessage='What`s included'
                />
              </h4>
              {this.state.inclusionsExpanded && activity.inclusions ? (
                <div className='itinerary-section-details'>
                  {activity.inclusions.map((inclusion) => {
                    return <p>{inclusion.title}</p>
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='itinerary-section collapsed-section'>
              <h4
                onClick={() => this.toggleSection('exclusionsExpanded')}
                className={this.state.exclusionsExpanded ? 'active' : ''}
              >
                <FormattedMessage
                  id='whats_not_included'
                  defaultMessage='What`s not included'
                />
              </h4>
              {this.state.exclusionsExpanded && activity.exclusions ? (
                <div className='itinerary-section-details'>
                  {activity.exclusions.map((exclusion) => {
                    return <p>{exclusion.title}</p>
                  })}
                </div>
              ) : (
                ''
              )}
            </div>

            <div className='itinerary-section'>
              <h4>
                <FormattedMessage
                  id='activity.whatToExpect'
                  defaultMessage='What to expect'
                />
              </h4>
              <p dangerouslySetInnerHTML={{ __html: activity?.whatToExpect || 'n/a' }} />
            </div>

            {/* <div className='itinerary-section collapsed-section'>
            <h4 onClick={() => this.toggleSection('optionalExtrasExpanded')} className={this.state.optionalExtrasExpanded ? 'active' : ''}>
              <FormattedMessage id='optional_extras' defaultMessage='Optional extras' />
            </h4>
            { this.state.optionalExtrasExpanded
              ? (<div className='itinerary-section-details'>
                <Inclusions shopType='resort' showPrices payedInAdvance type='optionalInAdvance' currency={currency} items={inclusions.bookableAndPayableInAdvance} />
                <Inclusions shopType='resort' showPrices type='optionalBookableAdvancePaidOnSite' currency={currency} items={inclusions.bookableInAdvancePayableOnSite} />
                <Inclusions shopType='resort' showPrices type='bookableAndPayableOnSite' currency={currency} items={inclusions.bookableAndPayableOnSite} />
                <i className='note-text'><FormattedMessage id='note.price' defaultMessage='Note: all listed prices are per person' /></i>
              </div>) : ''
            }
          </div> */}
          </div>
        </div>
      </Modal>
    )
  }
}

export default InclusionDescriptionPopup
