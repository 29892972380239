import { useEffect } from 'react'
import Modal from 'react-modal'
import './modal-styles.scss'

const PadiModal = ({
  isOpen,
  onClose,
  children,
  className = 'cancel-modal',
  styles = {},
}) => {
  useEffect(() => {
    Modal.setAppElement(document.body)
  }, [])
  const modalStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      ...styles,
    },
  }

  return (
    <Modal
      className={className}
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel='Modal window'
    >
      <button className='cancel-modal__close' onClick={onClose}>
        &times;
      </button>
      <div className='mth'>{children}</div>
    </Modal>
  )
}

export default PadiModal
