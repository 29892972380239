/* eslint-disable camelcase */
/* eslint-disable  indent */
/* eslint-disable  react/jsx-indent */
/* eslint-disable  react/jsx-indent-props */
/* eslint-disable  no-unexpected-multiline */

import { Component } from 'react'
import { connect } from 'react-redux'
import './resort-pricing.sass'
import { injectIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import store from 'store2'
import API from '../../api'
import AvailabilityCalendar from './AvailabilityCalendar'
import RoomPopup from '../popups/RoomPopup'
import InclusionDescriptionPopup from '../popups/InclusionDescriptionPopup'
import MealPlanPopup from '../popups/MealPlanPopup'
import {
  setDepartureDate,
  setGuests,
  setDives,
} from '../../actions/dive-operator-actions'
import { parseQuery } from '../../utils'
import ResortInclusions from '../popups/ResortInclusions'
import { PricingTableHeader, PricingTableRow } from './pricing'
import { calculateRoomPrice } from './pricing/helpers'
import { trackEcommerce } from 'gtm'
import PropTypes from 'prop-types'

export const prepareGuestSplitting = (guests = []) => {
  let preparedSplitting = []
  guests &&
    guests.forEach((item) => {
      preparedSplitting.push(JSON.stringify(item))
    })
  return preparedSplitting
}

class ResortPricing extends Component {
  constructor(props) {
    super(props)
    const query = parseQuery()
    const roomParams = store.get('roomParams') || {}
    const dates = store.get('date')
    const dateFrom =
      query && query.date_from
        ? query.date_from
        : (dates && dates[0]) || undefined
    const dateTo =
      query && query.date_from && query.duration
        ? moment(query.date_from).add(query.duration, 'd').format('YYYY-MM-DD')
        : (dates && dates[1]) || undefined
    let nights
    if(dateFrom && dateTo) {
      nights = moment(dateTo).diff(dateFrom, 'd')
    }
    if(!nights) {
      nights =
        query && query.duration
          ? query.duration
          : store.get('duration') || undefined
    }

    if(!nights && dateFrom && dateTo) {
      nights = moment(dateTo).diff(dateFrom, 'd')
    }
    this.state = {
      loading: true,
      roomPopupIsOpen: false,
      showCalendar: false,
      mealPlans: {},
      filters: {
        date_after: dateFrom,
        date_before: dateTo,
        nights,
        guests_split: prepareGuestSplitting(
          get(
            roomParams,
            'guest_split',
            get(roomParams, 'details', [roomParams]),
          ),
        ),
      },
      selectedGuests: roomParams,
      totalGuests:
        roomParams.totalGuests ||
        roomParams.students + roomParams.divers + roomParams.nonDivers,
      rooms: [],
      gmpEchoSaleOpacity: 0,
    }
  }

  componentDidMount() {
    if(this.props.includeFreeDiveInsurance) {
      API('booking/insurances/')
        .get()
        .then((insurance) => {
          this.setState({
            insurance,
          })
        })
    }
    this.getPrices()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  getPrices = () => {
    this.setState({
      loading: true,
    })
    const { nights } = this.state.filters
    store('duration', nights)
    const roomParams = store.get('roomParams') || {}
    if(this.state.filters.date_after && this.state.filters.date_before) {
      store('date', [
        this.state.filters.date_after,
        this.state.filters.date_before,
      ])
    } else {
      store.remove('date')
    }

    this.props.dispatch(
      setDepartureDate({
        dateFrom: this.state.filters.date_after,
        dateTo: this.state.filters.date_before,
        duration: nights,
      }),
    )
    this.props.dispatch(setGuests(this.state.selectedGuests))

    API(`booking/resort/${this.props.slug}/meal-plans/`)
      .get()
      .then((mealPlans) => {
        this.setState({
          mealPlans: mealPlans.mealPlanDescriptions,
        })
      })

    API(`booking/resort/${this.props.slug}/rooms/`)
      .get(this.state.filters)
      .then((rooms) => {
        if(!rooms) {
          return
        }
        const { title } = this.props
        let isDivesSet = false
        let products = []
        rooms.forEach((room) => {
          room = calculateRoomPrice(
            {
              room,
              roomParams,
              nights,
              shopTitle: title,
              isFixedPackage: this.props.isFixedPackage,
            },
            (currentRoom) => {
              if(currentRoom.divesAmount && !isDivesSet) {
                this.props.dispatch(
                  setDives({
                    divesAmount: currentRoom.divesAmount,
                    diveKind: currentRoom.packageDiveKind,
                  }),
                )
                isDivesSet = true
              }
            },
            this.props.isCheckout,
          )
          if(room.eCommerceProducts && this.props.isCheckout) {
            products.push(...room.eCommerceProducts)
          }
        })
        if(this.props.isCheckout) {
          trackEcommerce(
            'checkout_step_package',
            {
              checkout: {
                actionField: {
                  step: 1,
                },
                products,
              },
            },
            'USD',
          )
        }

        let sortedRooms = sortBy(rooms, 'minimalPrice')
        this.setState(
          {
            loading: false,
            rooms: sortedRooms,
          },
          () => {
            ReactTooltip.rebuild()
          },
        )
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleDatesChange = (dates) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          nights: dates.duration,
          date_after: dates.dateFrom || undefined,
          date_before: dates.dateTo || undefined,
        },
      },
      () => {
        this.getPrices()
        if(isEmpty(this.state.selectedGuests)) {
          document.querySelector('.rooms-and-guest-selector').click()
        }
      },
    )
  }

  handleGuestsChange = (guests) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          guests_split: prepareGuestSplitting(guests.guests_split),
        },
        selectedGuests: guests,
        totalGuests: guests.totalGuests,
      },
      () => {
        this.getPrices()
        if(!this.state.filters.date_after || !this.state.filters.date_before) {
          document.querySelector('.travel-datepicker').click()
        }
      },
    )
  }

  updateDate = (type = 'subtract') => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          date_after: moment(this.state.filters.date_after)
            [type](1, 'd')
            .format('YYYY-MM-DD'),
          date_before: moment(this.state.filters.date_before)
            [type](1, 'd')
            .format('YYYY-MM-DD'),
        },
      },
      this.getPrices,
    )
  }

  openRoomPopup = (roomInfo) => {
    this.setState({
      roomInfo,
      roomPopupIsOpen: true,
    })
  }

  closeRoomPopup = () => {
    this.setState({
      roomPopupIsOpen: false,
    })
  }

  triggerModal = (type, isOpen) => {
    this.setState({
      [type]: isOpen,
    })
  }

  setPopupData = (type, data) => {
    this.setState({
      [type]: true,
      ...data,
    })
  }

  triggerAvailabilityCalendar = (value) => {
    this.setState({
      showCalendar: value,
    })
  }

  render() {
    const {
      countrySlug,
      countryTitleIn,
      title,
      hasStudentCourses,
      availablePackageKinds,
      minimalNumberOfNights,
      id,
      slug,
      isFixedPackage,
      onRequest,
      areaFormat,
      isCheckout,
    } = this.props
    const {
      selectedGuests,
      filters,
      showCalendar,
      roomInfo,
      activity,
      thumbnails,
      activityTitle,
      isOpenInclusionDescriptionPopup,
      mealPlan,
      iconClass,
      mealPlanDescription,
      isOpenMealPlanPopup,
      roomPopupIsOpen,
      loading,
      rooms,
      totalGuests,
      mealPlans,
      insurance,
    } = this.state
    const showAvailabilityCalendar =
      !isFixedPackage &&
      !onRequest &&
      filters.date_after &&
      filters.date_before
    return (
      <div className='resort-pricing'>
        <ReactTooltip effect='solid' />
        <ResortInclusions />
        <PricingTableHeader
          isCheckout={isCheckout}
          title={title}
          countrySlug={countrySlug}
          countryTitleIn={countryTitleIn}
          hasStudentCourses={hasStudentCourses}
          availablePackageKinds={availablePackageKinds}
          isFixedPackage={isFixedPackage}
          id={id}
          slug={slug}
          selectedGuests={selectedGuests}
          filters={filters}
          minimalNumberOfNights={minimalNumberOfNights}
          handleDatesChange={this.handleDatesChange}
          getPrices={this.getPrices}
          handleGuestsChange={this.handleGuestsChange}
        />
        {!!showAvailabilityCalendar && (
          <AvailabilityCalendar
            showCalendar={showCalendar}
            shopSlug={slug}
            duration={filters.nights || 7}
            guestsSplit={filters.guests_split}
            triggerAvailabilityCalendar={this.triggerAvailabilityCalendar}
            dateFrom={filters.date_after}
            updateDate={this.updateDate}
            dateTo={filters.date_before}
          />
        )}
        <InclusionDescriptionPopup
          activity={activity}
          thumbnails={thumbnails}
          activityTitle={activityTitle}
          isOpen={isOpenInclusionDescriptionPopup}
          closeModal={() =>
            this.triggerModal('isOpenInclusionDescriptionPopup', false)
          }
        />
        <MealPlanPopup
          mealPlan={mealPlan}
          iconClass={iconClass}
          description={mealPlanDescription}
          isOpen={isOpenMealPlanPopup}
          closeModal={() => this.triggerModal('isOpenMealPlanPopup', false)}
        />
        {!!roomInfo && (
          <RoomPopup
            isOpen={roomPopupIsOpen}
            roomInfo={roomInfo}
            closeModal={this.closeRoomPopup}
            areaFormat={this.props.areaFormat}
          />
        )}
        <div className='resort-pricing__body'>
          <PricingTableRow
            isLoading={loading}
            rooms={rooms}
            totalGuests={totalGuests}
            openRoomPopup={this.openRoomPopup}
            filters={filters}
            selectedGuests={selectedGuests}
            handleGuestsChange={this.handleGuestsChange}
            handleDatesChange={this.handleDatesChange}
            showCalendar={showCalendar}
            triggerAvailabilityCalendar={this.triggerAvailabilityCalendar}
            isFixedPackage={isFixedPackage}
            id={id}
            slug={slug}
            countrySlug={countrySlug}
            title={title}
            setPopupData={this.setPopupData}
            areaFormat={areaFormat}
            onRequest={onRequest}
            mealPlans={mealPlans}
            insurance={insurance}
            isCheckout={isCheckout}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(
  connect((state) => ({
    date: state.departureDate,
    selectedCurrency: state.selectedCurrency,
  }))(ResortPricing),
)

ResortPricing.propTypes = {
  countrySlug: PropTypes.string,
  countryTitleIn: PropTypes.string,
  title: PropTypes.string,
  hasStudentCourses: PropTypes.bool,
  availablePackageKinds: PropTypes.array,
  minimalNumberOfNights: PropTypes.number,
  id: PropTypes.number,
  slug: PropTypes.string,
  isFixedPackage: PropTypes.bool,
  onRequest: PropTypes.bool,
  areaFormat: PropTypes.string,
  includeFreeDiveInsurance: PropTypes.bool,
  isCheckout: PropTypes.bool,
  dispatch: PropTypes.func,
}
