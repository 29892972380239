import { createAction } from 'redux-actions'

const SET_CURRENT_ROOM = 'SET_CURRENT_ROOM'
const setSelectedRoom = createAction(SET_CURRENT_ROOM)

function selectedRoom(state = {}, { type, payload }) {
  switch (type) {
    case SET_CURRENT_ROOM:
      return payload
    default:
      return state
  }
}
const SET_FILTERS = 'SET_FILTERS'
const setFilters = createAction(SET_FILTERS)

function checkoutFilters(state = {}, { type, payload }) {
  switch (type) {
    case SET_FILTERS:
      return payload
    default:
      return state
  }
}
const SET_COURSE_ID = 'SET_COURSE_ID'
const setCourseId = createAction(SET_COURSE_ID)

function selectedCourseId(state = null, { type, payload }) {
  switch (type) {
    case SET_COURSE_ID:
      return payload
    default:
      return state
  }
}

const SET_INCLUSIONS = 'SET_INCLUSIONS'
const setInclusions = createAction(SET_INCLUSIONS)

const UPDATE_INCLUSIONS = 'UPDATE_INCLUSIONS'
const updateInclusions = createAction(UPDATE_INCLUSIONS)

const UPDATE_DIVER_INCLUSIONS = 'UPDATE_DIVER_INCLUSIONS'
const updateDiverInclusions = createAction(UPDATE_DIVER_INCLUSIONS)

function checkoutInclusions(state = {}, { type, payload }) {
  switch (type) {
    case SET_INCLUSIONS:
      return payload
    case UPDATE_INCLUSIONS:
      return { ...state, ...payload }
    case UPDATE_DIVER_INCLUSIONS:
      if(state.diverInclusions) {
        return { ...state, diverInclusions: { ...state.diverInclusions, ...payload } }
      }
      return { ...state,
        diverInclusions: {
          ...payload,
        } }
    default:
      return state
  }
}

export { 
  setSelectedRoom, 
  selectedRoom, 
  checkoutFilters, 
  setFilters, 
  setInclusions, 
  checkoutInclusions,
  updateInclusions, 
  updateDiverInclusions,
  selectedCourseId, 
  setCourseId,
}
