import FormInput from './FormInput'
import FormDropDown from './FormDropDown'
import { defineMessages } from 'react-intl'

import './input-with-dropdown.scss'

const messages = defineMessages({
  weight: {
    id: 'weight',
    defaultMessage: 'Weight',
  },
})

export default function UserWeightField({
  inputName,
  dropdownName,
  dropdownOptions,
}) {
  const options = dropdownOptions || window.info.account.WEIGHT_UNIT

  return (
    <div className='input-with-dropdown'>
      <FormDropDown
        name={dropdownName}
        isArrayCollection
        options={options}
        title={messages.weight}
        isClearable={false}
        className='small-dropdown'
      />
      <FormInput
        name={inputName}
        onlyNumbers
        placeholder='75'
        className='main-input'
      />
    </div>
  )
}
