import BookingRequest from '../../operator/BookingRequest'
import { RoomInfo } from './partials'

export default function ResortPricingRowOnRequest({
  room,
  openRoomPopup,
  shopId,
  shopTitle,
}) {
  return (
    <div className='resort-pricing-row'>
      <RoomInfo room={room} openRoomPopup={openRoomPopup} showPicture />
      <div className='resort-pricing__request'>
        <BookingRequest shopId={shopId} shopTitle={shopTitle} />
      </div>
    </div>
  )
}
