/* eslint-disable no-return-assign */
import FormRow from './FormRow'
import { FormattedMessage } from 'react-intl'
import {
  FormInput,
  FormPhone,
  FormTextArea,
} from '../../forms/final-form-fields'

function ContactPerson({ setPhoneInstance, showTextArea, kind }) {
  return (
    <FormRow
      title={
        kind !== '60' ? (
          <FormattedMessage
            id='contact_person'
            defaultMessage='Contact person'
          />
        ) : (
          <FormattedMessage id='your_details' defaultMessage='Your details' />
        )
      }
    >
      <div className='info-field'>
        <FormInput
          isRequired
          name='firstName'
          label={
            <FormattedMessage id='first_name' defaultMessage='First name' />
          }
        />
      </div>
      <div className='info-field'>
        <FormInput
          isRequired
          name='lastName'
          label={<FormattedMessage id='last_name' defaultMessage='Last name' />}
        />
      </div>
      <div className='info-field'>
        <FormInput
          isRequired
          name='email'
          label={<FormattedMessage id='email' defaultMessage='Email' />}
        />
      </div>
      <div className='info-field'>
        <FormPhone
          isRequired
          getPhoneInstance={setPhoneInstance}
          name='phone'
          label={<FormattedMessage id='phone' defaultMessage='Phone number' />}
        />
      </div>
      {showTextArea && (
        <div className='info-field'>
          <FormTextArea
            name='description'
            label={<FormattedMessage id='message' defaultMessage='Message' />}
          />
        </div>
      )}
    </FormRow>
  )
}

export default ContactPerson
