export const padiProLocalizedUrls = {
  en: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/me/membership-plans',
    settings: 'https://pro.padi.com/settings',
    dashboard: 'https://pro.padi.com/me/dashboard',
    diveJobs: 'https://divejobs.padi.com/',
    processing: 'https://pro.padi.com/me/processing',
    trainingHub: 'https://pro.padi.com/me/training-hub',
    shop: 'https://pro.padi.com/me/shop',
  },
  it: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/io/piani-di-iscrizione',
    settings: 'https://pro.padi.com/impostazioni',
    dashboard: 'https://pro.padi.com/io/dashboard',
    diveJobs: 'https://divejobs.padi.com/',
    processing: 'https://pro.padi.com/me/processing',
    trainingHub: 'https://pro.padi.com/io/hub-formazione',
    shop: 'https://pro.padi.com/io/negozio',
  },
  de: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/me/membership-plans/de',
    settings: 'https://pro.padi.com/settings/de',
    dashboard: 'https://pro.padi.com/me/dashboard',
    diveJobs: 'https://divejobs.padi.com/de',
    processing: 'https://pro.padi.com/me/processing/de',
    trainingHub: 'https://pro.padi.com/me/training-hub',
    shop: 'https://pro.padi.com/me/shop',
  },
  nl: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/me/membership-plans',
    settings: 'https://pro.padi.com/settings',
    dashboard: 'https://pro.padi.com/me/dashboard',
    diveJobs: 'https://divejobs.padi.com/',
    processing: 'https://pro.padi.com/me/processing',
    trainingHub: 'https://pro.padi.com/me/training-hub',
    shop: 'https://pro.padi.com/me/shop',
  },
  es: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/yo/planes-de-afiliacion',
    settings: 'https://pro.padi.com/ajustes',
    dashboard: 'https://pro.padi.com/yo/panel-de-control',
    diveJobs: 'https://divejobs.padi.com/',
    processing: 'https://pro.padi.com/me/processing',
    trainingHub: 'https://pro.padi.com/yo/centro-de-formación',
    shop: 'https://pro.padi.com/yo/tienda',
  },
  fr: {
    account: 'https://pro.padi.com/account',
    memberPlans: 'https://pro.padi.com/me/membership-plans/fr',
    settings: 'https://pro.padi.com/settings/fr',
    dashboard: 'https://pro.padi.com/me/dashboard',
    diveJobs: 'https://divejobs.padi.com/fr',
    processing: 'https://pro.padi.com/me/processing/fr',
    trainingHub: 'https://pro.padi.com/me/training-hub',
    shop: 'https://pro.padi.com/me/shop',
  },
  'zh-hans': {
    account: 'https://pro.padi.com/account/cn',
    memberPlans: 'https://pro.padi.com/me/membership-plans/cn',
    settings: 'https://pro.padi.com/settings/cn',
    dashboard: 'https://pro.padi.com/me/dashboard',
    diveJobs: 'https://divejobs.padi.com/',
    processing: 'https://pro.padi.com/me/processing',
    trainingHub: 'https://pro.padi.com/me/training-hub',
    shop: 'https://pro.padi.com/me/shop',
  },
  'zh-hant': {
    account: 'https://pro.padi.com/account/cn',
    memberPlans: 'https://pro.padi.com/me/membership-plans/cn',
    settings: 'https://pro.padi.com/settings/cn',
    dashboard: 'https://pro.padi.com/me/dashboard/cn',
    diveJobs: 'https://divejobs.padi.com/cn',
    processing: 'https://pro.padi.com/me/processing/cn',
    trainingHub: 'https://pro.padi.com/me/training-hub/cn',
    shop: 'https://pro.padi.com/me/shop/cn',
  },
}
