import get from 'lodash/get'
import { Fragment, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  isLastMinuteBooking,
  serializeData,
  roundPrice, getTextFromInfo, parseQuery,
} from '../../../../utils'
import { createUrl } from '../../../../localized-urls'
import { generateGuestsText } from '../../../forms/GuestPicker'
import PriceConverter, {
  convertCurrency,
} from '../../../helpers/PriceConverter'
import PromotionLabel from '../../../helpers/PromotionLabel'
import PriceType from '../PriceType'
import { trackGA4Ecommerce } from '../../../../gtm'
import {
  setSelectedRoom,
  setFilters,
} from '../../../../actions/resort-checkout-actions'
import PropTypes from 'prop-types'

export default function RoomPrice({
  pricingObject,
  slug,
  countrySlug,
  filters,
  room,
  roomParams,
  totalGuests,
  isFixedPackage,
  isCheckout,
}) {
  const dispatch = useDispatch()
  const checkoutUrl = useMemo(() => {
    const query = parseQuery() || {}
    const params = serializeData({
      rate: pricingObject.rateIds || pricingObject.id,
      room: room.id,
      mealPlan: pricingObject.mealPlan,
      packages: pricingObject.packages,
      aid: query.aid,
    })

    return createUrl(
      'checkout_dr',
      {
        slug,
        location: countrySlug,
      },
      '?' + params,
    )
  }, [pricingObject, room])

  const setEcommerce = () => {
    const items = []
    let value = 0
    roomParams.details.forEach((item) => {
      value +=
        (pricingObject.ratePrice / totalGuests) *
        (item.divers + item.nonDivers + item.students)
      items.push({
        item_name: window.shop.title || window.shop.shopTitle,
        item_id: pricingObject.id.toString(),
        price: Number(
          roundPrice(
            convertCurrency(
              (pricingObject.ratePrice / totalGuests) *
                (item.divers + item.nonDivers + item.students),
              room.currency,
              'USD',
            ),
          ),
        ),
        item_brand: 'PADI',
        item_category: 'PADI Travel',
        item_category2: 'Dive Resort',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_variant: room.title,
        rate_type: getTextFromInfo(pricingObject.mealPlan, 'shop', 'RESORT_MEAL_PLANS'),
        quantity: 1,
        item_list_name: 'PADI Travel Dive Resorts - Product Page',
      })
    })
    trackGA4Ecommerce(
      'add_to_cart',
      {
        currency: 'USD',
        value: Number(roundPrice(convertCurrency(value, room.currency, 'USD'))),
        items,
      },
      'PADI Travel - Dive Resorts',
    )
  }

  const onPackageSelect = () => {
    setEcommerce()
    dispatch(
      setFilters({
        ...filters,
        roomParams,
        totalGuests,
      }),
    )
    dispatch(
      setSelectedRoom({
        rate: pricingObject.rateIds || pricingObject.id,
        room: room.id,
        mealPlan: pricingObject.mealPlan,
        packages: pricingObject.packages,
      }),
    )
  }

  return (
    <div className='resort-pricing__price'>
      <div className='pricing-wrapper'>
        {pricingObject.compareRatePrice > pricingObject.ratePrice && (
          <div>
            <PromotionLabel
              promotion={{
                kind: 10,
              }}
              price={pricingObject.ratePrice}
              currency={room.currency}
              compareAtPrice={pricingObject.compareRatePrice}
            />
          </div>
        )}
        <div className='price-per-guest-wrapper'>
          <strong className='price-per-guest'>
            <PriceConverter
              amount={pricingObject.ratePrice / totalGuests}
              currency={room.currency}
            />
          </strong>
          {pricingObject.compareRatePrice > pricingObject.ratePrice && (
            <span className='old-price'>
              <PriceConverter
                amount={pricingObject.compareRatePrice / totalGuests}
                currency={room.currency}
              />
            </span>
          )}
          {totalGuests > 1 && (
            <p className='price-per-guest__text'>
              <FormattedMessage
                id='avg_per_person'
                values={{
                  duration: room.nights,
                }}
                defaultMessage='Avg. per person for { duration, plural, one {# night} other {# nights}}'
              />
            </p>
          )}
        </div>
        <div className='total-price-wrapper'>
          {totalGuests > 1 && (
            <Fragment>
              <strong className='total-price'>
                <PriceConverter
                  amount={pricingObject.ratePrice}
                  currency={room.currency}
                />
              </strong>
              {pricingObject.compareRatePrice > pricingObject.ratePrice && (
                <span className='old-price'>
                  <PriceConverter
                    amount={pricingObject.compareRatePrice}
                    currency={room.currency}
                  />
                </span>
              )}
            </Fragment>
          )}
          <p className='total-price__text'>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {generateGuestsText(roomParams, true)},&nbsp;
            <PriceType
              isFixedPackage={isFixedPackage}
              roomParams={roomParams}
              room={room}
            />
          </p>
        </div>
        {isCheckout ? (
          <button onClick={onPackageSelect} className='btn-red'>
            <FormattedMessage
              id='customize_package'
              defaultMessage='Customize Package'
            />
          </button>
        ) : (
          <a href={checkoutUrl} onClick={setEcommerce} className='btn-red'>
            <FormattedMessage
              id='customize_package'
              defaultMessage='Customize Package'
            />
          </a>
        )}
      </div>
      {!isLastMinuteBooking(get(filters, 'date_after')) && (
        <p className='benefit'>
          <i className='font-icons ok-icon-new' />
          <FormattedMessage
            id='48h_free_cancellation'
            defaultMessage='48h free cancellation period'
          />
        </p>
      )}
      <p className='price-explanation'>
        <FormattedMessage
          id='prices_included_taxes'
          defaultMessage='Prices include taxes & service fees'
        />
      </p>
    </div>
  )
}

RoomPrice.propTypes = {
  pricingObject: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  countrySlug: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  roomParams: PropTypes.object.isRequired,
  totalGuests: PropTypes.number.isRequired,
  isFixedPackage: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool.isRequired,
}
