import FormRow from './FormRow'
import { FormattedMessage } from 'react-intl'
import TravelDatepicker from '../../helpers/TravelDatepicker'

export default function TripDates({ errors, onChange, dateTo, dateFrom }) {
  return (
    <FormRow
      title={<FormattedMessage id='trip_dates' defaultMessage='Trip dates' />}
    >
      <label
        className={`date with-label ${
          errors.firstDay || errors.lastDay ? 'error-field' : ''
        }`}
      >
        <strong>
          <FormattedMessage id='from_to' defaultMessage='From & to' />
        </strong>
        <TravelDatepicker
          range
          dateTo={dateTo}
          dateFrom={dateFrom}
          callback={(val) => {
            onChange('firstDay', val.dateFrom)
            onChange('lastDay', val.dateTo)
          }}
          style={{ width: '100%' }}
        />
        <div className='error-text'>
          {errors.firstDay && errors.lastDay && (
            <FormattedMessage
              id='required_field'
              defaultMessage='This field is required!'
            />
          )}
          {errors.firstDay && !errors.lastDay && (
            <FormattedMessage
              id='error_date_from'
              defaultMessage='Please select trip start date'
            />
          )}
          {!errors.firstDay && errors.lastDay && (
            <FormattedMessage
              id='error_date_to'
              defaultMessage='Please select trip end date'
            />
          )}
        </div>
      </label>
    </FormRow>
  )
}
