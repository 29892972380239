import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { handleEventBubbling } from '../../dom-handlers'
import OperatorEnquiryPopup from '../popups/OperatorEnquiryPopup'

import './booking-request.scss'

const BookingRequest = ({ showText = true, shopId, shopTitle }) => {
  const [enquiryIsOpen, setEnquiryOpen] = useState(false)

  const handleClick = (e) => {
    handleEventBubbling(e)
    setEnquiryOpen(true)
  }

  const handleModalClose = () => {
    setEnquiryOpen(false)
  }

  return (
    <div className='price booking-request'>
      <div onClick={handleEventBubbling}>
        <OperatorEnquiryPopup
          shop={+shopId}
          shopTitle={shopTitle}
          isOpen={enquiryIsOpen}
          closeModal={handleModalClose}
        />
      </div>
      <strong className='price-request'>
        <FormattedMessage id='on_request' defaultMessage='On request' />
      </strong>
      {showText && (
        <p>
          <FormattedMessage
            id='shop.pricing.on_request'
            defaultMessage="Get in touch and we'll put together a bespoke quote"
          />
        </p>
      )}
      <button className='btn-red' onClick={handleClick}>
        <FormattedMessage id='send_enquiry' defaultMessage='Send enquiry' />
      </button>
    </div>
  )
}

export default BookingRequest
