/**
 * @file Contains common handlers.
 */

export const handleEventBubbling = (event) => {
  event.stopPropagation()
}

export const handleEventDefault = (event) => {
  event.preventDefault()
}

export const scrollToError = () => {
  setTimeout(() => {
    const el = document.querySelector('.error-field')
    if(el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }, 100)
}
export const preventNavigationHandler = (e) => {
  e.stopPropagation()
  e.preventDefault()
}
