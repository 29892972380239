import { useState } from 'react'
import Modal from 'react-modal'
import store from 'store2'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

// TODO add translations

const popupMessage = {
  nl:
    "De pagina die je opvraagt is nog niet beschikbaar in jouw taal. Wij zijn bezig met het vertalen van alle pagina's en hopen dat zo snel mogelijk af te ronden. Excuses voor het ongemak. Bedankt voor je interesse in PADI.",
  ko:
    '요청하신 페이지는 아직 귀하의 언어로 제공되지 않습니다. 우리는 모든 페이지를 번역하도록 노력하고 있으며 또 계속 발전할 수 있도록 최선을 다하고 있습니다. 불편을 끼쳐드려 죄송합니다. PADI 에 관심가져 주심에 감사합니다',
  ja:
    '「このページは、あなたの言語ではまだ利用できません。 私たちはすべてのページが翻訳され、最新の状態を維持するよう努力を重ねています。 ご不便をおかけしますがよろしくお願いします。 また、PADIに関心をお寄せいただきありがとうございます。」',
  'zh-hans':
    '您请求的页面暂时无法以您的语言提供。我们正在努力推进所有页面的翻译工作。对此给您带来的不便，我们深感抱歉。感谢您对PADI的兴趣。', // chinesse simplified
  'zh-hant':
    '您所請求的頁面還沒有您的語言版本。我們正在努力確保所有頁面均被翻譯，並將繼續取得進展。很抱歉給您帶來不便。 感謝您對 PADI 的關注', // chinesse traditional
}

const LanguageWarningPopup = ({ lang }) => {
  const [isOpen, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    store.session('languageWarningShown', true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      className='Modal react-popup'
      overlayClassName='Overlay'
    >
      <span className='close-btn' onClick={handleClose} />
      <p className='language-popup'>
        {popupMessage[lang] || (
          <FormattedMessage defaultMessage='The page you are requesting is not available in your language yet. We are working hard to ensure all pages are translated and will continue to make progress. We apologise for any inconvenience. Thank you for your interest in PADI.' />
        )}
      </p>
    </Modal>
  )
}

export default LanguageWarningPopup

LanguageWarningPopup.propTypes = {
  lang: PropTypes.string.isRequired,
}
