import { Fragment, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorCalendar from '../../helpers/ErrorCalendar'
import { RoomInfo } from './partials'
import Proptypes from 'prop-types'

export default function ResortPricingRowUnavailable({
  availabilityCalendar,
  showCalendar,
  room,
  triggerAvailabilityCalendar,
  openRoomPopup,
}) {
  const backendErrorMessage = room.exceptionMessage || room.error
  const showCalendarButton = backendErrorMessage && !availabilityCalendar
  useEffect(() => {
    if(availabilityCalendar && !showCalendar) {
      triggerAvailabilityCalendar(true)
    }
  }, [])

  return (
    <div className='resort-pricing-row unavailable'>
      <RoomInfo room={room} openRoomPopup={openRoomPopup} />
      <div className='resort-pricing__meal-plan' />
      <div className='resort-pricing__price'>
        <p className='pricing-error'>
          {backendErrorMessage?.length ? (
            <Fragment>{backendErrorMessage}</Fragment>
          ) : (
            <FormattedMessage
              id='not_available_for_number_of_guests'
              defaultMessage='No prices & availability'
            />
          )}
        </p>
        <br />
        <div>
          {!!showCalendarButton && (
            <ErrorCalendar
              selector={'.travel-datepicker'}
              defaultMessage={
                <FormattedMessage
                  id='view_available_dates'
                  defaultMessage='View available dates'
                />
              }
              className='fake-input'
              icon='font-icons calendar-icon'
            />
          )}
        </div>
      </div>
    </div>
  )
}

ResortPricingRowUnavailable.propTypes = {
  availabilityCalendar: Proptypes.bool,
  showCalendar: Proptypes.bool,
  room: Proptypes.object,
  triggerAvailabilityCalendar: Proptypes.func,
  openRoomPopup: Proptypes.func,
}
