import { createAction } from 'redux-actions'

const SET_DEPARTURE_DATE = 'SET_DEPARTURE_DATE'
const setDepartureDate = createAction(SET_DEPARTURE_DATE)

function departureDate(state = null, { type, payload }) {
  switch (type) {
    case SET_DEPARTURE_DATE:
      return payload
    default:
      return state
  }
}

const SET_GUESTS_IN_ROOM = 'SET_GUESTS_IN_ROOM'
const setGuests = createAction(SET_GUESTS_IN_ROOM)

function guestsInRoom(state = null, { type, payload }) {
  switch (type) {
    case SET_GUESTS_IN_ROOM:
      return payload
    default:
      return state
  }
}

const SET_DIVES_NUMBER = 'SET_DIVES_NUMBER'
const setDives = createAction(SET_DIVES_NUMBER)

function fixedPackageDives(state = null, { type, payload }) {
  switch (type) {
    case SET_DIVES_NUMBER:
      return payload
    default:
      return state
  }
}

const SET_SCHEDULE_TYPE = 'SET_SCHEDULE_TYPE'
const setScheduleType = createAction(SET_SCHEDULE_TYPE)

function scheduleType(state = 'all', { type, payload }) {
  switch (type) {
    case SET_SCHEDULE_TYPE:
      return payload
    default:
      return state
  }
}
const SET_ACTIVITIES = 'SET_ACTIVITIES'
const setActivities = createAction(SET_ACTIVITIES)

function activitesList(state = {}, { type, payload }) {
  switch (type) {
    case SET_ACTIVITIES:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export {
  setDepartureDate,
  departureDate,
  guestsInRoom,
  setGuests,
  setDives,
  fixedPackageDives,
  scheduleType,
  setScheduleType,
  setActivities,
  activitesList,
}
