/* eslint-disable camelcase */
import { Fragment, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Modal from 'react-modal'
import './styles.sass'
import API from '../../api'
import DateFormat from '../helpers/DateFormat'

import { Form } from 'react-final-form'
import {
  FormDatepicker,
  FormDropDown,
  FormInput,
} from '../forms/final-form-fields'

import { FormRow, ContactPerson, WhatAreYouLooking } from './booking-enquire'
import { contactUsValidation } from './booking-enquire/validations'

const msg = defineMessages({
  datePickerFrom: {
    id: 'placeholder.datepickerFrom',
    defaultMessage: 'Pick From Date',
  },
  datePickerTo: {
    id: 'placeholder.datepickerTo',
    defaultMessage: 'Pick To Date',
  },
})

const defaultFormData = {
  firstName: null,
  lastName: null,
  phone: null,
  firstDay: null,
  lastDay: null,
  email: null,
  kind: '0',
  existingReservationReference: null,
  isDiving: false,
  isAccommodationAndDiving: false,
  isLiveaboardTrip: false,
  numberDivers: 0,
  numberGuests: 0,
  numberRooms: 0,
}

const ENQUIRE_TYPES = [
  {
    '0': (
      <FormattedMessage id='booking_enquiry' defaultMessage='Booking enquiry' />
    ),
  },
  {
    '10': (
      <FormattedMessage
        id='group_booking_enquiry'
        defaultMessage='Group booking enquiry'
      />
    ),
  },
  {
    '50': (
      <FormattedMessage
        id='existing_reservation'
        defaultMessage='Existing reservation'
      />
    ),
  },
  { '30': <FormattedMessage id='other' defaultMessage='Other' /> },
]

const BookingEnquirePopup = ({ shop, title, isOpen, closeModal }) => {
  const intl = useIntl()
  const [phoneInstance, setPhoneInstance] = useState(null)
  const [finished, setFinished] = useState(false)

  const handleFormSubmit = (values, form) => {
    const formData = { ...values }
    if(formData.kind !== '0' && formData.kind !== '10') {
      formData.firstDay = null
      formData.lastDay = null
    } else if(formData.kind !== '50') {
      formData.existingReservationReference = null
    }
    API('shop/booking_enquire/', false, true)
      .post(formData)
      .then(() => {
        setFinished(true)
        form.restart()
      })
  }

  const options = ENQUIRE_TYPES.flatMap(Object.entries)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className='Modal react-popup form-popup'
      overlayClassName='Overlay'
      contentLabel='Booking enquire popup'
    >
      <span className='close-btn' onClick={closeModal} />
      {finished ? (
        <div className='form-popup-content'>
          <p>
            <FormattedMessage
              id='booking_enquire.finished'
              defaultMessage='Thank you for your enquiry. The PADI Travel team will get back to you as soon as possible.'
            />
          </p>
        </div>
      ) : (
        <div className='form-popup-content'>
          <h3>
            {title || (
              <FormattedMessage id='contact_us' defaultMessage='Contact us' />
            )}
          </h3>

          {shop ? (
            <Fragment>
              <div className='shop-detail'>
                <div
                  className='img'
                  style={{ backgroundImage: `url(${shop.background})` }}
                />
                <ul>
                  <li>
                    <span
                      className='shop-title'
                      ng-bind='selectedShop.info.title'
                    />
                  </li>
                  <li>
                    <span className='bold' translate='translate'>
                      <FormattedMessage
                        id='itinerary'
                        defaultMessage='Itinerary'
                      />
                      :&nbsp;
                    </span>
                    <span>{shop.itinerary.title}</span>
                  </li>
                  <li>
                    <span className='bold' translate='translate'>
                      <FormattedMessage
                        id='duration'
                        defaultMessage='Duration'
                      />
                      :&nbsp;
                    </span>
                    <FormattedMessage
                      id='night_shop_qty'
                      values={{ qty: shop.duration }}
                      defaultMessage='{qty, plural, one {# night} other {# nights}}'
                    />
                  </li>
                  <li>
                    <span className='bold'>
                      <FormattedMessage
                        id='departure'
                        defaultMessage='Departure'
                      />
                      :&nbsp;
                    </span>
                    <span>
                      <DateFormat date={shop.startDate} />
                    </span>
                  </li>
                  <li>
                    <span className='bold' translate='translate'>
                      <FormattedMessage id='arrival' defaultMessage='Arrival' />
                      :&nbsp;
                    </span>
                    <span>
                      <DateFormat date={shop.endDate} />
                    </span>
                  </li>
                </ul>
              </div>
              <p>
                <FormattedMessage
                  id='booking_enquire.text2'
                  defaultMessage='Please provide us with your broad requirements and our dive travel specialists will get back to you regarding the selected vessel and itinerary, but also other possible options.'
                />
              </p>
            </Fragment>
          ) : (
            <p>
              <FormattedMessage
                id='booking_enquire.text'
                defaultMessage='Please provide us with your requirements and our dive travel specialists will get back to you with a short list of options and packages to choose from'
              />
            </p>
          )}
          <Form
            onSubmit={handleFormSubmit}
            initialValues={defaultFormData}
            validate={(values) => contactUsValidation(values, phoneInstance)}
          >
            {({ handleSubmit, values }) => (
              <form name='enquireForm' onSubmit={handleSubmit}>
                <FormRow
                  title={
                    <FormattedMessage
                      id='what_you_like_to_contact'
                      defaultMessage='What would you like to contact us about?'
                    />
                  }
                >
                  <FormDropDown
                    name='kind'
                    options={options}
                    isArrayCollection
                  />
                </FormRow>
                {(values.kind === '0' || values.kind === '10') && (
                  <FormRow
                    title={
                      <FormattedMessage
                        id='trip_dates'
                        defaultMessage='Trip dates'
                      />
                    }
                  >
                    <FormDatepicker
                      isRequired
                      placeholder={intl.formatMessage(msg.datePickerFrom)}
                      name='firstDay'
                      label={
                        <FormattedMessage
                          id='date_first_day'
                          defaultMessage='First Day'
                        />
                      }
                    />
                    <FormDatepicker
                      isRequired
                      name='lastDay'
                      placeholder={intl.formatMessage(msg.datePickerTo)}
                      label={
                        <FormattedMessage
                          id='date_last_day'
                          defaultMessage='Last Day'
                        />
                      }
                    />
                  </FormRow>
                )}
                {values.kind === '50' && (
                  <FormRow
                    title={
                      <FormattedMessage
                        id='booking_reference'
                        defaultMessage='Booking reference'
                      />
                    }
                  >
                    <FormInput
                      isRequired
                      name='existingReservationReference'
                      label={
                        <FormattedMessage
                          id='booking_reference'
                          defaultMessage='Booking reference'
                        />
                      }
                    />
                  </FormRow>
                )}
                <ContactPerson
                  setPhoneInstance={setPhoneInstance}
                  kind={values.kind}
                  showTextArea={values.kind !== '0' && values.kind !== '10'}
                />
                {(values.kind === '0' || values.kind === '10') && (
                  <WhatAreYouLooking
                    isDiving={values.isDiving}
                    kind={values.kind}
                  />
                )}
                <div className='form-bottom'>
                  <div>
                    <p>
                      <FormattedMessage
                        id='booking_enquire.hint'
                        defaultMessage='Fine print: Don`t worry, there isn`t any! <new_line></new_line>This is only an enquiry and doesn`t commit you to anything.<new_line></new_line>
                  Sit back and relax while we do the work for you :)'
                        values={{
                          new_line: () => <br />,
                        }}
                      />
                    </p>
                    <br />
                    <p>
                      <FormattedMessage
                        id='booking_enquire.mandatory'
                        defaultMessage='* mandatory fields'
                      />
                    </p>
                    <p
                      className='error-booking-enquire'
                      ng-show='enquireForm.$invalid && enquireForm.$submitted'
                    >
                      <FormattedMessage
                        id='booking_enquire.mandatory_error'
                        defaultMessage='Some mandatory fields are incomplete, please review your enquiry and try to submit again'
                      />
                    </p>
                  </div>
                  <div>
                    <button className='btn-red' type='submit'>
                      <FormattedMessage
                        id='send_request'
                        defaultMessage='Send request'
                      />
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      )}
    </Modal>
  )
}

export default BookingEnquirePopup
