import { FormattedMessage } from 'react-intl'
import './checkbox.scss'

export default ({
  input,
  meta,
  label,
  className = '',
  disabled,
  checkboxOnChange,
}) => {
  const checkboxOnChangeHandler = (e) => {
    input.onChange(e)
    if(typeof checkboxOnChange === 'function') {
      checkboxOnChange(e)
    }
  }
  return (
    <div
      className={`checkbox-wrapper ${className} ${
        (meta.error || meta.submitError) && meta.touched ? ' error-field' : ''
      }`}
    >
      <label>
        <input
          name='isGoing'
          type='checkbox'
          {...input}
          disabled={disabled}
          onChange={checkboxOnChangeHandler}
        />
        <span className='checkbox-area' />
        <span className='checkbox-title'>
          {typeof label === 'object' && label.id ? (
            <FormattedMessage {...label} />
          ) : (
            label
          )}
        </span>
      </label>
    </div>
  )
}
