import { Fragment } from 'react'

export function toSquareMeters(data) {
  if(!data) { return null }

  return Math.round(data * 0.09290304)
}

export function toSquareFeets(data) {
  if(!data) { return null }

  return Math.round(data / 0.09290304)
}

export default function ConvertMetersToFeets({ data, currentUnits }) {
  if(!data) { return '—' }
  if(window.userLength === 0 && currentUnits === 10) {
    return (<Fragment>
      {data}m<sup>2</sup>
    </Fragment>)
  } else if(window.userLength === 0 && currentUnits !== 10) {
    return (<Fragment>
      {toSquareMeters(data)}m<sup>2</sup>
    </Fragment>)
  } else if(window.userLength === 1 && currentUnits !== 10) {
    return (<Fragment>
      {data}ft<sup>2</sup>
    </Fragment>)
  }

  return <Fragment>
    {toSquareFeets(data)}ft<sup>2</sup>
  </Fragment>
}
