import L from 'leaflet'

const dummyImagePath = `${window.staticPath}travel_dist/images/dsl_placeholder.jpg`
const dummyImagePathWebp = `${window.staticPath}travel_dist/images/dsl_placeholder.webp`

const defaultShopPicture = {
  url: dummyImagePath,
  webpUrl: dummyImagePathWebp,
}

const diveSite = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_site.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_site.svg`,
  iconSize: [21, 25],
})
const diveSiteSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_site_selected.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_site_selected.svg`,
  iconSize: [36, 43],
  iconAnchor: [20, 26],
})
const diveSiteBookable = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_site_bookable.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_site_bookable.svg`,
  iconSize: [21, 25],
})
const diveSiteBookableSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_site_bookable_selected.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_site_bookable_selected.svg`,
  iconSize: [36, 43],
  iconAnchor: [20, 26],
})
const diveShop = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_shop.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_shop.svg`,
  iconSize: [21, 25],
})
const diveShopSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_shop_selected.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_shop_selected.svg`,
  iconSize: [36, 43],
  iconAnchor: [20, 26],
})
const diveShopBookable = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_shop_bookable.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_shop_bookable.svg`,
  iconSize: [21, 25],
})
const diveShopBookableSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dive_shop_bookable_selected.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dive_shop_bookable_selected.svg`,
  iconSize: [36, 43],
  iconAnchor: [20, 26],
})
const locationIcon = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/location.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/location.svg`,
  iconSize: [21, 25],
})
const locationIconSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/location_selected.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/location_selected.svg`,
  iconSize: [36, 43],
  iconAnchor: [20, 26],
})

const dslShopPin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-shop-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-shop-pin.svg`,
  iconSize: [32, 32],
})

const dslShopPinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-shop-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-shop-pin.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const eLearningOnlyPin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/eLearningOnlyPin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/eLearningOnlyPin.svg`,
  iconSize: [32, 32],
})

const eLearningOnlyPinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/eLearningOnlyPin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/eLearningOnlyPin.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const dslFiveStarPin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-5star-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-5star-pin.svg`,
  iconSize: [32, 32],
})

const dslFiveStarPinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-5star-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-5star-pin.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const dslDiveSitePin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-dive-site-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-dive-site-pin.svg`,
  iconSize: [32, 32],
})

const dslDiveSitePinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-dive-site-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-dive-site-pin.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const dslProfessionalPin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-professional.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-professional.svg`,
  iconSize: [32, 32],
})

const dslProfessionalPinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-professional.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-professional.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const dslConservationPin = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-conservation-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-conservation-pin.svg`,
  iconSize: [32, 32],
})

const dslConservationPinSelected = new L.Icon({
  iconUrl: `${window.staticPath}travel_dist/images/dsl/dsl-conservation-pin.svg`,
  iconRetinaUrl: `${window.staticPath}travel_dist/images/dsl/dsl-conservation-pin.svg`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 22.5],
})

const mapboxToken =
  'pk.eyJ1IjoiZGl2aWFjIiwiYSI6ImNiZjU5MGVlY2VkZmQ4YWFkNGJmMWVjMDVjMDUzNDdlIn0.62dEH0gmRXAGO5e2x1JnRg'
const tiandituToken = '8e9223d593890349336508b16d27fb3a'
const sateliteTilesUrl = `//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`
const mapboxOutdoorsTilesUrl = `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`
const mapboxLightTilesUrl = `https://api.mapbox.com/styles/v1/diviac/clfqjxohb000401o2lohbtcrf/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const frenchLngMap = `https://api.mapbox.com/styles/v1/diviac/clf9diol0003z01qoyafb0jtz/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const germanLngMap = `https://api.mapbox.com/styles/v1/diviac/clf8zc7mv002q01ocr0zslt8k/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const italianLngMap = `https://api.mapbox.com/styles/v1/diviac/clfqja7qm000101qvfhco6nue/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const jpLngMap = `https://api.mapbox.com/styles/v1/diviac/clfqjbroq000101qxr9plmbxk/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const koLngMap = `https://api.mapbox.com/styles/v1/diviac/clfqjdfq0000101pjctmzt7gs/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const esLngMap = `https://api.mapbox.com/styles/v1/diviac/clfqjeur8000001qstl8qar2o/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const zhHansMap = `https://api.mapbox.com/styles/v1/diviac/clfqjhbna000401pj2uklypwm/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const zhHantMap = `https://api.mapbox.com/styles/v1/diviac/clfqjkb0a000101qzzl8vco2y/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`

const mapList = {
  en: mapboxLightTilesUrl,
  nl: mapboxLightTilesUrl,
  fr: frenchLngMap,
  de: germanLngMap,
  it: italianLngMap,
  ja: jpLngMap,
  ko: koLngMap,
  es: esLngMap,
  'zh-hant': zhHantMap,
  'zh-hans': zhHansMap,
}

const tiandituMarkTilesUrl = `https://t4.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=${tiandituToken}`
const tiandituSateliteTilesUrl = `https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${tiandituToken}`
export const tiandituTilesUrl = `https://t2.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=${tiandituToken}`

const ChinaCode = ['CN', 'CHN']

export const isMainLand = (countryCode) => {
  return ChinaCode.indexOf(countryCode) !== -1
}

const getMapTileUrl = ({ isSateliteView, countryCode, language, isLight }) => {
  if(isMainLand(countryCode)) {
    return isSateliteView ? tiandituSateliteTilesUrl : tiandituMarkTilesUrl
  }
  if(language && !isSateliteView && mapList[language]) {
    return mapList[language]
  }

  return isSateliteView
    ? sateliteTilesUrl
    : isLight
      ? mapboxLightTilesUrl
      : mapboxOutdoorsTilesUrl
}

export {
  diveSite,
  diveSiteSelected,
  diveSiteBookable,
  diveSiteBookableSelected,
  diveShop,
  diveShopBookableSelected,
  diveShopSelected,
  diveShopBookable,
  locationIcon,
  locationIconSelected,
  dummyImagePath,
  dummyImagePathWebp,
  defaultShopPicture,
  dslShopPin,
  dslFiveStarPin,
  dslDiveSitePin,
  dslShopPinSelected,
  dslFiveStarPinSelected,
  dslDiveSitePinSelected,
  dslConservationPin,
  dslConservationPinSelected,
  dslProfessionalPin,
  dslProfessionalPinSelected,
  sateliteTilesUrl,
  mapboxOutdoorsTilesUrl,
  getMapTileUrl,
  eLearningOnlyPinSelected,
  eLearningOnlyPin,
}
