import each from 'lodash/each'
import { getLanguage } from './utils'
import { padiProLocalizedUrls } from './padi-pro-localized-urls'
import { padiLearningLocalizedUrls } from './padi-learning-localized-urls'

export const routes = {
  en: {
    homepage: '/',
    login: '/login/',
    search_la: '/s/liveaboards/:slug/',
    fleets: '/fleets/:slug/',
    search_dr: '/s/dive-resorts/:slug/',
    search_dc: '/s/dive-centers/:slug/',
    search_diving: '/s/diving/:slug/',
    search_courses: '/s/dive-courses/:slug/',
    search_snorkeling: '/s/snorkeling/:slug/',
    search_conservation: '/conservation/activities/results/:slug/',
    la_page: '/liveaboard/:location/:slug/',
    dr_page: '/dive-resort/:location/:slug/',
    dc_page: '/dive-center/:location/:slug/',
    destination: '/d/:location/',
    destinations: '/destinations/',
    checkout_la: '/checkout/liveaboard/:location/:slug/',
    checkout_dr: '/checkout/dive-resort/:location/:slug/',
    widget_search_la: '/widget/s/:slug/',
    widget_search_dr: '/widget/s/dr/:slug/',
    group_trips: '/s/group-trips/:slug/',
    profile: '/profile/',
    dive_buddies: '/dive-buddies/',
    settings: '/settings/',
    preferences: '/preferences/',
    payment_and_coupons: '/payment-and-coupons/',
    bookings: '/bookings/',
    booking_info: '/bookings/:id/',
    wishlist: '/wishlist/',
    recently_viewed: '/recently-viewed/',
    register_completed: '/register/completed/',
    terms: '/terms/',
    checkout_adventures: '/checkout/adventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/diving-in/:slug/',
    dive_sites: '/dive-sites/:slug/',
    dive_sites_world: '/dive-sites/world/',
    dive_site: '/dive-site/:location/:slug/',
    la_deals: '/liveaboard-deals/',
    dr_deals: '/dive-resort-deals/',
    gis: '/bookings/:id/:uuid/',
    help_center: 'https://padi.force.com/travelhelp/s/',
    travel_resources:
      'https://blog.padi.com/covid-19-diving-travel-restrictions-by-country/',
    'aff-bookings': '/aff-bookings/',
    dive_shops: '/dive-shops/:slug',
    adventures: '/adventures/',
    logout: '/logout/',
  },
  fr: {
    homepage: '/fr/',
    login: '/fr/login/',
    search_la: '/fr/s/croisieres-plongee/:slug/',
    fleets: '/fr/flottes/:slug/',
    search_dr: '/fr/s/resorts-plongee/:slug/',
    search_dc: '/fr/s/centres-plongee/:slug/',
    search_diving: '/fr/s/plongee/:slug/',
    search_courses: '/fr/s/cours-plongee/:slug/',
    search_snorkeling: '/fr/s/snorkeling/:slug/',
    search_conservation: '/fr/preservation/activities/results/:slug/',
    la_page: '/fr/croisiere-plongee/:location/:slug/',
    dr_page: '/fr/resort-plongee/:location/:slug/',
    dc_page: '/fr/centre-plongee/:location/:slug/',
    destination: '/fr/d/:location/',
    destinations: '/fr/destinations/',
    checkout_la: '/fr/checkout/croisiere-plongee/:location/:slug/',
    checkout_dr: '/fr/checkout/resort-plongee/:location/:slug/',
    widget_search_la: '/fr/widget/s/:slug/',
    widget_search_dr: '/fr/widget/s/dr/:slug/',
    group_trips: '/fr/s/voyages-en-groupe/:slug/',
    profile: '/fr/profil/',
    dive_buddies: '/fr/dive-buddies/',
    settings: '/fr/parametres/',
    preferences: '/fr/preferences/',
    payment_and_coupons: '/fr/paiement-et-codes-reduction/',
    bookings: '/fr/reservations/',
    booking_info: '/fr/reservations/:id/',
    wishlist: '/fr/wishlist/',
    recently_viewed: '/fr/consultes-recemment/',
    register_completed: '/fr/inscription/termine/',
    terms: '/fr/conditions/',
    checkout_adventures: '/fr/consulter/aventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/fr/plonger-en/:slug/',
    dive_sites: '/fr/sites-plongee/:slug/',
    dive_sites_world: '/fr/sites-plongee/monde/',
    dive_site: '/fr/site-plongee/:location/:slug/',
    la_deals: '/fr/croisiere-plongee-offres/',
    dr_deals: '/fr/sejour-plongee-offres/',
    gis: '/fr/reservations/:id/:uuid/',
    help_center: 'https://padi.force.com/travelhelp/s/',
    travel_resources:
      'https://blog.padi.com/fr/les-restrictions-de-plongee-et-de-voyage-covid-19-par-pays/',
    'aff-bookings': '/fr/aff-bookings/',
    dive_shops: '/fr/magasins-plongee/:slug',
    adventures: '/fr/adventures/',
    logout: '/logout/',
  },
  es: {
    homepage: '/es/',
    login: '/es/iniciar-sesion/',
    search_la: '/es/s/cruceros-de-buceo/:slug/',
    fleets: '/es/flotas/:slug/',
    search_dr: '/es/s/resorts-de-buceo/:slug/',
    search_dc: '/es/s/centros-buceo/:slug/',
    search_diving: '/es/s/buceo/:slug/',
    search_courses: '/es/s/cursos-buceo/:slug/',
    search_snorkeling: '/es/s/esnorquel/:slug/',
    search_conservation: '/es/conservacion/actividades/resultados/:slug/',
    la_page: '/es/crucero-de-buceo/:location/:slug/',
    dr_page: '/es/resort-de-buceo/:location/:slug/',
    dc_page: '/es/centro-buceo/:location/:slug/',
    destination: '/es/d/:location/',
    destinations: '/es/destinos/',
    checkout_la: '/es/proceso-de-compra/crucero-de-buceo/:location/:slug/',
    checkout_dr: '/es/proceso-de-compra/resort-de-buceo/:location/:slug/',
    widget_search_la: '/es/widget/s/:slug/',
    widget_search_dr: '/es/widget/s/dr/:slug/',
    group_trips: '/es/s/viajes-en-grupo/:slug/',
    profile: '/es/perfil/',
    dive_buddies: '/es/dive-buddies/',
    settings: '/es/ajustes/',
    preferences: '/es/preferencias/',
    payment_and_coupons: '/es/pago-y-cupones/',
    bookings: '/es/reservas/',
    booking_info: '/es/reservas/:id/',
    wishlist: '/es/lista-de-favoritos/',
    recently_viewed: '/es/recien-visitados/',
    register_completed: '/es/registro/completado/',
    terms: '/es/términos/',
    checkout_adventures: '/es/finalizar-compra/aventura/:activityId/:date/',
    // dive-guides urls
    diving_in: '/es/diving-in/:slug/',
    dive_sites: '/es/sitios-buceo/:slug/',
    dive_sites_world: '/es/sitios-buceo/mundo/',
    dive_site: '/es/sitio-buceo/:location/:slug/',
    la_deals: '/es/ofertas-crucero-de-buceo/',
    dr_deals: '/es/ofertas-resort-de-buceo/',
    gis: '/es/reservas/:id/:uuid/',
    help_center: 'https://padi.force.com/travelhelp/s/',
    travel_resources:
      'https://blog.padi.com/es/restricciones-de-viajes-y-buceo-covid-19-por-pais/',
    'aff-bookings': '/es/aff-bookings/',
    dive_shops: '/es/tiendas-buceo/:slug',
    adventures: '/es/adventures/',
    logout: '/logout/',
  },
  de: {
    homepage: '/de/',
    login: '/de/login/',
    search_la: '/de/s/tauchsafaris/:slug/',
    fleets: '/de/flotten/:slug/',
    search_dr: '/de/s/tauchresorts/:slug/',
    search_dc: '/de/s/tauchcenter/:slug/',
    search_diving: '/de/s/tauchen/:slug/',
    search_courses: '/de/s/tauchkurse/:slug/',
    search_snorkeling: '/de/s/schnorcheln/:slug/',
    search_conservation: '/de/schutz/aktivitaten/ergebnisse/:slug/',
    la_page: '/de/tauchsafari/:location/:slug/',
    dr_page: '/de/tauchresort/:location/:slug/',
    dc_page: '/de/tauchcenter/:location/:slug/',
    destination: '/de/d/:location/',
    destinations: '/de/reiseziele/',
    checkout_la: '/de/kasse/tauchsafari/:location/:slug/',
    checkout_dr: '/de/kasse/tauchresort/:location/:slug/',
    widget_search_la: '/de/widget/s/:slug/',
    widget_search_dr: '/de/widget/s/dr/:slug/',
    group_trips: '/de/s/gruppenreisen/:slug/',
    profile: '/de/profil/',
    dive_buddies: '/de/dive-buddies/',
    settings: '/de/einstellungen/',
    preferences: '/de/praeferenzen/',
    payment_and_coupons: '/de/zahlung-und-coupons/',
    bookings: '/de/buchungen/',
    booking_info: '/de/buchungen/:id/',
    wishlist: '/de/wunschzettel/',
    recently_viewed: '/de/vor-kurzem-angesehen/',
    register_completed: '/de/registrieren/abgeschlossen/',
    terms: '/de/teilnahmebedingungen/',
    checkout_adventures: '/de/zahlung/adventure/:activityId/:date/',
    // dive-guides urls
    diving_in: '/de/tauchen-in/:slug/',
    dive_sites: '/de/tauchplaetze/:slug/',
    dive_sites_world: '/de/tauchplaetze/weltweit/',
    dive_site: '/de/tauchplatz/:location/:slug/',
    la_deals: '/de/tauchsafari-angebote/',
    dr_deals: '/de/tauchresort-angebote/',
    gis: '/de/buchungen/:id/:uuid/',
    help_center: 'https://padi.force.com/travelhelp/s/',
    travel_resources:
      'https://blog.padi.com/covid-19-diving-travel-restrictions-by-country/',
    'aff-bookings': '/de/aff-bookings/',
    dive_shops: '/de/tauchshops/:slug',
    adventures: '/de/adventures/',
    logout: '/logout/',
  },
  it: {
    homepage: '/it/',
    login: '/it/login/',
    search_la: '/it/s/crociere-sub/:slug/',
    fleets: '/it/flotte/:slug/',
    search_dr: '/it/s/viaggi-sub/:slug/',
    search_dc: '/it/s/centri-sub/:slug/',
    search_diving: '/it/s/immersioni/:slug/',
    search_courses: '/it/s/corsi-sub/:slug/',
    search_snorkeling: '/it/s/snorkeling/:slug/',
    search_conservation: '/it/salvaguardia/attivita/risultati/:slug/',
    la_page: '/it/crociera-sub/:location/:slug/',
    dr_page: '/it/viaggio-sub/:location/:slug/',
    dc_page: '/it/centro-sub/:location/:slug/',
    destination: '/it/d/:location/',
    destinations: '/it/destinazioni/',
    checkout_la: '/it/checkout/crociera-sub/:location/:slug/',
    checkout_dr: '/it/checkout/viaggio-sub/:location/:slug/',
    widget_search_la: '/it/widget/s/:slug/',
    widget_search_dr: '/it/widget/s/dr/:slug/',
    group_trips: '/it/s/viaggi-di-gruppo/:slug/',
    profile: '/it/profilo/',
    dive_buddies: '/it/dive-buddies/',
    settings: '/it/impostazioni/',
    preferences: '/it/preferenze/',
    payment_and_coupons: '/it/pagamento-e-coupon/',
    bookings: '/it/prenotazioni/',
    booking_info: '/it/prenotazioni/:id/',
    wishlist: '/it/lista-dei-desideri/',
    recently_viewed: '/it/visti-recentemente/',
    register_completed: '/it/registra/completato/',
    terms: '/it/termini/',
    checkout_adventures: '/it/checkout/avventura/:activityId/:date/',
    // dive-guides urls
    diving_in: '/it/immersioni-in/:slug/',
    dive_sites: '/it/siti-immersione/:slug/',
    dive_sites_world: '/it/siti-immersione/mondo/',
    dive_site: '/it/sito-immersione/:location/:slug/',
    la_deals: '/it/offerte-crociere-sub/',
    dr_deals: '/it/offerte-viaggi-sub/',
    gis: '/it/prenotazioni/:id/:uuid/',
    help_center: 'https://padi.force.com/travelhelp/s/',
    travel_resources:
      'https://blog.padi.com/it/quali-sono-le-restrizioni-covid-19-per-la-subacquea-e-i-viaggi-nei-paesi/',
    'aff-bookings': '/it/aff-bookings/',
    dive_shops: '/it/centri-sub/:slug',
    logout: '/logout/',
  },
  nl: {
    dive_shops: '/nl/duikcentra/:slug',
    adventures: '/it/adventures/',
    logout: '/logout/',
  },
}

export const coursesUrls = {
  en: 'https://www.padi.com/courses/:slug/',
  fr: 'https://www.padi.com/fr/cours/:slug/',
  de: 'https://www.padi.com/de/kurse/:slug/',
  it: 'https://www.padi.com/it/corsi/:slug/',
  es: 'https://www.padi.com/es/cursos/:slug/',
  nl: 'https://www.padi.com/nl/cursus/:slug/',
  ja: 'https://www.padi.com/ja/courses/:slug/',
  ko: 'https://www.padi.com/ko/courses/:slug/',
  'zh-hans': 'https://www.padi.com/zh-hans/courses/:slug/',
  'zh-hant': 'https://www.padi.com/zh-hant/courses/:slug/',
}

export const APP_LANGUAGE = getLanguage()

export function createFullUrl(path, language) {
  let lang = language || APP_LANGUAGE

  if(!routes[lang]) {
    lang = 'en'
  }

  return routes[lang][path]
}

export function createUrl(path, params, query) {
  try {
    let url = createFullUrl(path)

    if(params) {
      each(params, function(val, key) {
        url = url.replace(':' + key, val)
      })
    }

    if(query) {
      url += query
    }

    return url
  } catch (e) {
    console.error(`"${path}" for locale "${APP_LANGUAGE}" - doesn't find`)
    return ''
  }
}

export const createTravelDomainUrl = function(path, params, query) {
  const travelDomain = process.env.DOMAIN
  const relativeUrl = createUrl(path, params, query)

  return `${travelDomain}${relativeUrl}`
}

export function createPadiProLocalizedUrl(path, query) {
  const lang = padiProLocalizedUrls[APP_LANGUAGE] ? APP_LANGUAGE : 'en'
  let url = padiProLocalizedUrls[lang][path]

  if(url && query) {
    return `${url}${query}`
  }

  return url || ''
}

export function createPadiLearningLocalizedUrl(path, query) {
  const lang = padiLearningLocalizedUrls[APP_LANGUAGE] ? APP_LANGUAGE : 'en'
  let url = padiLearningLocalizedUrls[lang][path]

  if(url && query) {
    return `${url}${query}`
  }

  return url || ''
}

export const createPadiCourseUrl = function(slug, query) {
  let lang = coursesUrls[APP_LANGUAGE] ? APP_LANGUAGE : 'en'

  let url = coursesUrls[lang]
  url = url.replace(':slug', slug)

  if(query) {
    url += query
  }

  return url
}
