import { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BookingEnquirePopup from '../popups/BookingEnquirePopup'

export default function IndividualBooking() {
  const [isOpen, triggerPopup] = useState(false)
  const openPopup = () => triggerPopup(true)
  const closePopup = () => triggerPopup(false)

  return (
    <Fragment>
      <a
        onClick={openPopup}
        className='email-us-link'
        data-fake-url='mail-us'
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='menu-travelmain-contactus'
      >
        <FormattedMessage id='email.us' defaultMessage='Email us' />
      </a>
      <BookingEnquirePopup closeModal={closePopup} isOpen={isOpen} />
    </Fragment>
  )
}
