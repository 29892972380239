export default function PlaceholderResortPricing() {
  return (
    <div className='resort-pricing-row placeholder placeholder-animation'>
      <div className='resort-pricing__rooms'>
        <div className='shop-title' />
        <div className='image' />
        <div className='text' />
        <div className='text' />
        <div className='text' />
        <div className='text' />
      </div>
      <div className='resort-pricing__whats-included'>
        <div className='text' />
        <div className='text' />
        <div className='text' />
        <div className='text' />
        <div className='text' />
        <div className='text' />
      </div>
      <div className='resort-pricing__meal-plan'>
        <div className='text' />
        <div className='text' />
        <div className='text' />
      </div>
      <div className='resort-pricing__price'>
        <div className='main-price' />
        <div className='text' />
        <div className='price' />
        <div className='text' />

        <div className='btn' />
      </div>
    </div>
  )
}
