import { FormattedMessage } from 'react-intl'

export function FinalFormTextArea({
  input,
  meta,
  placeholder,
  label,
  icon,
  rows,
  cols,
  disabled,
  hint,
  isRequired,
  className = '',
}) {
  const error = meta.touched && (meta.error || meta.submitError)
  const onChange = ({ target }) => {
    let value = target.value
    input.onChange(value)
  }
  return (
    <div className={`input-wrapper ${error ? 'error-field' : ''} ${className}`}>
      <label>
        {typeof label === 'object' && label.id ? (
          <FormattedMessage {...label} />
        ) : (
          label
        )}
        {isRequired ? <span className='asterisk'>*</span> : ''}
      </label>
      <div className={icon ? 'input-container--icon' : ''}>
        {icon || ''}
        <textarea
          className='input'
          rows={rows}
          cols={cols}
          {...input}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && meta.touched && <div className='error-text'>{error}</div>}
      </div>
    </div>
  )
}
