import { Fragment, Component, createRef } from 'react'
import moment from 'moment'
import TravelCalendar from '../../libs/calendar'
import isNull from 'lodash/isNull'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import each from 'lodash/each'
import { injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { APP_LANGUAGE } from '../../localized-urls'
import API from '../../api'

import scheduleHeaderStyles from '../dive-center/schedule_DSL/scheduleHeader.module.scss'
import cx from 'classnames'

const msg = defineMessages({
  from: {
    id: 'from',
    defaultMessage: 'From',
  },
  to: {
    id: 'to',
    defaultMessage: 'To',
  },
  available: {
    id: 'available',
    defaultMessage: 'Available',
  },
  soldOut: {
    id: 'sold_out',
    defaultMessage: 'Sold out',
  },
  onRequest: {
    id: 'on_request',
    defaultMessage: 'On request',
  },
  select: {
    id: 'select',
    defaultMessage: 'Select',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  clear: {
    id: 'clear',
    defaultMessage: 'Clear',
  },
  possibleStart: {
    id: 'possible_start',
    defaultMessage: 'Possible start date',
  },
  possibleEnd: {
    id: 'possible_end',
    defaultMessage: 'Possible end date',
  },
  minDuration: {
    id: 'min_duration',
    defaultMessage:
      'Minimal duration { minNights } nights. Maximum duration { maxNights } nights',
  },
  dateErrorLabel: {
    id: 'date_error',
    defaultMessage:
      'Package must start on a date highlighted with a circle. Please choose new dates',
  },
  highlightedDatesMsg: {
    id: 'highlighted_msg',
    defaultMessage: 'Please choose an end date highlighted in blue',
  },
  nights: {
    id: 'nights',
    defaultMessage: 'nights',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  night_qty: {
    id: 'night_qty',
    defaultMessage: '{qty, plural, one {# night} other {# nights}}',
  },
  minStudentDuration: {
    id: 'min_student_duration',
    defaultMessage: 'Student packages must be { minNights } nights or longer',
  },
  adventureAvailable: {
    id: 'available',
    defaultMessage: 'Available',
  },
})

const VACATION_AVAILABILITY_MONTHES = 36
class TravelDatepicker extends Component {
  constructor(props) {
    super(props)

    this.inputRef = createRef()
  }

  componentDidMount() {
    const { intl } = this.props
    this.calendar = new TravelCalendar({
      element: this.inputRef.current,
      dateFrom: this.props.dateFrom || this.inputRef.current.value,
      dateTo: this.props.dateTo || null,
      dateToInputId: this.props.dateToInputId,
      monthNumber: VACATION_AVAILABILITY_MONTHES,
      language: APP_LANGUAGE,
      dateFromLabel: intl.formatMessage(this.props.labelFrom || msg.from),
      dateToLabel: intl.formatMessage(msg.to),
      showLegend: this.props.showLegend,
      isDiveCenterCalendar: this.props.isDiveCenterCalendar,
      availableLabel: intl.formatMessage(msg.adventureAvailable),
      minDuration: this.props.minDuration,
      maxDuration: this.props.maxDuration,
      showDuration: this.props.showDuration,
      roomAvailableLabel: intl.formatMessage(msg.available),
      roomSoldOutLabel: intl.formatMessage(msg.soldOut),
      roomNoInfoLabel: intl.formatMessage(msg.onRequest),
      selectBtnLabel: intl.formatMessage(msg.select),
      closeBtnLabel: intl.formatMessage(msg.close),
      clearBtnLabel: intl.formatMessage(msg.clear),
      possibleStartDateLabel: intl.formatMessage(msg.possibleStart),
      possibleEndDateLabel: intl.formatMessage(msg.possibleEnd),
      calendarErrorLabel: intl.formatMessage(msg.minDuration, {
        minNights: this.props.minDuration || 1,
        maxNights: this.props.maxDuration || 21,
      }),
      labelFromHint: this.props.labelFromHint,
      labelClearHint: this.props.labelClearHint,
      dateErrorLabel: intl.formatMessage(msg.dateErrorLabel),
      highlghtedDatesMsg: intl.formatMessage(msg.highlightedDatesMsg),
      isFixedPackage: this.props.isFixedPackage,
      validateStudentsPackage: this.props.validateStudentsPackage,
      students: this.props.students,
      minDurationForStudents: this.props.minDurationForStudents || 5,
      calendarStudentsErrorLabel: intl.formatMessage(msg.minStudentDuration, {
        minNights: this.props.minDurationForStudents || 5,
      }),
      nightsLabel: intl.formatMessage(msg.nights),
      disableInput: this.props.disableInput,
      availableDatesAdventures: this.props.availableDatesAdventures,
      closeOnSelect: this.props.closeOnSelect,
      onReady: () => {
        if(this.props.datesInOneField) {
          this.formatInputValue(
            this.props.dateFrom,
            this.props.dateTo,
            moment(this.props.dateTo).diff(this.props.dateFrom, 'd')
          )
        }

        this.updateAvailableDates()
        this.getPackagesData()
      },
      onSelectDates: (dates) => {
        this.inputRef.current.value = dates.dateFromFormatted

        if(this.props.dateToInputId) {
          const elem2 = document.getElementById(this.props.dateToInputId)
          elem2.value = dates.dateToFormatted
        }

        if(this.props.updateDuration) {
          dates.duration = dates.dateTo
            ? moment(dates.dateTo).diff(dates.dateFrom, 'd')
            : undefined
        }

        if(this.props.datesInOneField) {
          this.formatInputValue(dates.dateFrom, dates.dateTo, dates.duration)
        }

        if(typeof this.props.callback === 'function') {
          this.props.callback(dates)
        }
      },
      rangePicker: this.props.range || false,
    })

    if(this.props.getInputRef) {
      this.props.getInputRef(this.inputRef.current)
    }
  }

  componentDidUpdate(prevProps) {
    if(
      prevProps.dateFrom !== this.props.dateFrom ||
      prevProps.dateTo !== this.props.dateTo ||
      JSON.stringify(prevProps.guestsSplit) !==
        JSON.stringify(this.props.guestsSplit)
    ) {
      this.calendar.updateDates(this.props.dateFrom, this.props.dateTo)
      this.updateAvailableDates()
      this.formatInputValue(
        this.props.dateFrom,
        this.props.dateTo,
        this.props.dateTo
          ? moment(this.props.dateTo).diff(this.props.dateFrom, 'd')
          : undefined
      )
      this.calendar.updateNumbeOfStudents(this.props.students)
    }
    if(
      prevProps.availableDatesAdventures !== this.props.availableDatesAdventures
    ) {
      this.calendar.updateAvailableDatesAdventures(
        this.props.availableDatesAdventures
      )
    }
    if(moment(this.props.dateFrom) < moment().startOf('day')) {
      this.formatInputValue(
        this.props.dateFrom,
        this.props.dateTo,
        this.props.dateTo
          ? moment(this.props.dateTo).diff(this.props.dateFrom, 'd')
          : undefined
      )
    }
  }

  formatInputValue(from, to, duration) {
    let momentDateFrom = moment(from)
    let momentDateTo = moment(to)
    let formatDateFrom = 'DD MMM YYYY'
    let shouldUpdateDates = false

    if(momentDateTo.format('YYYY') === momentDateFrom.format('YYYY')) {
      formatDateFrom = 'DD MMM'
    }

    if(from && momentDateFrom < moment().startOf('day')) {
      momentDateFrom = moment()
      shouldUpdateDates = true
    }

    if(to && momentDateTo <= momentDateFrom) {
      momentDateTo = moment(from).add(7, 'd')
      shouldUpdateDates = true
    }

    if(to && momentDateTo.diff(from, 'd') > (this.props.maxDuration || 21)) {
      momentDateTo = moment(from).add(this.props.maxDuration || 21, 'd')
      duration = this.props.maxDuration || 21
      shouldUpdateDates = true
    }

    if(from && to) {
      this.inputRef.current.value = `${momentDateFrom.format(
        formatDateFrom
      )} - ${momentDateTo.locale(APP_LANGUAGE).format('DD MMM YYYY')}`

      if(duration) {
        this.inputRef.current.value += `, ${this.props.intl.formatMessage(
          msg.night_qty,
          { qty: duration }
        )}`
      }
    } else if(from && !to) {
      this.inputRef.current.value = `${momentDateFrom.format('DD MMM YYYY')}`
    }
    if(shouldUpdateDates) {
      this.props.callback({
        dateFrom: momentDateFrom.format('YYYY-MM-DD'),
        dateTo: momentDateTo.format('YYYY-MM-DD'),
        duration: duration < 0 ? 7 : duration,
      })
    }
  }

  updateAvailableDates() {
    if(this.props.shopId && !this.props.isFixedPackage) {
      API(`booking/resort/${this.props.shopId}/availability/`)
        .get({
          date_after: moment().format('YYYY-MM-DD'),
          date_before: moment()
            .add(VACATION_AVAILABILITY_MONTHES, 'M')
            .format('YYYY-MM-DD'),
          guests_split: this.props.guestsSplit,
          nights: this.props.nights || 7,
        })
        .then((dates) => {
          this.calendar.updateAvailableDates(dates)
        })
    }
  }

  getPackagesData() {
    if(this.props.isFixedPackage && this.props.shopSlug) {
      API(`booking/resort/${this.props.shopSlug}/packages-data/`)
        .get({
          guests_split: this.props.guestsSplit,
        })
        .then((pricing) => {
          let pricesData = []
          let groupedPricesData = groupBy(
            flatten(
              map(pricing, function(val) {
                return val.pricesData
              })
            ),
            'date'
          )
          each(groupedPricesData, function(data) {
            if(!Array.isArray(data)) {
              return
            }
            var dateObj = { date: data[0].date, allowedLength: [] }

            each(data, function(day, i) {
              dateObj.allowedLength = uniq(
                dateObj.allowedLength.concat(day.allowedLength)
              )

              if(isNull(day.itemsLeft) === false) {
                if(!dateObj.itemsLeft) {
                  dateObj.itemsLeft = 0
                }

                dateObj.itemsLeft += day.itemsLeft
              }
            })

            pricesData.push(dateObj)
          })

          this.calendar.updatePricesData(pricesData)
        })
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.withIcon ? (
          <Fragment>
            <i
              className={cx(
                scheduleHeaderStyles.fieldIcon,
                'dsl-icons dsl-icons--calendar field-icon'
              )}
            />
            {this.props.isSchedule && (
              <i
                className={cx(
                  scheduleHeaderStyles.fieldIcon,
                  scheduleHeaderStyles.onlyMobile,
                  'dsl-icons dsl-icons--dots-vertical field-icon only-mobile'
                )}
              />
            )}
          </Fragment>
        ) : null}
        <input
          ref={this.inputRef}
          autoComplete='off'
          id={this.props.id}
          placeholder={this.props.intl.formatMessage(
            this.props.placeholder || msg.date
          )}
          className={this.props.className}
          style={this.props.style}
        />
      </Fragment>
    )
  }
}

TravelDatepicker.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(TravelDatepicker)
