export default function FormRow({title, children}) {
  return <div className='form-row'>
    <div className='col-names'>
      { title }
    </div>
    <div className='col-info'>
      { children }
    </div>
  </div>
}
