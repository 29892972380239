import { createAction } from 'redux-actions'
import get from 'lodash/get'

const SET_NUMBER_OF_TRIPS = 'SET_NUMBER_OF_TRIPS'
const setNumberOfTrips = createAction(SET_NUMBER_OF_TRIPS)

function numberOfTrips(
  state = get(window, 'locationInfo.totalCount', 0),
  { type, payload }
) {
  switch (type) {
    case SET_NUMBER_OF_TRIPS:
      return payload
    default:
      return state
  }
}
const SET_IS_NEAR_BY = 'SET_IS_NEAR_BY'
const setIsNearBy = createAction(SET_IS_NEAR_BY)

function isNearBy(state = false, { type, payload }) {
  switch (type) {
    case SET_IS_NEAR_BY:
      return payload
    default:
      return state
  }
}

const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'
const setActivePage = createAction(SET_ACTIVE_PAGE)

function activePage(state = 1, { type, payload }) {
  switch (type) {
    case SET_ACTIVE_PAGE:
      return payload
    default:
      return state
  }
}

const SET_FILTERS = 'SET_FILTERS'
const setSearchFilters = createAction(SET_FILTERS)

const CLEAR_FILTERS = 'CLEAR_FILTERS'
const clearSearchFilters = createAction(CLEAR_FILTERS)

const initialState = {}

function searchFilters(state = initialState, { type, payload }) {
  switch (type) {
    case SET_FILTERS:
      return { ...state, ...payload }
    case CLEAR_FILTERS:
      return initialState
    default:
      return state
  }
}

const FOCUS_SEARCH_INPUT = 'FOCUS_SEARCH_INPUT'
const setInputFocus = createAction(FOCUS_SEARCH_INPUT)

function inputFocus(state = false, { type, payload }) {
  switch (type) {
    case FOCUS_SEARCH_INPUT:
      return payload
    default:
      return state
  }
}

export {
  setNumberOfTrips,
  numberOfTrips,
  setIsNearBy,
  isNearBy,
  setSearchFilters,
  searchFilters,
  clearSearchFilters,
  setActivePage,
  activePage,
  inputFocus,
  setInputFocus,
}
