/* eslint-disable camelcase */
import { pushToDataLayer } from 'gtm'
import { toast } from 'react-toastify'
import { onClick } from '../../directives'
import messages from '../../messages'

// TODO: update react-intl lib to allow using react hooks in any functions.
const openSalesForcePopup = (shopInfo, intl, isMap, message) => {
  if(!shopInfo) {
    return
  }
  const { email, affiliateId, title, locationTitle, countryTitle } = shopInfo
  pushToDataLayer({
    event: 'contact_shop',
    store_id: affiliateId,
    shop_name: title,
    shop_email: email,
    shop_country: countryTitle,
    shop_location: locationTitle,
    click_position: isMap ? 'Map' : 'Listing',
  })
  pushToDataLayer({
    eventAction: `Contact - Lead Form${isMap ? ' (Map)' : ''}`,
    eventLabel: shopInfo.id,
    eventValue: 1,
    eventNonInteraction: false,
  })

  if(!window.dataLayer) {
    const translatedMessage = intl
      ? intl.formatMessage(messages.unableOpenPopup)
      : message
    toast.error(translatedMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  }
}

const navigationClicks = () => {
  onClick('[data-navigation-elements]', (element) => {
    const { regionIdentifier, fakeUrl } = element.dataset
    const link = element.href || fakeUrl
    const href = linkTransform(link)

    pushToDataLayer({
      event: 'interaction',
      eventCategory: 'Navigation',
      eventAction: 'Click',
      eventLabel: `${removeQueryParams(
        window.englishUrl,
      )}_${regionIdentifier}_${href}`,
      eventValue: 0,
      eventNonInteraction: false,
    })
  })
}

const trackClick = ({
  regionIdentifier,
  link,
  eventCategory = 'Navigation',
  eventAction = 'Click',
  eventValue = 0,
  customLabel,
}) => {
  const href = linkTransform(link)

  pushToDataLayer({
    event: 'interaction',
    eventCategory,
    eventAction,
    eventLabel:
      customLabel ||
      `${removeQueryParams(window.englishUrl)}_${regionIdentifier}_${href}`,
    eventValue,
    eventNonInteraction: false,
  })
}

const getProductCategory = (type) => {
  switch (type) {
    case 'Diving':
    case 'dive-trips':
    case 'dive_trip':
    case 10:
      return {
        checkoutType: 'Adventures - Diving',
        type: 'Diving (Adventures)',
        activityType: 'Diving',
        activityPageType:
          'Travel Adventures - Dive Center/Resort Page - Diving Offered by the Operator',
        productList: 'Travel Adventures - Dive Trips Listing',
      }
    case 'Snorkeling':
    case 'snorkelling':
    case 20:
      return {
        checkoutType: 'Adventures - Snorkeling',
        type: 'Snorkeling (Adventures)',
        activityType: 'Snorkeling',
        activityPageType:
          'Travel Adventures - Dive Center/Resort Page - Snorkeling Offered by the Operator',
        productList: 'Travel Adventures - Snorkeling Trips Listing',
      }
    case 'Courses':
    case 'courses':
    case 'course':
    case 30:
      return {
        checkoutType: 'Adventures - Courses',
        type: 'Courses (Adventures)',
        activityType: 'Courses',
        activityPageType:
          'Travel Adventures - Dive Center/Resort Page - Courses Offered by the Operator',
        productList: 'Travel Adventures - Dive Courses Listing',
      }
    case 50:
      return {
        checkoutType: 'Adventures - Conservation',
        activityType: 'Conservation',
        type: 'Conservation Activity (Adventures)',
      }
    default:
      return {
        checkoutType: 'Adventures - Social',
        type: 'Social (Adventures)',
        activityType: 'Social',
        activityPageType:
          'Travel Adventures - Dive Center/Resort Page - Social Events Offered by the Operator',
        productList: 'Travel Adventures - Dive Trips Listing',
      }
  }
}

function removeQueryParams(url) {
  if(!url) {
    return '/'
  }
  return url.split('?')[0]
}
function linkTransform(link) {
  let url = '##'

  if(link) {
    if(link.indexOf('://') === -1 && link[0] === '/') {
      url = window.location.origin + link
    } else {
      url = link
    }

    url = url.replace('https://', '').replace('http://', '')
  }

  return removeQueryParams(url) // link should be without query parameters
}

export {
  openSalesForcePopup,
  navigationClicks,
  trackClick,
  getProductCategory,
}
