import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import PhoneInput from '../PhoneInput'

import './phone-input.scss'

const FinalPhoneInput = ({
  input,
  meta,
  hint,
  label,
  className,
  isRequired,
  getPhoneInstance,
  countryCode
}) => {
  const error = meta.touched && (meta.error || meta.submitError)
  return (
    <div className={`input-wrapper ${className} ${error ? 'error-field' : ''}`}>
      <label>
        {typeof label === 'object' && label.id ? (
          <FormattedMessage {...label} />
        ) : (
          label
        )}
        {isRequired ? <span className='asterisk'>*</span> : ''}
      </label>
      <div className='phone-input'>
        <PhoneInput {...input} getPhoneInstance={getPhoneInstance} countryCode={countryCode} />
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && <div className='error-text'>{error}</div>}
      </div>
    </div>
  )
}

export default function FormPhone(props) {
  const { name } = props
  return <Field name={name} component={FinalPhoneInput} {...props} />
}
