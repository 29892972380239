import InputNumber from '../InputNumber'

export default function FormInputNumber(props) {
  const { input, meta, onValueChange } = props
  const error = meta.touched && (meta.error || meta.submitError)
  return (
    <div>
      <InputNumber
        {...props}
        value={input.value}
        onChange={input.onChange}
        onValueChange={onValueChange}
      />
      {error && meta.touched && <span>{error}</span>}
    </div>
  )
}
