import styles from './StyledCheckbox.module.scss'
import cx from 'classnames'

export default function StyledCheckbox({
  checked,
  onChange,
  className,
  label,
}) {
  const handleChange = ({ target }) => {
    onChange(target.checked)
  }
  return (
    <label className={cx(styles.checkboxContainer, className)}>
      {label && <span className={styles.checkboxLabel}>{label}</span>}
      <input
        type='checkbox'
        className={styles.checkboxInput}
        onChange={handleChange}
        checked={checked}
      />
      <span className={styles.switch} />
    </label>
  )
}

export function StyledCheckboxFinalForm({
  value,
  input,
  className,
  label,
  labelEn,
  onChange,
}) {
  const handleChange = (e) => {
    input.onChange(e)
    if(onChange) {
      onChange(e.target.checked, labelEn)
    }
  }
  return (
    <label className={cx(styles.checkboxContainer, className)}>
      {label && <span className={styles.checkboxLabel}>{label}</span>}
      <input
        type='checkbox'
        className={styles.checkboxInput}
        value={value}
        {...input}
        onChange={handleChange}
      />
      <span className={styles.switch} />
    </label>
  )
}
