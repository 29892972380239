import { Fragment } from 'react'
import moment from 'moment'
import { APP_LANGUAGE } from '../../localized-urls'

const isUtc = (date) => {
  return date && typeof date === 'string' ? date.indexOf('T') !== -1 : true
}

export function formatDate(date, format = 'll', locale) {
  if(!date) {
    return null
  }
  const IS_UTC = isUtc(date)

  return IS_UTC
    ? moment(date)
      .locale(locale || APP_LANGUAGE)
      .utc()
      .format(format)
    : moment(date)
      .locale(locale || APP_LANGUAGE)
      .format(format)
}

export default function DateFormat({ date, format = 'll' }) {
  const IS_UTC = isUtc(date)
  return (
    <Fragment>
      {date
        ? IS_UTC
          ? moment(date).locale(APP_LANGUAGE).utc().format(format)
          : moment(date).locale(APP_LANGUAGE).format(format)
        : ''}
    </Fragment>
  )
}
