import { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'

export function convertCurrency(
  amount,
  currency = 'USD',
  to,
  selectedCurrency,
  conversionPercent
) {
  return window
    .fx(amount || 0)
    .from(currency)
    .to(
      to || selectedCurrency,
      window.fx.discount,
      conversionPercent ? window.fx.conversion_percent : 0
    )
}

function PriceConverter({
  amount,
  currency = 'USD',
  onlyNumber,
  to,
  decimals,
  noCurrency,
  selectedCurrency,
  conversionPercent,
}) {
  const convertedCurrency = useMemo(() => {
    const toCurrency = to || selectedCurrency
    if(typeof window.fx !== 'function') {
      return amount || 0
    }
    let value = window
      .fx(amount || 0)
      .from(currency)
      .to(
        to || selectedCurrency,
        window.fx.discount,
        conversionPercent ? window.fx.conversion_percent : 0
      )
    if(onlyNumber) {
      return value
    }

    if(value >= 100000 && value < 1000000) {
      return (
        <NumberFormat
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='K'
          decimalScale={1}
          value={value / 1000}
        />
      )
    } else if(value >= 1000000 && value < 1000000000) {
      return (
        <NumberFormat
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='M'
          decimalScale={2}
          value={value / 1000000}
        />
      )
    } else if(value >= 1000000000) {
      return (
        <NumberFormat
          thousandSeparator
          displayType='text'
          prefix={noCurrency ? '' : toCurrency + ' '}
          suffix='Bn'
          decimalScale={2}
          value={value / 1000000000}
        />
      )
    }
    return (
      <NumberFormat
        thousandSeparator
        displayType='text'
        prefix={noCurrency ? '' : toCurrency + ' '}
        decimalScale={decimals ? 2 : 0}
        fixedDecimalScale={decimals}
        value={value}
      />
    )
  }, [amount, to, currency, selectedCurrency])
  return (
    <Fragment>
      {amount && window.fx && window.fx.rates
        ? convertedCurrency
        : `${selectedCurrency} 0`}
    </Fragment>
  )
}

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(PriceConverter)
