import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getTextFromInfo } from '../../../../utils'
import messages from '../../../../messages'
import PriceConverter from '../../../helpers/PriceConverter'
import MealPlanIcon from './MealPlanIcon'
import { trackGA4Ecommerce } from '../../../../gtm'
import PropTypes from 'prop-types'

export default function RoomMealPlan({
  room,
  mealPlans,
  setPopupData,
  setMealPlan,
  totalGuests,
  pricingObject,
  selectedMealPlan,
}) {
  const intl = useIntl()
  const { eCommerceProducts } = room
  const handleMealPlanClick = ({ target }, rate) => {
    setMealPlan(+target.value)
    const item = eCommerceProducts.find((item) => item.item_id === rate.id.toString())
    trackGA4Ecommerce(
      'view_item',
      {
        currency: 'USD',
        value: item.price,
        items: [item],
      },
      'PADI Travel - Dive Resorts',
    )
  }

  return (
    <div className='resort-pricing__meal-plan'>
      <div className='meal-plan-selector'>
        {room.rates.map((rate) => {
          const mealPlanDescription = useMemo(
            () => mealPlans && mealPlans[rate.mealPlan],
            [mealPlans, rate.mealPlan],
          )
          const totalAmount = useMemo(
            () => (rate.ratePrice - pricingObject.ratePrice) / totalGuests,
            [totalGuests, pricingObject.ratePrice, rate.ratePrice],
          )

          const handleInfoIconClick = (e) => {
            e.preventDefault()
            setPopupData('isOpenMealPlanPopup', {
              mealPlan: rate.mealPlan,
              mealPlanDescription,
            })
            return false
          }
          return (
            <div className='radio-wrap' key={rate.id}>
              <label>
                <input
                  type='radio'
                  name={`mealplan${room.id}`}
                  value={rate.mealPlan}
                  checked={selectedMealPlan === rate.mealPlan}
                  onChange={(e) => handleMealPlanClick(e, rate)}
                />
                <span>
                  {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                  <MealPlanIcon mealPlan={rate.mealPlan} />{' '}
                  {rate.mealPlan ? (
                    getTextFromInfo(rate.mealPlan, 'shop', 'RESORT_MEAL_PLANS')
                  ) : (
                    <FormattedMessage
                      id='no_meals_included'
                      defaultMessage='No meals included'
                    />
                  )}
                  {!!mealPlanDescription && (
                    <i
                      className='font-icons info-icon'
                      onClick={handleInfoIconClick}
                      data-tip={intl.formatMessage(
                        messages.tooltipMealplanInfo,
                      )}
                    />
                  )}
                  <i className='hint'>
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {rate.ratePrice >= pricingObject.ratePrice && '+'}
                    <PriceConverter
                      amount={totalAmount}
                      currency={room.currency}
                    />
                    &nbsp;
                    <FormattedMessage
                      id='per_person'
                      defaultMessage='per person'
                    />
                  </i>
                </span>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

RoomMealPlan.propTypes = {
  room: PropTypes.object,
  mealPlans: PropTypes.object,
  setPopupData: PropTypes.func,
  setMealPlan: PropTypes.func,
  totalGuests: PropTypes.number,
  pricingObject: PropTypes.object,
  selectedMealPlan: PropTypes.number,
}
