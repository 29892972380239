import DatePicker, { registerLocale } from 'react-datepicker'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-final-form'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker.scss'

import Proptypes from 'prop-types'
import { APP_LANGUAGE } from '../../../localized-urls'

const lang = APP_LANGUAGE === 'ach' ? 'en' : APP_LANGUAGE
registerLocale(lang)

const Calendar = function({
  input,
  meta,
  isRequired,
  label,
  minDate,
  maxDate,
  placeholder,
  hint,
}) {
  const handleChange = (value) => {
    input.onChange(value ? moment(value).format('YYYY-MM-DD') : value)
  }
  const error = meta.touched && (meta.error || meta.submitError)

  return (
    <div
      className={`form-item-row datepicker-wrapper ${
        error ? 'error-field' : ''
      }`}
    >
      <label className='mbn'>
        <strong>
          {typeof label === 'object' && label.id ? (
            <FormattedMessage {...label} />
          ) : (
            label
          )}
          {isRequired ? <span className='asterisk'>*</span> : ''}
        </strong>
      </label>
      <div className='form-item-row__container'>
        <i className='font-icons calendar-icon' />
        <DatePicker
          selected={input.value ? moment(input.value).toDate() : undefined}
          onChange={handleChange}
          peekNextMonth
          showMonthDropdown
          placeholderText={placeholder}
          showYearDropdown
          maxDate={maxDate}
          minDate={minDate}
          dropdownMode='select'
        />
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && meta.touched && <div className='error-text'>{error}</div>}
      </div>
    </div>
  )
}
export default function FormDatepicker(props) {
  const { name } = props
  return <Field name={name} component={Calendar} {...props} />
}

FormDatepicker.propTypes = {
  name: Proptypes.string,
}

Calendar.propTypes = {
  name: Proptypes.string,
  label: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  isRequired: Proptypes.bool,
  placeholder: Proptypes.string,
  hint: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  minDate: Proptypes.instanceOf(Date),
  maxDate: Proptypes.instanceOf(Date),
  input: Proptypes.object,
  meta: Proptypes.object,
}
