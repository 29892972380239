import { defineMessages } from 'react-intl'

export default defineMessages({
  addToWishlist: {
    id: 'addToWishlist',
    defaultMessage: 'ADD TO WISHLIST',
  },
  removeFromWishlist: {
    id: 'removeFromWishlist',
    defaultMessage: 'REMOVE FROM WISHLIST',
  },
  diveInsuranceDescription: {
    id: 'diveInsuranceDescription',
    defaultMessage:
      'We have partnered with DAN - Divers Alert Network - to offer you free dive accident insurance for the duration of your trip (valid for bookings with a value of > USD {price} per person)',
  },
  fullyFlexibleDescription: {
    id: 'fullyFlexibleDescription',
    defaultMessage:
      'You can customize the number of dives for each diver during the checkout process. The package price will be adjusted dynamically',
  },
  notFlexibleDives: {
    id: 'notFlexibleDives',
    defaultMessage:
      'This is the total number of dives you get for the price you pay and includes, if offered as part of your package, non-standard dives such as night dives and special trips.',
  },
  tooltipMealplanInfo: {
    id: 'tooltipMealplanInfo',
    defaultMessage: 'Click here to find out what`s included in this meal plan.',
  },
  courseTooltip: {
    id: 'courseTooltip',
    defaultMessage: 'Click here to find out what`s included.',
  },
  whatIsMissing: {
    id: 'whatIsMissing',
    defaultMessage: 'What information is missing?',
  },
  whatShouldWeAdd: {
    id: 'whatShouldWeAdd',
    defaultMessage: 'What other info would be helpful?',
  },
  showAvailable: {
    id: 'showAvailable',
    defaultMessage: 'Show available',
  },
  showAll: {
    id: 'showAll',
    defaultMessage: 'Show all',
  },
  next30: {
    id: 'next30',
    defaultMessage: 'Next 30 days',
  },
  topicToSuggest: {
    id: 'topicToSuggest',
    defaultMessage: 'What topic do you want to suggest?',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  homepageSearchPlaceholder: {
    id: 'homepage.search.placeholder',
    defaultMessage: 'Where do you want to go?',
  },
  homepageSearchPlaceholderConservation: {
    id: 'homepage.search.placeholder.conservation',
    defaultMessage: 'Where do you want to protect?',
  },
  divingInPlaceholder: {
    id: 'divingIn.search.placeholder',
    defaultMessage: 'Looking for another destination?',
  },
  selectDate: {
    id: 'selectDate',
    defaultMessage: 'Select a date',
  },
  pleaseSelect: {
    id: 'pleaseSelect',
    defaultMessage: 'Please select',
  },
  unableOpenPopup: {
    id: 'unableOpenPopup',
    defaultMessage:
      'We were unable to open the contact form. Please disable your ad blocker and try again',
  },
  near_me: {
    id: 'near_current_location',
    defaultMessage: 'Near current location',
  },
  use_map_tip: {
    id: 'use_map_tip',
    defaultMessage:
      '<strong>Tip:</strong> use map view to refine or expand the search radius',
  },
  removeFromWishList: {
    id: 'tooltip.removeFromWishlist',
    defaultMessage: 'Click to remove from your wishlist',
  },
  bookWithConfidence: {
    id: 'bookWithConfidenceDescription',
    defaultMessage: 'Free cancellation up to 8 days before trip start date',
  },
})
