import get from 'lodash/get'
import { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  setResortInclusionsData,
  showResortInclusions,
} from '../../../../actions/main-actions'
import messages from '../../../../messages'

const inclusionMessages = defineMessages({
  diverPackage: {
    id: 'diver_package',
    defaultMessage: 'Diver package',
  },
  studentPackage: {
    id: 'student_package',
    defaultMessage: 'Student package',
  },
  nonDiverPackage: {
    id: 'non-diver_package',
    defaultMessage: 'Non-diver package',
  },
  divesPerDiver: {
    id: 'pricing.dives_per_diver',
    defaultMessage: '{ divesAmount } dives per diver',
  },
  divesPerDiverType: {
    id: 'pricing.dives_per_diver_with_kind',
    defaultMessage: '{ divesAmount } { diveType } dives per diver',
  },
  unlimitedShore: {
    id: 'unlimited_shore_dives',
    defaultMessage: 'Unlimited shore dives',
  },
  mealsAsChoosen: {
    id: 'meals_as_chosen',
    defaultMessage: 'Meals as per chosen meal plan',
  },
})

const SubSectionTitle = ({ onClick, quantity, title }) => (
  <strong onClick={onClick}>
    {title}&nbsp;x&nbsp;{quantity}
    <i className='bottom-arrow-icon font-icons' />
  </strong>
)

const Inclusion = ({
  inclusion,
  showDescription,
  mainTooltip,
  insuranceTooltip,
  isFlexibale,
}) => {
  const intl = useIntl()
  return (
    <li className='insurance' data-tip={mainTooltip}>
      <i className='font-icons icon-tick' />
      &nbsp;{inclusion.title}
      {inclusion.isPtExclusive && (
        <div className='padi-label' data-tip={insuranceTooltip}>
          <FormattedMessage
            id='padi_exclusive'
            defaultMessage='PADI exclusive'
          />
          <i className='font-icons info-icon' />
        </div>
      )}
      {!!inclusion.activity && showDescription && (
        <i
          className='font-icons info-icon'
          onClick={showDescription}
          data-tip={intl.formatMessage(messages.courseTooltip)}
        />
      )}
      {isFlexibale && (
        <div
          className='padi-label'
          data-tip={intl.formatMessage(messages.fullyFlexibleDescription)}
        >
          <FormattedMessage
            id='fully_flexible'
            defaultMessage='Fully flexible'
          />
          <i className='font-icons info-icon' />
        </div>
      )}
    </li>
  )
}

function RoomInclusions({
  slug,
  roomParams,
  mealPlanDescription,
  filters,
  isFixedPackage,
  room,
  diveType,
  duration,
  insurance,
  showResortInclusions,
  setResortInclusionsData,
  setPopupData,
}) {
  const intl = useIntl()
  const [collapsedFields, setCollapsed] = useState({})

  const insuranceTooltip = intl.formatMessage(
    messages.diveInsuranceDescription,
    {
      price: get(insurance, 'limitPrice', 'n/a'),
    }
  )

  const insuranceIclusion = {
    title: (
      <FormattedMessage
        id='free_dan_insurance'
        defaultMessage='Free DAN dive accident insurance'
      />
    ),
    isPtExclusive: true,
  }

  const mobileCollapseHandler = (type) => () => {
    setCollapsed({ ...collapsedFields, [type]: !collapsedFields[type] })
  }

  const showWhatsIncluded = () => {
    setResortInclusionsData({
      slug,
      currency: room.currency,
      queryParams: {
        ...filters,
        rate: room.rates && room.rates[0].id,
        packages: room.rates && room.rates[0].packages,
      },
      room,
      diveType,
      insurance,
      duration,
      isPackage: isFixedPackage,
      mealPlanDescription,
    })
    showResortInclusions(true)
  }

  return (
    <div className='resort-pricing__whats-included'>
      <div className='included'>
        {!!roomParams.divers && (
          <div
            className={`features mobile-collapse ${
              collapsedFields.diversCollapse ? 'active' : ''
            }`}
          >
            <SubSectionTitle
              onClick={mobileCollapseHandler('diversCollapse')}
              title={intl.formatMessage(inclusionMessages.diverPackage)}
              quantity={roomParams.divers}
            />
            <ul>
              {!isFixedPackage ? (
                <Inclusion
                  inclusion={{
                    title: intl.formatMessage(
                      inclusionMessages.divesPerDiverType,
                      { divesAmount: room.divesAmount, diveType }
                    ),
                  }}
                />
              ) : (
                <Fragment>
                  {room.packageDiveKind !== 30 && (
                    <Inclusion
                      mainTooltip={intl.formatMessage(
                        messages.notFlexibleDives
                      )}
                      inclusion={{
                        title: intl.formatMessage(
                          inclusionMessages.divesPerDiver,
                          { divesAmount: room.divesAmount }
                        ),
                      }}
                    />
                  )}
                  {[20, 30].includes(room.packageDiveKind) && (
                    <Inclusion
                      inclusion={{
                        title: intl.formatMessage(
                          inclusionMessages.unlimitedShore
                        ),
                      }}
                    />
                  )}
                </Fragment>
              )}
              <Inclusion
                inclusion={{
                  title: intl.formatMessage(inclusionMessages.mealsAsChoosen),
                }}
              />
              {insurance && (
                <Inclusion
                  insuranceTooltip={insuranceTooltip}
                  inclusion={insuranceIclusion}
                />
              )}
              {room.diveCenterInclusions.map((inclusion) => (
                <Inclusion key={inclusion.title} inclusion={inclusion} />
              ))}
              {!isFixedPackage &&
                room.resortRoomInclusions.map((inclusion) => (
                  <Inclusion key={inclusion.title} inclusion={inclusion} />
                ))}
            </ul>
          </div>
        )}
        {!!roomParams.students && (
          <div
            className={`features mobile-collapse ${
              collapsedFields.studentsCollapse ? 'active' : ''
            }`}
          >
            <SubSectionTitle
              onClick={mobileCollapseHandler('studentsCollapse')}
              title={intl.formatMessage(inclusionMessages.studentPackage)}
              quantity={roomParams.students}
            />
            <ul>
              {room.studentInclusions.map((inclusion) => {
                const showDescription = () => {
                  setPopupData('isOpenInclusionDescriptionPopup', {
                    activity: inclusion.activity,
                    thumbnails: inclusion.thumbnails,
                    activityTitle: inclusion.title,
                  })
                }
                return (
                  <Inclusion
                    key={inclusion.title}
                    inclusion={inclusion}
                    showDescription={showDescription}
                  />
                )
              })}
              {insurance && (
                <Inclusion
                  insuranceTooltip={insuranceTooltip}
                  inclusion={insuranceIclusion}
                />
              )}
              {!isFixedPackage &&
                room.resortRoomInclusions.map((inclusion) => (
                  <Inclusion key={inclusion.title} inclusion={inclusion} />
                ))}
            </ul>
          </div>
        )}
        {!!roomParams.nonDivers && (
          <div
            className={`features mobile-collapse ${
              collapsedFields.nonDiversCollapse ? 'active' : ''
            }`}
          >
            <SubSectionTitle
              onClick={mobileCollapseHandler('nonDiversCollapse')}
              title={intl.formatMessage(inclusionMessages.nonDiverPackage)}
              quantity={roomParams.nonDivers}
            />
            <ul>
              <Inclusion
                inclusion={{
                  title: intl.formatMessage(inclusionMessages.mealsAsChoosen),
                }}
              />
              {room.resortRoomInclusions.map((inclusion) => (
                <Inclusion key={inclusion.title} inclusion={inclusion} />
              ))}
            </ul>
          </div>
        )}
        <p className='link-color' onClick={showWhatsIncluded}>
          <FormattedMessage
            id='full_details_whats_included'
            defaultMessage='Full details of what’s included and not'
          />
        </p>
      </div>
    </div>
  )
}

export default connect(null, { setResortInclusionsData, showResortInclusions })(
  RoomInclusions
)
