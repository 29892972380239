/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-indent-props, react/jsx-indent */
import { Component, Fragment, createRef } from 'react'
import Autosuggest from 'react-autosuggest'
import { injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import { getOffset } from '../../utils'
import API from '../../api'
import messages from '../../messages'
import { trackClick } from '../dive-guides/gtm-events'

import './autosuggest.sass'
const msg = defineMessages({
  tripsIn: {
    id: 'trips_in',
    defaultMessage: 'Group trips {location}',
  },
  operatorsIn: {
    id: 'operators_in',
    defaultMessage: 'Dive operators {location}',
  },
  liveaboardSearchUrl: {
    id: 'liveaboards_in',
    defaultMessage: 'Liveaboards {location}',
  },
  diveResortSearchUrl: {
    id: 'dr_in',
    defaultMessage: 'Dive resorts {location}',
  },
  diveCenterSearchUrl: {
    id: 'dc_in',
    defaultMessage: 'Dive centers {location}',
  },
  diveSitesIn: {
    id: 'ds_in',
    defaultMessage: 'Dive sites {location}',
  },
  groupTripsSearchUrl: {
    id: 'groups_in',
    defaultMessage: 'Group trips {location}',
  },
  scubaGuidesIn: {
    id: 'scuba_guides_in',
    defaultMessage: '{ location } scuba guide',
  },
  fleet: {
    id: 'fleet',
    defaultMessage: 'Fleet',
  },
})

const setElementsPosition = (data) => {
  if(data && data.length) {
    data[0].firstRow = true
    data[data.length - 1].last = true
  }
}

const renderIcon = (suggestion) => {
  let template
  if(suggestion.modelType === 'Shop' && suggestion.firstRow) {
    template = (
      <i
        className={`font-icons 
            ${suggestion.kind === 10 ? 'liveaboard-icon' : ''} 
            ${suggestion.kind === 20 ? 'dive-resort-icon' : ''} 
            ${suggestion.kind === 0 ? 'dive-center-icon' : ''}`}
      />
    )
  } else if(suggestion.modelType === 'Fleet') {
    template = <i className='font-icons liveaboard-icon' />
  } else if(suggestion.modelType === 'Travel guides' && suggestion.firstRow) {
    template = <i className='font-icons icon-information' />
  } else if(
    (suggestion.modelType === 'Location' ||
      suggestion.modelType === 'Geo-Location') &&
    suggestion.firstRow
  ) {
    template = <i className='font-icons find-icon' />
  }
  return template
}

export const getHighlightedText = function(text, higlight) {
  if(!text) {
    return ''
  }
  // Split on higlight term and include term into parts, ignore case

  let parts = text.split(
    new RegExp(
      `(${higlight
        .replace(/\\/g, '')
        .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})`,
      'gi',
    ),
  )
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === higlight.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  )
}

const renderSuggestion = (suggestion, { query }) => (
  <div
    className={`typeahead-item ${suggestion.last ? 'last' : ''} ${
      suggestion.modelType === 'Travel guides' ? 'guide' : ''
    }`}
  >
    <div className='img-wrap'>{renderIcon(suggestion)}</div>
    <div className='typeahead-title'>
      <span className='location-title'>
        {getHighlightedText(suggestion.title, query)}
      </span>
      <span className='location-text'>
        {getHighlightedText(suggestion.name, query) || ' '}
      </span>
    </div>
  </div>
)

class Typeahead extends Component {
  constructor(props) {
    super(props)

    if(props.popupId) {
      this.popup = document.getElementById(props.popupId)
    }

    this.inputRef = createRef()
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: props.inputValue || '',
      suggestions: [],
      popupVisible: false,
    }
  }

  componentDidMount() {
    const ref = this.inputRef.current.input
    this.inputOffset = getOffset(ref)
    if(this.popup && this.props.handlePopupItems) {
      const urls = this.popup.querySelectorAll('a')
      const toggleButtons = this.popup.querySelectorAll('.option')
      const countriesList = this.popup.querySelectorAll(
        '.country-list-wrapper',
      )
      if(urls) {
        urls.forEach((item) => {
          item.addEventListener('click', (e) => {
            e.preventDefault()
            const itemKind = +item.getAttribute('kind')
            this.onSuggestionSelected(e, {
              suggestion: {
                modelType: 'PopupLink',
                url: item.getAttribute('href'),
                kind: itemKind,
                title: item.text,
                itemTitle: item.text,
                searchKind: itemKind === 10 ? 'LA' : 'DR',
              },
            })
            if(typeof this.props.mobileDestination === 'function') {
              this.props.mobileDestination(false)
            }
            this.hidePopup()
            this.setState({
              value: item.text,
            })
          })
        })
      }

      if(toggleButtons) {
        toggleButtons.forEach((button) => {
          button.addEventListener('click', () => {
            if(!button.classList.contains('active')) {
              toggleButtons.forEach((button) => {
                button.classList.remove('active')
              })
              button.classList.add('active')
              countriesList.forEach((item) => {
                item.classList.toggle('active')
              })
              if(this.props.onToggleButtonClick) {
                this.props.onToggleButtonClick(button.getAttribute('kind'))
              }
            }
          })
        })
      }

      document.addEventListener('click', (e) => {
        if(this.state.popupVisible) {
          let element = e.target
          let parents = []
          let clickedOutside = true
          while(element) {
            parents.unshift(element)
            element = element.parentNode
          }
          parents.forEach((elem) => {
            if(elem.id === this.props.popupId || elem === ref) {
              clickedOutside = false
            }
          })
          if(clickedOutside && !this.props.isActiveMobile) {
            this.hidePopup()
          }
        }
      })
    }
  }

  showPopup() {
    if(this.popup) {
      this.inputOffset = getOffset(this.inputRef.current.input)
      this.popup.style.opacity = 1

      if(
        this.inputOffset.left + this.popup.offsetWidth >
        window.innerWidth - 20
      ) {
        this.popup.style.left = `${
          (window.innerWidth - this.popup.offsetWidth) / 2
        }px`
      } else {
        this.popup.style.left = `${this.inputOffset.left - 10}px`
      }
      this.popup.style.top = `${this.inputOffset.top + 50}px`
      this.setState({
        popupVisible: true,
      })

      if(window.innerWidth < 767) {
        window.scrollTo(0, 0)
        if(typeof this.props.mobileDestination === 'function') {
          this.props.mobileDestination(true)
        }
        this.popup.style.top = '70px'
      }
    }
  }

  hidePopup() {
    if(this.popup) {
      this.popup.style.opacity = 0
      this.setState({
        popupVisible: false,
      })
      setTimeout(() => {
        this.popup.style.left = '-9999px'
      }, 300)
    }
  }

  onSuggestionSelected = (_, { suggestion }) => {
    const url = suggestion.url || suggestion.travelUrl
    trackClick({ regionIdentifier: 'menu-travelmain-search', link: url })
    if(this.props.selectCallback) {
      this.props.selectCallback({
        url,
        item: suggestion,
      })
    } else {
      window.location.href = url
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.title
  }

  onChange = (_, { newValue }) => {
    if(this.popup) {
      if(newValue.length < 1) {
        this.showPopup()
      } else {
        this.hidePopup()
      }
    }

    if(this.props.activateHeaderSearch && window.innerWidth < 767) {
      this.props.activateHeaderSearch(true)
    }

    this.setState({
      value: newValue,
    })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    const { intl } = this.props

    if(value.length < 3) {
      this.setState({
        suggestions: [],
      })

      return false
    }

    let params = { q: value }

    if(this.props.type === 'group') {
      /// Ха-ха, ебучий костыль
      params.kind = 999
    }

    // if(this.props.kind) {
    //   params.kind = this.props.kind
    // }

    const placesFields = [
      'liveaboardSearchUrl',
      'diveResortSearchUrl',
      'diveCenterSearchUrl',
      'groupTripsSearchUrl',
      'liveaboardFleetSearchUrl',
    ]

    this.props.setLoading(true)
    API('search/autosuggest/')
      .get(params)
      .then((data) => {
        let resorts = []
        let centers = []
        let liveaboards = []
        let locations = []
        let travelGuides = []
        let fleets = []
        let locationsCount = filter(data.places, function(item) {
          return (
            item.modelType === 'Geo-Location' || item.modelType === 'Location'
          )
        })
        data.shops.forEach((item) => {
          item.modelType = 'Shop'
          if(item.kind === 10) {
            liveaboards.push(item)
          } else if(item.kind === 20) {
            if(item.diveCenterUrl) {
              centers.push({
                ...item,
                kind: 0,
                travelUrl: item.diveCenterUrl,
                title: item.diveCenterTitle,
              })
            }
            if(item.travelUrl) {
              resorts.push(item)
            }
          } else if(item.kind === 0) {
            centers.push(item)
          }
        })
        data.fleets.forEach((fleet) => {
          fleet.name = intl.formatMessage(msg.fleet)
          fleet.modelType = 'Fleet'
          fleet.url = fleet['travelAutosuggestFleetsUrl']
          fleets.push(fleet)
        })
        data.places.forEach((item) => {
          if(locationsCount.length > 1) {
            let url
            placesFields.some((field) => {
              if(item[field]) {
                url = item[field]
                return true
              }
            })
            if(url) {
              (item.title = intl.formatMessage(msg.operatorsIn, {
                location: item.inTitle,
              })),
              (item.url = url)
              locations.push(item)
            }
          } else {
            placesFields.forEach((field) => {
              if(item[field]) {
                const newItem = {
                  ...item,
                  url: item[field],
                  title: msg[field]
                    ? intl.formatMessage(msg[field], {
                      location: item.inTitle,
                    })
                    : item.title,
                }

                locations.push(newItem)
              }
            })
          }
        })

        const autosuggestElements = [
          resorts,
          centers,
          liveaboards,
          locations,
          fleets,
        ]
        autosuggestElements.forEach(setElementsPosition)

        if(value.kind === 'scubaguide') {
          return this.setState({
            suggestions: travelGuides,
          })
        }
        if(this.props.kind) {
          this.setState({
            suggestions: locations
              .concat(fleets)
              .concat(liveaboards)
              .concat(resorts)
              .concat(centers),
          })
        } else {
          this.setState({
            suggestions: locations
              .concat(travelGuides)
              .concat(fleets)
              .concat(liveaboards)
              .concat(resorts)
              .concat(centers),
          })
        }
      }).finally(() => {
        this.props.setLoading(false)
      })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    const { value, suggestions } = this.state
    const { placeholder, intl } = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder || intl.formatMessage(messages.search),
      value,
      autoComplete: 'off',
      onChange: this.onChange,
      onBlur: () => {
        if(!this.props.handlePopupItems) {
          this.hidePopup()
        }
      },
      onFocus: (e) => {
        try {
          e.currentTarget.setSelectionRange(0, value.length)
        } catch (err) {
          // Non IOs option if not supported, e.g. Chrome
          e.currentTarget.select()
        }
        if(this.props.activateHeaderSearch && window.innerWidth < 767) {
          this.props.activateHeaderSearch(true)
        }

        if(this.popup && !this.props.onFocusClear && !this.props.isActivity) {
          if(value.length < 3) {
            this.showPopup()
          }
        }

        if(this.props.onFocusClear) {
          this.setState(
            {
              value: '',
            },
            this.showPopup,
          )
        }
      },
    }

    // Finally, render it!
    return (
      <Fragment>
        {this.props.withIcon ? (
          <i className='font-icons field-icon location-icon' />
        ) : (
          ''
        )}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          ref={this.inputRef}
        />
        {this.props.isActiveMobile &&
        typeof this.props.mobileDestination === 'function' ? (
            <span
              onClick={() => this.props.mobileDestination(false)}
              className='close-destination-popup close-icon font-icons'
            />
          ) : (
            ''
          )}
      </Fragment>
    )
  }
}

Typeahead.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Typeahead)
