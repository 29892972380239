import { Component, Fragment, createRef } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import API from '../../api'
import { showResortInclusions } from '../../actions/main-actions'
import Loader from '../helpers/Loader'
import { FormattedMessage } from 'react-intl'
import Inclusions from '../helpers/Inclusions'
import PaymentAndCancellation from '../helpers/PaymentsAndCancellation'

const defaultState = {
  inclusions: {},
  whatsIncludedExpanded: true,
  mandatorySurchargesExpanded: true,
  optionalExtrasExpanded: true,
  goodToKnowExpanded: true,
  paymentExpanded: true,
  loading: true,
  info: {},
}

class ResortInclusions extends Component {
  constructor(props) {
    super(props)

    this.state = defaultState

    this.slickRef = createRef()
  }

  closeModal = () => {
    this.props.dispatch(showResortInclusions(false))
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable  react/no-did-update-set-state */
    const {
      slug,
      insurance,
      room,
      duration,
      mealsDescription,
      isPackage,
      queryParams,
    } = this.props.resortInclusionPopupData

    if(JSON.stringify(prevProps) === JSON.stringify(this.props)) {
      return false
    }

    this.setState(defaultState)

    API(`booking/resort/inclusions/${slug}/`)
      .get(queryParams)
      .then((inclusions) => {
        this.setState({
          inclusions: {
            ...inclusions,
            insurance,
            room,
            duration,
            mealsDescription,
            isPackage,
          },
          loading: false,
        })
        ReactTooltip.rebuild()
      })
  }

  toggleSection = (type) => {
    this.setState({
      [type]: !this.state[type],
    })
  }

  render() {
    const { inclusions } = this.state
    const {
      room,
      queryParams,
      insurance,
      currency,
      isPackage,
      diveType,
    } = this.props.resortInclusionPopupData
    return (
      <Modal
        isOpen={this.props.resortInclusionsOpen}
        onRequestClose={this.closeModal}
        ariaHideApp={false}
        className='Modal react-itinerary-modal'
        overlayClassName='Overlay'
        contentLabel='inclusions popup'
      >
        <div
          className={`itinerary-page-new ${
            this.state.loading ? 'loading' : ''
          }`}
        >
          <div className='itinerary-page__modal-header'>
            <span className='btn-close' onClick={this.closeModal}>
              &times;
            </span>
          </div>
          <Loader />
          <div className='itinerary-popup-content'>
            <div className='main-title'>
              <h4 className='title'>
                <FormattedMessage
                  id='package_details'
                  defaultMessage='Package details'
                />
              </h4>
            </div>
            {!this.state.loading ? (
              <Fragment>
                <div className='itinerary-section collapsed-section'>
                  <h4
                    onClick={() => this.toggleSection('whatsIncludedExpanded')}
                    className={this.state.whatsIncludedExpanded ? 'active' : ''}
                  >
                    <FormattedMessage
                      id='whats_included'
                      defaultMessage='What`s included'
                    />
                  </h4>
                  {this.state.whatsIncludedExpanded ? (
                    <div className='itinerary-section-details'>
                      <Inclusions
                        divesAmount={room.divesAmount}
                        fixedPackage={isPackage}
                        diveType={diveType}
                        insurance={insurance}
                        room={room}
                        duration={queryParams.nights}
                        included
                        items={[
                          ...inclusions.includedDiver,
                          ...inclusions.includedNondiver,
                        ]}
                      />
                      <i className='note-text'>
                        <FormattedMessage
                          id='note.price'
                          defaultMessage='Note: all listed prices are per person'
                        />
                      </i>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='itinerary-section collapsed-section'>
                  <h4
                    onClick={() =>
                      this.toggleSection('mandatorySurchargesExpanded')
                    }
                    className={
                      this.state.mandatorySurchargesExpanded ? 'active' : ''
                    }
                  >
                    <FormattedMessage
                      id='whats_not_included'
                      defaultMessage='What`s not included'
                    />
                  </h4>
                  {this.state.mandatorySurchargesExpanded ? (
                    <div className='itinerary-section-details'>
                      <Inclusions
                        shopType='resort'
                        showPrices
                        type='mandatoryInAdvance'
                        currency={currency}
                        payedInAdvance
                        items={inclusions.mandatoryInAdvance}
                      />
                      <Inclusions
                        shopType='resort'
                        showPrices
                        type='mandatoryOnSite'
                        currency={currency}
                        items={inclusions.mandatoryOnSite}
                      />
                      <i className='note-text'>
                        <FormattedMessage
                          id='note.price'
                          defaultMessage='Note: all listed prices are per person'
                        />
                      </i>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='itinerary-section collapsed-section'>
                  <h4
                    onClick={() => this.toggleSection('optionalExtrasExpanded')}
                    className={
                      this.state.optionalExtrasExpanded ? 'active' : ''
                    }
                  >
                    <FormattedMessage
                      id='optional_extras'
                      defaultMessage='Optional extras'
                    />
                  </h4>
                  {this.state.optionalExtrasExpanded ? (
                    <div className='itinerary-section-details'>
                      <Inclusions
                        shopType='resort'
                        showPrices
                        payedInAdvance
                        type='optionalInAdvance'
                        currency={currency}
                        items={inclusions.bookableAndPayableInAdvance}
                      />
                      <Inclusions
                        shopType='resort'
                        showPrices
                        type='optionalBookableAdvancePaidOnSite'
                        currency={currency}
                        items={inclusions.bookableInAdvancePayableOnSite}
                      />
                      <Inclusions
                        shopType='resort'
                        showPrices
                        type='bookableAndPayableOnSite'
                        currency={currency}
                        items={inclusions.bookableAndPayableOnSite}
                      />
                      <i className='note-text'>
                        <FormattedMessage
                          id='note.price'
                          defaultMessage='Note: all listed prices are per person'
                        />
                      </i>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {inclusions.goodToKnow ? (
                  <div className='itinerary-section itinerary-logistics collapsed-section'>
                    <h4
                      onClick={() => this.toggleSection('goodToKnowExpanded')}
                      className={this.state.goodToKnowExpanded ? 'active' : ''}
                    >
                      <FormattedMessage
                        id='good_to_know'
                        defaultMessage='Good to know'
                      />
                    </h4>
                    {this.state.goodToKnowExpanded ? (
                      <div
                        className='description wysiwyg'
                        dangerouslySetInnerHTML={{
                          __html: inclusions.goodToKnow,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {inclusions.paymentInformation ? (
                  <div className='itinerary-section itinerary-logistics collapsed-section'>
                    <h4
                      onClick={() => this.toggleSection('paymentExpanded')}
                      className={this.state.paymentExpanded ? 'active' : ''}
                    >
                      <FormattedMessage
                        id='payment_and_cancellation'
                        defaultMessage='Payment & Cancellation terms'
                      />
                    </h4>
                    {this.state.paymentExpanded ? (
                      <div className='itinerary-section-details'>
                        <PaymentAndCancellation
                          currency={currency}
                          paymentInformation={inclusions.paymentInformation}
                          milestones={inclusions.paymentMilestones}
                          isCustomCancellation={inclusions.isCustomCancellation}
                          cancellationMilestones={
                            inclusions.cancellationMilestones
                          }
                          customCancellationText={inclusions.customCancellation}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect((state) => ({
  resortInclusionsOpen: state.resortInclusionsOpen,
  resortInclusionPopupData: state.resortInclusionPopupData,
}))(ResortInclusions)
