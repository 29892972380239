import { useMemo } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

const RadioButton = ({
  input,
  meta,
  label,
  className = '',
  isNumericValue,
  onChange,
}) => {
  const handleOnChange = (e) => {
    let value = e.target.value
    if(isNumericValue) {
      value = Number(value)
    }

    if(onChange && typeof onChange === 'function') {
      onChange(value)
    }

    input.onChange(value)
  }

  return (
    <div
      className={`radio-btn-wrapper ${className} ${
        (meta.error || meta.submitError) && meta.touched ? ' error-field' : ''
      }`}
    >
      <label>
        <input {...input} onChange={handleOnChange} />
        <span>
          {typeof label === 'object' && label.id ? (
            <FormattedMessage {...label} />
          ) : (
            label
          )}
        </span>
      </label>
    </div>
  )
}

export default function FormRadioGroup(props) {
  const { name, options, isNumericValue } = props

  const radioOptions = useMemo(() => {
    return props.isArrayCollection
      ? options.map((option) => ({ id: option[0], title: option[1] }))
      : options
  }, [options])

  const radioKeys = Object.keys(radioOptions)
  return (
    <div className='radio-group'>
      {radioKeys.map((item) => {
        const value = get(radioOptions[item], 'id', radioOptions[item].value)

        return (
          <Field
            name={name}
            type='radio'
            component={RadioButton}
            key={`${name}.${value}`}
            value={value}
            label={radioOptions[item].title}
            isNumericValue={isNumericValue}
            onChange={props.onChange}
          />
        )
      })}
    </div>
  )
}

export function FormRadioButton(props) {
  return (
    <div className='radio-wrap'>
      <Field type='radio' component={RadioButton} {...props} />
    </div>
  )
}
