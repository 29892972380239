import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { RoomInfo } from './partials/'

export default function ResortPricingRowSoldOut({
  triggerAvailabilityCalendar,
  availabilityCalendar,
  room,
  isFixedPackage,
  openRoomPopup,
  showCalendar,
}) {
  const handleClick = () => {
    triggerAvailabilityCalendar(true)
  }

  useEffect(() => {
    if(availabilityCalendar && !showCalendar) {
      triggerAvailabilityCalendar(true)
    }
  }, [])

  return (
    <div className='resort-pricing-row unavailable'>
      <RoomInfo room={room} openRoomPopup={openRoomPopup} />
      <div className='resort-pricing__whats-included' />
      <div className='resort-pricing__meal-plan' />
      <div className='resort-pricing__price center-align'>
        <span className='sold-out'>
          <i className='font-icons fail-icon-new' />
          &nbsp;
          <FormattedMessage id='sold_out' defaultMessage='Sold out' />
        </span>
        {!isFixedPackage ? (
          <button
            className='btn-red-invert'
            smooth-scroll='smooth-scroll'
            scroll-to='#availiability-calendar-wrapper'
            onClick={handleClick}
          >
            <FormattedMessage
              id='view_availability'
              defaultMessage='View availability calendar'
            />
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
