import React, { useState } from 'react'
import Lightbox from 'react-images'

const PicPreview = ({ photo, setStatus }) => {

  const [isOpen, setOpenStatus] = useState(true)

  const onClose = () => {
    setOpenStatus(false)
    if (setStatus) setStatus(false)
  }
  return <Lightbox
    images={[{ src: photo }]}
    isOpen={isOpen}
    width={window.innerWidth * 0.9}
    onClose={onClose}
  />
}

export default PicPreview
