import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getTextFromInfo } from '../../../utils'

export default function PriceType({ isFixedPackage, roomParams, room }) {
  return useMemo(() => {
    if(roomParams.divers && !roomParams.students) {
      if(room.packageDiveKind === 30) {
        return (
          <FormattedMessage
            id='unlimited_shore_dives_included'
            defaultMessage='including unlimited shore dives'
          />
        )
      } else {
        return (
          <FormattedMessage
            id='divers_or_non-divers_only_pricing_type'
            values={{
              duration: room.nights,
              divesAmount: room.divesAmount * roomParams.divers,
              diveType: !isFixedPackage
                ? getTextFromInfo(
                  room.diveType,
                  'shop',
                  'DIVE_TYPE'
                ).toLowerCase()
                : '',
            }}
            defaultMessage='{ duration, plural, one {# night} other {# nights}} incl. {divesAmount} {diveType} dives'
          />
        )
      }
    } else if(!roomParams.divers && roomParams.students) {
      return (
        <FormattedMessage
          id='students_only_pricing_type'
          values={{
            duration: room.nights,
          }}
          defaultMessage='{ duration, plural, one {# night} other {# nights}} incl. diving courses'
        />
      )
    } else {
      if(room.packageDiveKind === 30) {
        return (
          <FormattedMessage
            id='unlimited_shore_dives_and_courses_included'
            defaultMessage='including unlimited shore dives and diving courses'
          />
        )
      } else {
        return (
          <FormattedMessage
            id='divers_or_non-divers_or_students_only_pricing_type'
            values={{
              duration: room.nights,
              divesAmount: room.divesAmount * roomParams.divers,
              diveType: !isFixedPackage
                ? getTextFromInfo(
                  room.diveType,
                  'shop',
                  'DIVE_TYPE'
                ).toLowerCase()
                : '',
            }}
            defaultMessage='{ duration, plural, one {# night} other {# nights}} incl. {divesAmount} {diveType} dives and diving courses'
          />
        )
      }
    }
  }, [roomParams, room, isFixedPackage])
}
