import { convertCurrency } from '../../helpers/PriceConverter'
import {
  getTextFromInfo,
  getTotalGuests,
  roundPrice,
} from '../../../utils'
import isObject from 'lodash/isObject'
import map from 'lodash/map'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import each from 'lodash/each'
import sortBy from 'lodash/sortBy'
import { trackGA4Ecommerce } from '../../../gtm'

export function calculateRoomPrice(
  {
    room,
    roomParams,
    nights,
    shopTitle,
    isFixedPackage,
    trackEvent = true,
  },
  callbackForFixedPackage,
  isCheckoutPage,
) {
  if(isFixedPackage) {
    let packages = map(room.packages, function(pack, key) {
      if(isObject(pack)) {
        pack.mealPlan = +key
        pack.ratePrice = pack.price
        if(
          !room.showRates &&
          pack.ids &&
          pack.ids.length &&
          !pack.exceptions.length
        ) {
          room.showRates = true
        }

        if(pack.exceptions && pack.exceptions.length) {
          room.exceptionMessage = pack.exceptions[0]
        }

        if(pack.mealPlan && pack.ratePrice && !pack.exceptions.length) {
          pack.id = key
          return pack
        }
      }
    })
    packages = filter(packages, function(pack) {
      return pack
    })

    room.rates = packages
    room.packageDiveKind = get(room.rates, '[0].packageDiveKind') // remove this line when BE will send it in a normal way
    room.divesAmount = room.packages.dives

    if(typeof callbackForFixedPackage === 'function') {
      callbackForFixedPackage(room)
    }

    room.diveCenterInclusions = room.packages.diverInclusions
    room.resortRoomInclusions = room.packages.nondiverInclusions
  } else {
    room.groupedRates = groupBy(sortBy(room.rates, 'kind'), 'mealPlan')
    room.rates = []

    each(room.groupedRates, function(v) {
      const selectedPriceData = {}
      each(roomParams.details, (guests) => {
        const totalGuests = getTotalGuests(guests)
        each(v, (rateObj) => {
          if(rateObj.pricesData) {
            each(rateObj.pricesData[totalGuests], (item, key) => {
              if(item.isSoldOut) {
                room.isSoldOut = true
                return
              }
              if(!selectedPriceData[totalGuests]) {
                selectedPriceData[totalGuests] = {}
              }
              if(!selectedPriceData[totalGuests][key]) {
                selectedPriceData[totalGuests][key] = {}
              }

              item.rateId = rateObj.id
              selectedPriceData[totalGuests][key] = item
            })
          }
        })
      })
      let ratePrice = parseFloat(room.diveProductsPrice)
      let compareRatePrice = parseFloat(room.diveProductsPrice)
      let rateIds = []
      let isRateValid = true

      const roomPricingIds = []

      each(roomParams.details, (guests) => {
        const totalGuests = getTotalGuests(guests)
        if(selectedPriceData[totalGuests]) {
          const pricingObjectsAmount = Object.keys(
            selectedPriceData[totalGuests],
          ).length
          if(parseInt(nights, 10) === pricingObjectsAmount) {
            each(selectedPriceData[totalGuests], (val) => {
              ratePrice += +val.priceGross * totalGuests
              compareRatePrice += +val.compareAtPrice * totalGuests
              if(!rateIds.includes(val.rateId)) {
                rateIds.push(val.rateId)
              }
              roomPricingIds.push({
                isRoom: true,
                pricing: val.pk,
                quantity: totalGuests,
              })
            })
          } else {
            isRateValid = false
          }
        }
      })
      room.roomPricingIds = roomPricingIds
      if(get(v, '[0].error[0]')) {
        room.error = v[0].error[0]
      }
      if(isRateValid) {
        room.rates.push({
          ...v[0],
          rateIds,
          ratePrice,
          compareRatePrice,
        })
      }
    })
    room.rates = sortBy(room.rates, 'ratePrice')
  }

  if(room.error.length) {
    room.showRates = false
  }

  if(!isCheckoutPage) {
    let items = []
    each(room.rates, (rate, index) => {
      if(rate.ratePrice && trackEvent) {
        const productData = {
          item_name: shopTitle,
          item_id: rate.id.toString(),
          price: Number(
            roundPrice(convertCurrency(rate.ratePrice, room.currency, 'USD')),
          ),
          item_brand: 'PADI',
          item_category: 'PADI Travel',
          item_category2: 'Dive Resort',
          item_variant: room.title,
          rate_type: getTextFromInfo(rate.mealPlan, 'shop', 'RESORT_MEAL_PLANS'),
          quantity: 1,
          item_list_name: 'PADI Travel Dive Resorts - Product Page',
        }
        items.push(productData)
        if(index === 0) {
          trackGA4Ecommerce(
            'view_item',
            {
              currency: 'USD',
              value: productData.price,
              items: productData,
            },
            'PADI Travel - Dive Resorts',
          )
        }
      } else if(!rate.isSoldOut && get(rate, 'error.length')) {
        room.rates = null
      }
    })
    room.eCommerceProducts = items
  }

  if(room.rates && room.rates.length) {
    room.minimalPrice = room.rates[0].ratePrice
  }

  return room
}
