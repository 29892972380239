import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import ConvertMetersToFeets from '../../../helpers/ConvertMetersToFeets'

export default function RoomInfo({
  room,
  openRoomPopup,
  areaFormat,
  showPicture,
  showDetailedDescription,
}) {
  const handleClick = () => openRoomPopup(room)
  const roomImage = get(room, 'images[0].thumbnails.FeaturedDealsSmallDesktop')
  return (
    <div className='resort-pricing__rooms'>
      <h4 className='room-name'>{room.title}</h4>
      <div className='room-info'>
        {showPicture && roomImage && (
          <div
            className='room-image'
            onClick={handleClick}
            style={{
              backgroundImage: `url(${roomImage})`,
            }}
          >
            <div className='custom-paging'>1 / {room.images.length}</div>
          </div>
        )}
        <div className='room-info-wrapper'>
          {showDetailedDescription && (
            <ul className='room-info-list'>
              <li>
                <i className='font-icons people-icon' />
                <FormattedMessage
                  id='people_max'
                  values={{
                    maxOccupancy: room.maxOccupancy,
                  }}
                  defaultMessage='{ maxOccupancy, plural, one {# person} other {# people}} max'
                />
              </li>
              <li>
                <i className='font-icons room-icon' />
                <FormattedMessage
                  id='bed_type'
                  values={{
                    bedTypeTitle: room.bedTypeTitle,
                  }}
                  defaultMessage='{ bedTypeTitle } bed'
                />
              </li>
              <li>
                <i className='font-icons length-icon' />
                <FormattedMessage
                  id='room_size'
                  values={{
                    roomSize: (
                      <ConvertMetersToFeets
                        data={room.size}
                        currentUnits={areaFormat}
                      />
                    ),
                  }}
                  defaultMessage='{ roomSize } room size'
                />
              </li>
              {!!room.minAge && (
                <li>
                  <i className='font-icons icon-warning' />
                  <FormattedMessage
                    id='min_age'
                    values={{
                      minAge: room.minAge,
                    }}
                    defaultMessage='Min age of guests: {minAge}'
                  />
                </li>
              )}
            </ul>
          )}
          <a className='room-more-info' onClick={handleClick}>
            <FormattedMessage
              id='show_room_info'
              defaultMessage='Show room information'
            />
            <i className='font-icons right-arrow-icon' />
          </a>
        </div>
      </div>
    </div>
  )
}
