import { Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import { handleEventBubbling } from '../../dom-handlers'

const messages = defineMessages({
  originalTitle: {
    id: 'originalTitle',
    defaultMessage: 'Original activity title:',
  },
})

export default function AdventureOriginalTitle({ title, originalTitle }) {
  if(!originalTitle || title === originalTitle) {
    return null
  }

  const intl = useIntl()

  const formattedTitle = `<p style="text-align: center;text-transform: none; font-size: 10px; margin-bottom: 5px">
      ${intl.formatMessage(messages.originalTitle)}
    </p>
    <p style="text-transform: uppercase;color: #fff; font-size: 14px; text-align: center; margin-bottom: 0; white-space: normal;">${originalTitle}</p>
  `

  return (
    <Fragment>
      <i
        className='font-icons icon-languages pointer mls'
        data-tip={formattedTitle}
        data-for={originalTitle}
        data-html
        onClick={handleEventBubbling}
      />
      <ReactTooltip effect='solid' id={originalTitle} />
    </Fragment>
  )
}
