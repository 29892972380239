import { FormattedMessage } from 'react-intl'
import InputMask from 'react-input-mask'

export default function FinalFormInputMask({
  input,
  meta,
  placeholder,
  label,
  icon,
  disabled,
  hint,
  mask,
  formatChars,
  beforeMaskedValueChange,
  isRequired,
  className = '',
  onBlur,
}) {
  const error = meta.touched && (meta.error || meta.submitError)
  return (
    <div className={`input-wrapper ${className} ${error ? 'error-field' : ''}`}>
      <label>
        {typeof label === 'object' && label.id ? (
          <FormattedMessage {...label} />
        ) : (
          label
        )}
        {isRequired ? <span className='asterisk'>*</span> : ''}
      </label>
      <div className={icon ? 'input-container--icon' : ''}>
        {icon || ''}
        <InputMask
          className='input'
          {...input}
          mask={mask}
          autoComplete='off'
          formatChars={formatChars}
          placeholder={placeholder}
          beforeMaskedValueChange={beforeMaskedValueChange}
          disabled={disabled}
          onBlur={onBlur}
        />
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && meta.touched && <div className='error-text'>{error}</div>}
      </div>
    </div>
  )
}
