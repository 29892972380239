import {
  FormattedMessage,
  injectIntl,
  defineMessages,
} from 'react-intl'
import PropTypes from 'prop-types'
import { Component } from 'react'
import API from '../../api'
import { isValidEmail } from '../../utils'
import Modal from 'react-modal'
import { ContactPerson, TripDates, WhatAreYouLooking } from './booking-enquire'

const messages = defineMessages({
  description: {
    id: 'operator_enquiry.text',
    defaultMessage:
      'Let us know your preferences - we will come back with a bespoke quote as soon as possible.',
  },
  textAreaPlaceholder: {
    id: 'booking_enquiry.operator.description',
    defaultMessage: 'Any comments or extra information about your enquiry?',
  },
  hint: {
    id: 'booking_enquire.operator.hint',
    defaultMessage: 'Sending this enquiry does not commit you in any way.',
  },
  mandatoryError: {
    id: 'booking_enquire.mandatory_error',
    defaultMessage:
      'Some mandatory fields are incomplete, please review your enquiry and try to submit again',
  },
  enquiryFinished: {
    id: 'booking_enquire_operator.finished',
    defaultMessage:
      'Thank you for your enquiry. The PADI Travel team will get back to you as soon as possible.',
  },
})

const defaultState = {
  kind: 60,
  firstDay: '',
  lastDay: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  number_divers: 0,
  number_guests: 0,
  number_dives_per_diver: 0,
  number_rooms: 0,
  submitted: false,
  errors: {},
}

class OperatorEnquiryPopup extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  onChange = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if(this.state.submitted) {
          this.validate()
        }
      }
    )
  }

  submit = (e) => {
    e.preventDefault()

    this.setState({
      submitted: true,
    })

    if(this.validate()) {
      const obj = { ...this.state }
      obj.shop = this.props.shop

      API('shop/booking-request/', false, true)
        .post(obj)
        .then(() => {
          this.setState({
            ...defaultState,
            finished: true,
          })
        })
    }
    return false
  }

  setPhoneInstance = (instance) => {
    this.phoneInstance = instance
  }

  validate() {
    let errors = {}

    if(!this.state.first_name) {
      errors.first_name = true
    }

    if(!this.state.firstDay) {
      errors.firstDay = true
    }

    if(!this.state.lastDay) {
      errors.lastDay = true
    }

    if(!this.state.number_divers) {
      errors.number_divers = true
    }

    if(!this.state.last_name) {
      errors.last_name = true
    }

    if(!this.state.email) {
      errors.email = true
    } else if(!isValidEmail(this.state.email)) {
      errors.email = true
    }

    if(!this.state.phone) {
      errors.phone = true
    } else if(this.phoneInstance && !this.phoneInstance.isValidNumber()) {
      errors.phone = true
    }

    this.setState({
      errors,
    })

    return Object.keys(errors).length === 0
  }

  render() {
    const { errors } = this.state
    const { shopTitle } = this.props

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        className='Modal react-popup form-popup'
        overlayClassName='Overlay'
        contentLabel='Booking enquire popup'
      >
        <span className='close-btn' onClick={this.props.closeModal} />
        {this.state.finished ? (
          <div className='form-popup-content'>
            <p>{this.props.intl.formatMessage(messages.enquiryFinished)}</p>
          </div>
        ) : (
          <div className='form-popup-content'>
            <h3>
              {shopTitle && (
                <FormattedMessage
                  id='operator_enquiry'
                  values={{ shopTitle }}
                  defaultMessage='Booking enquiry for {shopTitle}'
                />
              )}
            </h3>
            <p>{this.props.intl.formatMessage(messages.description)}</p>
            <form
              name='enquireForm'
              noValidate='novalidate'
              onSubmit={this.submit}
            >
              <TripDates
                onChange={this.onChange}
                dateTo={this.state.lastDay}
                dateFrom={this.state.firstDay}
                errors={errors}
              />
              <ContactPerson
                onChange={this.onChange}
                errors={errors}
                firstName={this.state.first_name}
                lastName={this.state.last_name}
                email={this.state.email}
                phone={this.state.phone}
                kind={this.state.kind}
                setPhoneInstance={this.setPhoneInstance}
              />
              <WhatAreYouLooking
                onChange={this.onChange}
                errors={errors}
                numberDivers={this.state.number_divers}
                numberGuests={this.state.number_guests}
                description={this.state.description}
                numberRooms={this.state.number_rooms}
                kind={this.state.kind}
                textAreaPlaceholder={messages.textAreaPlaceholder}
              />
              <div className='form-bottom'>
                <div>
                  <p>{this.props.intl.formatMessage(messages.hint)}</p>
                  <br />
                  <p>
                    <FormattedMessage
                      id='booking_enquire.mandatory'
                      defaultMessage='* mandatory fields'
                    />
                  </p>
                  <p
                    className='error-booking-enquire'
                    ng-show='enquireForm.$invalid && enquireForm.$submitted'
                  >
                    {this.props.intl.formatMessage(messages.mandatoryError)}
                  </p>
                </div>
                <div>
                  <button className='btn-red' type='submit'>
                    <FormattedMessage
                      id='send_request'
                      defaultMessage='Send request'
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Modal>
    )
  }
}

OperatorEnquiryPopup.propTypes = {
  intl: PropTypes.object.isRequired,
  shop: PropTypes.number.isRequired,
  shopTitle: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default injectIntl(OperatorEnquiryPopup)
