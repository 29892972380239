import { FormattedMessage } from 'react-intl'

export default function IncludedDiving({ numberOfDivesMin, numberOfDivesMax }) {
  if(!numberOfDivesMin && !numberOfDivesMax) {
    return null
  }
  return (
    <li>
      <i className='font-icons dive-center-icon' />
      {numberOfDivesMax ? (
        <FormattedMessage
          id='including_dives_min_max'
          defaultMessage='Including { divesMin } - { divesMax } dives'
          values={{
            divesMin: numberOfDivesMin,
            divesMax: numberOfDivesMax,
          }}
        />
      ) : (
        <FormattedMessage
          id='including_dives_min'
          defaultMessage='Including { divesMin } dives'
          values={{
            divesMin: numberOfDivesMin,
          }}
        />
      )}
    </li>
  )
}
