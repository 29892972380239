import { FormattedMessage, defineMessages } from 'react-intl'
import PriceConverter from './PriceConverter'
import { useMemo } from 'react'

const msg = defineMessages({
  promotion_save_qty: {
    id: 'promotion_save_qty',
    defaultMessage: 'Save {qty}',
  },
  promotion_save_nights: {
    id: 'promotion_save_nights',
    defaultMessage: '{freeNights, plural, one {# night free} other {# nights free}}',
  },
  promotion_deal: {
    id: 'promotion_deal',
    defaultMessage: 'Deal',
  },
})

function getLabel(promotion, price, compareAtPrice, currency, showComparePrice) {
  if(promotion) {
    switch (promotion.kind) {
      case 10:
        return {
          ...msg.promotion_save_qty,
          values: {
            qty: <PriceConverter noCurrency amount={Math.round(compareAtPrice - price)} currency={currency} />,
          },
        }
      case 20:
        return {
          ...msg.promotion_save_qty,
          values: {
            qty: <PriceConverter noCurrency amount={Math.round(((price / (100 - promotion.value)) * 100) - price)} currency={currency} />,
          },
        }
      case 30:
        return {
          ...msg.promotion_save_nights,
          values: {
            freeNights: promotion.value,
          },
        }
      case 40:
        return {
          ...msg.promotion_deal,
        }
    }
  } else if(compareAtPrice > price && showComparePrice) {
    return {
      ...msg.promotion_save_qty,
      values: {
        qty: <PriceConverter noCurrency amount={Math.round(compareAtPrice - price)} currency={currency} />,
      },
    }
  }
}

export default function PromotionLabel({ promotion, price, compareAtPrice, currency, showComparePrice }) {
  const message = useMemo(() => getLabel(promotion, price, compareAtPrice, currency, showComparePrice))

  return message ? (<span className='deal-label'>
    <FormattedMessage {...message} />
  </span>) : null
}
