import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { getTextFromInfo } from '../../utils'

function getMilestoneAmountType(amountTypeKey, currency) {
  return (
    getTextFromInfo(amountTypeKey, 'shop', 'MILESTONE_AMOUNT_TYPE') || currency
  )
}
export default function PaymentAndCancellation({
  paymentInformation,
  milestones = [],
  isCustomCancellation,
  cancellationMilestones = [],
  customCancellationText,
  currency,
  onlyCancellation,
  freeCancellationDays,
}) {
  const milestonesLength = milestones.length
  const cancellationMilestonesLength = cancellationMilestones.length

  return (
    <Fragment>
      {!onlyCancellation && (
        <div className='circled-list'>
          <ul>
            <li className='title'>
              <FormattedMessage
                id='payment_schedule'
                defaultMessage='Payment schedule'
              />
            </li>
            <li className='bold'>
              <FormattedMessage id='now_0' defaultMessage='Now: 0%' />
            </li>
            {milestonesLength ? (
              <li>
                <FormattedMessage
                  id='payment_schedule.due_booking'
                  values={{
                    amount: paymentInformation.paymentTermsCustomer,
                    amountType: getMilestoneAmountType(
                      paymentInformation.paymentTermsTypeCustomer,
                      currency
                    ),
                  }}
                  defaultMessage='48 hours after booking confirmation: { amount } { amountType } (downpayment)'
                />
              </li>
            ) : (
              <li>
                <FormattedMessage
                  id='payment_schedule.due_booking_100%'
                  defaultMessage='100% 48 hours after confirmation'
                />
              </li>
            )}
            {milestones.map((milestone, index) => {
              return (
                <li key={milestone.id || index}>
                  {index + 1 !== milestonesLength ? (
                    <FormattedMessage
                      id='milestone_amount_first'
                      values={{
                        amount: milestone.amount,
                        amountType: getMilestoneAmountType(
                          milestone.amountType,
                          currency
                        ),
                        days: milestone.days,
                      }}
                      defaultMessage='{ days } days before trip start: { amount } { amountType }'
                    />
                  ) : (
                    <FormattedMessage
                      id='milestone_amount_last'
                      values={{
                        amount: milestone.amount,
                        amountType: getMilestoneAmountType(
                          milestone.amountType,
                          currency
                        ),
                        days: milestone.days,
                      }}
                      defaultMessage='{ days } days before trip start: { amount } { amountType } (balance)'
                    />
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {!!cancellationMilestonesLength && isCustomCancellation && (
        <div className='circled-list'>
          <ul className='circled-list pbn'>
            <li className='title'>
              <FormattedMessage
                id='cancellation_fees'
                defaultMessage='Cancellation fees'
              />
            </li>
          </ul>
          {freeCancellationDays && (
            <p>
              <FormattedMessage
                id='free_cancellation_48h'
                tagName='strong'
                defaultMessage='Free cancellation for 48h after confirmation'
              />
            </p>
          )}
          <div
            className='wysiwyg'
            dangerouslySetInnerHTML={{ __html: customCancellationText }}
          />
        </div>
      )}
      {!isCustomCancellation && (
        <div className='circled-list'>
          {freeCancellationDays && (
            <p>
              <FormattedMessage
                id='free_cancellation_48h'
                tagName='strong'
                defaultMessage='Free cancellation for 48h after confirmation'
              />
            </p>
          )}
          {cancellationMilestonesLength > 2 && (
            <ul>
              <li>
                <FormattedMessage
                  id='cancellation.free_text'
                  defaultMessage='Free cancellation for 48h after confirmation (except for last minute bookings)'
                />
              </li>
              {cancellationMilestones.map((milestone, index) => {
                return (
                  <li key={milestone.id || index}>
                    {index === 0 && (
                      <FormattedMessage
                        id='cancel_milestone_first'
                        values={{
                          amount: milestone.amount,
                          amountType: getMilestoneAmountType(
                            milestone.amountType,
                            currency
                          ),
                          days: milestone.days,
                        }}
                        defaultMessage='{ amount } { amountType } of the total booking amount will be charged if you cancel more than { days } days prior to arrival'
                      />
                    )}
                    {index !== 0 && index + 1 !== cancellationMilestonesLength && (
                      <FormattedMessage
                        id='cancel_milestone2'
                        values={{
                          amount: milestone.amount,
                          amountType: getMilestoneAmountType(
                            milestone.amountType,
                            currency
                          ),
                          prevDays: cancellationMilestones[index - 1].days,
                          days: milestone.days,
                        }}
                        defaultMessage='{ amount } { amountType } will be charged if you cancel between { prevDays } and { days } days prior to arrival'
                      />
                    )}
                    {index + 1 === cancellationMilestonesLength && (
                      <FormattedMessage
                        id='cancel_milestone_last'
                        values={{
                          amount: milestone.amount,
                          amountType: getMilestoneAmountType(
                            milestone.amountType,
                            currency
                          ),
                          days: milestone.days,
                        }}
                        defaultMessage='{ amount } { amountType } will be charged if you cancel less than { days } days prior to arrival'
                      />
                    )}
                  </li>
                )
              })}
            </ul>
          )}
          {cancellationMilestonesLength === 2 && (
            <ul>
              <li>
                <FormattedMessage
                  id='cancellation.free_text'
                  defaultMessage='Free cancellation for 48h after confirmation (except for last minute bookings)'
                />
              </li>
              <li>
                <FormattedMessage
                  id='cancel_milestone_first'
                  values={{
                    amount: cancellationMilestones[0].amount,
                    days: cancellationMilestones[0].days,
                    amountType: getMilestoneAmountType(
                      cancellationMilestones[0].amountType,
                      currency
                    ),
                  }}
                  defaultMessage='{ amount } { amountType } of the total booking amount will be charged if you cancel more than { days } days prior to arrival'
                />
              </li>
              <li>
                <FormattedMessage
                  id='cancel_milestone_last'
                  values={{
                    amount: cancellationMilestones[1].amount,
                    days: cancellationMilestones[1].days,
                    amountType: getMilestoneAmountType(
                      cancellationMilestones[1].amountType,
                      currency
                    ),
                  }}
                  defaultMessage='{ amount } { amountType } will be charged if you cancel less than { days } days prior to arrival'
                />
              </li>
            </ul>
          )}
          {cancellationMilestonesLength === 1 && (
            <ul>
              <li>
                <FormattedMessage
                  id='cancellation.free_text'
                  defaultMessage='Free cancellation for 48h after confirmation (except for last minute bookings)'
                />
              </li>
              <li>
                <FormattedMessage
                  id='cancel_milestone_last'
                  values={{
                    amount: cancellationMilestones[0].amount,
                    days: cancellationMilestones[0].days,
                    amountType: getMilestoneAmountType(
                      cancellationMilestones[0].amountType,
                      currency
                    ),
                  }}
                  defaultMessage='{ amount } { amountType } will be charged if you cancel less than { days } days prior to arrival'
                />
              </li>
            </ul>
          )}
        </div>
      )}
    </Fragment>
  )
}
