import { useState, useEffect, Fragment } from 'react'
import Modal from 'react-modal'

const DjangoModal = ({ text, comment }) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    Modal.setAppElement(document.body)
  }, [])

  const handleClick = () => {
    setIsOpen(true)
  }

  const closeModal = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <Fragment>
      <span onClick={handleClick}>{text}</span>
      <Modal
        className='Modal react-popup form-popup'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <span className='close-btn' onClick={closeModal} />
        <h3 className='mbh'>{text}</h3>
        {comment}
      </Modal>
    </Fragment>
  )
}

export default DjangoModal
