import FormDropDown from './FormDropDown'
import { defineMessages } from 'react-intl'

import './input-with-dropdown.scss'

const messages = defineMessages({
  shoeSize: {
    id: 'traveler.form.placeholder.shoe.size',
    defaultMessage: 'Shoe Size',
  },
})

const engUnitTextMapping = {
  10: 'EU',
  20: 'US',
  30: 'UK',
}

function UserShoeSizeField({ sizeDropDownName, dropdownName, shoeSizeUnit }) {
  const SHOE_SIZE_UNITS = window.info.account.SHOE_SIZE_UNIT
  /* eslint-disable standard/computed-property-even-spacing */
  const SHOE_SIZES =
    window.info.account[
      `SHOE_SIZES_${engUnitTextMapping[shoeSizeUnit] || 'EU'}`
    ]

  return (
    <div className='dropdowns-field'>
      <FormDropDown
        name={sizeDropDownName}
        isArrayCollection
        options={SHOE_SIZE_UNITS}
        title={messages.shoeSize}
        isClearable={false}
        className='small-dropdown'
      />
      <FormDropDown
        name={dropdownName}
        isArrayCollection
        options={SHOE_SIZES}
        className='main-dropdown'
      />
    </div>
  )
}

export default UserShoeSizeField
