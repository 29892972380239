import FormDropDown from './FormDropDown'
import { defineMessages, injectIntl } from 'react-intl'

import './input-with-dropdown.scss'
import FormInputMask from './FormInputMask'

const messages = defineMessages({
  height: {
    id: 'traveler.form.placeholder.height',
    defaultMessage: 'Height',
  },
  centimetersHeight: {
    id: 'centimetersHeight',
    defaultMessage: 'Height in centimeters',
  },
})

function UserHeightField(props) {
  const {
    inputName,
    dropdownName,
    dropdownOptions,
    intl,
    change,
    heightUnit,
  } = props
  const options = dropdownOptions || window.info.account.HEIGHT_UNIT

  const onCantimetersChange = (inputValue) => {
    let { value } = inputValue
    const newValue =
      value && value.indexOf('_') === -1 ? value.replace(' cm', '') : value
    return {
      ...inputValue,
      value: newValue,
    }
  }

  const onFeetsChange = (inputValue) => {
    let { value } = inputValue
    let newValue = value
    const inchFirstNumber = parseInt(value[2])

    if(parseInt(inchFirstNumber) !== 1) {
      newValue = value.slice(0, -1)
    }
    return {
      ...inputValue,
      value: newValue,
    }
  }

  const clearLastCharacterOnBlur = ({ target }) => {
    const newValue = target.value.replace('_', '')
    change(inputName, newValue)
    target.value = newValue
  }

  return (
    <div className='input-with-dropdown'>
      <FormDropDown
        name={dropdownName}
        isArrayCollection
        options={options}
        title={messages.height}
        isClearable={false}
        className='small-dropdown'
      />
      {heightUnit === 10 ? (
        <FormInputMask
          name={inputName}
          mask='123 cm'
          formatChars={{
            '1': '[1,2]',
            '2': '[0-9]',
            '3': '[0-9]',
          }}
          placeholder={intl.formatMessage(messages.centimetersHeight)}
          beforeMaskedValueChange={onCantimetersChange}
          className='main-input'
        />
      ) : (
        <FormInputMask
          name={inputName}
          mask={`1'23`}
          formatChars={{
            '1': '[3-8]',
            '2': '[0-9]',
            '3': '[0,1]',
          }}
          placeholder={`5'7`}
          beforeMaskedValueChange={onFeetsChange}
          onBlur={clearLastCharacterOnBlur}
          className='main-input'
        />
      )}
    </div>
  )
}

export default injectIntl(UserHeightField)
