import { Component } from 'react'
import Slider from 'react-slick'
import Modal from 'react-modal'
import { FormattedMessage } from 'react-intl'
import ConvertMetersToFeets from '../helpers/ConvertMetersToFeets'
import { findInObject, getTextFromInfo } from '../../utils'

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrows: true,
}

export default class RoomPopup extends Component {
  render() {
    const { roomInfo } = this.props
    return (<Modal
      isOpen={this.props.isOpen}
      onRequestClose={this.props.closeModal}
      ariaHideApp={false}
      className='Modal react-popup form-popup dr-room-popup'
      overlayClassName='Overlay'
    >
      <span className='close-btn pointer' onClick={this.props.closeModal} />
      <div className='room-popup-title'>{roomInfo.title}</div>
      <div className='room-popup-images'>
        <Slider className='slick-pt-custom mbn' {...settings}>
          {
            roomInfo.images && roomInfo.images.map((img) => {
              return <div key={img.id}>
                <div className='image-wrapper'
                  style={{
                    backgroundImage: `url(${img.thumbnails
                      ? img.thumbnails.FeaturedDealsBigTabletLand
                      : img.src})`,
                  }}
                />
              </div>
            })
          }
        </Slider>
      </div>
      <div className='room-popup-information new-buttons'>
        <ul className='clearfix'>
          <li>
            <i className='font-icons length-icon' />
            <span>
              <FormattedMessage id='room_popup.size' defaultMessage='Room size' />:&nbsp;
              <b>
                {+roomInfo.size
                  ? (<ConvertMetersToFeets data={roomInfo.size} currentUnits={this.props.areaFormat} />)
                  : roomInfo.size}</b>
            </span>
          </li>
          <li>
            <i className='font-icons peoples-icon' />
            <span>
              <FormattedMessage id='room_popup.max_occupancy' defaultMessage='Max. occupancy' />:&nbsp;
              <b>{roomInfo.maxOccupancy}</b>
            </span>
          </li>
          <li>
            <i className='font-icons room-icon' />
            <span>
              <FormattedMessage id='room_popup.beds' defaultMessage='Beds' />:&nbsp;
              <b>{roomInfo.bedTypeTitle || getTextFromInfo(roomInfo.bedType, 'shop', 'BED_TYPE')}</b>
            </span>
          </li>
          <li>
            <i className='font-icons icon-shower' />
            <span>
              <FormattedMessage id='room_popup.bathroom' defaultMessage='Private bathroom' />:&nbsp;
              <b>{findInObject(roomInfo.amenities, 1) ? <FormattedMessage id='yes' defaultMessage='Yes' />
                : <FormattedMessage id='no' defaultMessage='No' />}</b>
            </span>
          </li>
          <li>
            <i className='font-icons icon-wifi' />
            <span>
              <FormattedMessage id='room_popup.wifi' defaultMessage='WiFi in room' />:&nbsp;
              <b>
                {
                  findInObject(roomInfo.amenities, 14) || findInObject(roomInfo.amenities, 13)
                    ? <FormattedMessage id='yes' defaultMessage='Yes' />
                    : <FormattedMessage id='no' defaultMessage='No' />
                }
              </b>
            </span>
          </li>
          <li>
            <i className='font-icons icon-condition' />
            <span>
              <FormattedMessage id='room_popup.air' defaultMessage='Air conditioning' />:&nbsp;
              <b>{findInObject(roomInfo.amenities, 5) ? <FormattedMessage id='yes' defaultMessage='Yes' />
                : <FormattedMessage id='no' defaultMessage='No' />}</b>
            </span>
          </li>
        </ul>
      </div>
      {roomInfo.description && (
        <div className='room-description'>{roomInfo.description}</div>
      )}
      {roomInfo.amenities && roomInfo.amenities.length ? (
        <div className='room-amenities'>
          <p>
            <FormattedMessage id='room_amenities' defaultMessage='Room amenities' />
          </p>
          <ul className='clearfix'>
            {
              roomInfo.amenities.map(amenitie => {
                return (<li key={amenitie.id}>{amenitie.title} </li>)
              })
            }
          </ul>
        </div>
      ) : ''}
    </Modal>)
  }
}
