exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".checkboxContainer--2tRaO{display:flex;align-items:center;height:28px;margin-bottom:0;font-size:14px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.checkboxContainer--2tRaO,.switch--1BIUz{position:relative;cursor:pointer}.switch--1BIUz{display:inline-block;width:36px;height:14px;border-radius:20px;vertical-align:top;background:rgba(33,33,33,.08);transition:.2s}.switch--1BIUz:before{content:\"\";display:inline-block;width:20px;height:20px;position:absolute;top:-3px;left:-1px;background:#fff;border-radius:50%;box-shadow:0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);transition:.15s}.checkboxInput--1p7jJ{display:block;width:0;height:0;position:absolute;z-index:-1;opacity:0}.checkboxInput--1p7jJ:active+switch,.checkboxInput--1p7jJ:focus+switch{-webkit-animation:checkbox-active-on--2zkYQ .5s linear forwards;animation:checkbox-active-on--2zkYQ .5s linear forwards}.checkboxInput--1p7jJ:checked+.switch--1BIUz{background:rgba(0,112,211,.38)}.checkboxInput--1p7jJ:checked+.switch--1BIUz:before{background:#0070d3;transform:translateX(18px)}.checkboxLabel--3vgMA{font-size:12px;font-weight:700;color:rgba(0,0,0,.6);padding-right:8px;vertical-align:middle}@-webkit-keyframes checkbox-active-off--2-CzZ{0%{box-shadow:0 0 0 0 rgba(154,190,247,0)}99%{box-shadow:0 0 0 10px rgba(154,190,247,.5)}}@keyframes checkbox-active-off--2-CzZ{0%{box-shadow:0 0 0 0 rgba(154,190,247,0)}99%{box-shadow:0 0 0 10px rgba(154,190,247,.5)}}", ""]);

// Exports
exports.locals = {
	"checkboxContainer": "checkboxContainer--2tRaO",
	"switch": "switch--1BIUz",
	"checkboxInput": "checkboxInput--1p7jJ",
	"checkbox-active-on": "checkbox-active-on--2zkYQ",
	"checkboxLabel": "checkboxLabel--3vgMA",
	"checkbox-active-off": "checkbox-active-off--2-CzZ"
};