import { Field } from 'react-final-form'
import Checkbox from '../Checkbox'
import { StyledCheckboxFinalForm } from '../StyledCheckbox'

export default function FormCheckbox(props) {
  const { name } = props
  return <Field name={name} component={Checkbox} type='checkbox' {...props} />
}

export function FormCheckboxStyled(props) {
  const { name } = props
  return (
    <Field
      name={name}
      component={StyledCheckboxFinalForm}
      type='checkbox'
      {...props}
    />
  )
}
