import { Fragment, useState, useEffect } from 'react'
import StarRatingComponent from 'react-star-rating-component'
import moment from 'moment'
import API from '../../api'
import { FormattedMessage } from 'react-intl'
import DropdownDate from 'react-dropdown-date'
import Modal from 'react-modal'

import './review-styles.sass'
import { toast } from 'react-toastify'
import store from 'store2'
import { createUrl } from '../../localized-urls'
import { isLoggedIn } from '../../utils'
// import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'

export function submitReview(shopSlug, data) {
  return API(`shop/${shopSlug}/reviews/`, false, true)
    .post(data)
    .then(() => {
      toast.success(
        <FormattedMessage
          id='review_added'
          defaultMessage='Your review was successfully added'
        />,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      )
    })
}

export default function ReviewPopup({
  slug,
  kind,
  shopTitle,
  isOpen,
  closeModal,
}) {
  const [review, setReview] = useState({})
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if(isSubmitted) {
      validate()
    }
  }, [isSubmitted, review])

  const onChange = (key, value) => {
    setReview({
      ...review,
      [key]: value,
    })
  }

  const validate = () => {
    const errors = {}

    if(!review.trip_end_date) {
      errors.trip_end_date = true
    }

    if(!review.rate) {
      errors.rate = true
    }

    if(!review.agreement) {
      errors.agreement = true
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setIsSubmitted(true)

    if(validate()) {
      setLoading(true)
      const data = { ...review, shopSlug: slug }

      if(isLoggedIn()) {
        submitReview(slug, data)
          .then(() => {
            closeModal()
            setErrors({})
            setIsSubmitted(false)
            setLoading(false)
            setReview({})
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        store.session('reviewData', data)
        window.location = createUrl('login')
      }
    }

    return false
  }

  // onStarClick = (value) => {
  //   this.setState({
  //     review: {
  //       rating: value
  //     }
  //   })
  // }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className='Modal react-popup form-popup'
      overlayClassName='Overlay'
      contentLabel='Booking enquire popup'
    >
      <span className='close-btn' onClick={closeModal} />
      <div className={`review-popup ${loading ? 'loader' : ''}`}>
        <h3>{shopTitle}</h3>
        <form onSubmit={onSubmit}>
          <div className='form-row grey'>
            <div className='row-title'>
              <FormattedMessage
                id='when_did_you_dive'
                defaultMessage='When did you dive with them?'
              />
            </div>
            <div
              className={`row-info ${errors.trip_end_date ? 'has-error' : ''}`}
            >
              <DropdownDate
                classes={{
                  dateContainer: 'dropdown-date',
                }}
                onMonthChange={() => onChange('trip_end_date', null)}
                onDayChange={() => onChange('trip_end_date', null)}
                onYearChange={() => onChange('trip_end_date', null)}
                onDateChange={(date) => {
                  const formattedDate = moment(date)
                    .utc()
                    .format('YYYY-MM-DDThh:mm:ss')

                  if(formattedDate.indexOf('1970') === -1) {
                    onChange('trip_end_date', formattedDate)
                  }
                }}
                startDate={moment().subtract(10, 'years').format('YYYY-MM-DD')}
              />
            </div>
          </div>
          <p className='review-hint'>
            <FormattedMessage
              id='give_rating'
              defaultMessage='Please give an overall 1 to 5 star rating for this liveaboard.'
            />
          </p>
          <div className='form-row grey'>
            <div className='row-title'>
              <FormattedMessage
                id='overall_rating'
                defaultMessage='Overall rating'
              />
            </div>
            <div className='row-info'>
              <div
                className={`rating-wrapper big-stars ${
                  errors.rate ? 'has-error' : ''
                }`}
              >
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rate'
                  starCount={5}
                  value={review.rate}
                  onStarClick={(value) => onChange('rate', value)}
                />
              </div>
            </div>
          </div>
          <p className='review-hint'>
            <FormattedMessage
              id='give_rating_details'
              defaultMessage='Please provide other divers as much information as possible about your experience with this liveaboard.
      It will help them tremendously in evaluating their options when booking their next dive vacation.'
            />
          </p>
          <div className='form-row grey'>
            <div className='row-title'>
              <FormattedMessage
                id='specific_rating'
                defaultMessage='Specific rating'
              />
            </div>
            <div className='row-info detailed-rating'>
              {kind === 20 ? (
                <Fragment>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='resort' defaultMessage='Resort' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateResort'
                      starCount={5}
                      value={review.rateResort}
                      onStarClick={(value) => onChange('rateResort', value)}
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage
                        id='dive_center'
                        defaultMessage='Dive center'
                      />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateDiveCenter'
                      starCount={5}
                      value={review.rateDiveCenter}
                      onStarClick={(value) => onChange('rateDiveCenter', value)}
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='service' defaultMessage='Service' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateService'
                      starCount={5}
                      value={review.rateService}
                      onStarClick={(value) => onChange('rateService', value)}
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='rooms' defaultMessage='Rooms' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateRooms'
                      starCount={5}
                      value={review.rateRooms}
                      onStarClick={(value) => onChange('rateRooms', value)}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='vessel' defaultMessage='Vessel' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateVessel'
                      starCount={5}
                      value={review.rateVessel}
                      onStarClick={(value) => onChange('rateVessel', value)}
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage
                        id='dive_infrastructure'
                        defaultMessage='Dive infrastructure'
                      />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateDiveInfrastructure'
                      starCount={5}
                      value={review.rateDiveInfrastructure}
                      onStarClick={(value) =>
                        onChange('rateDiveInfrastructure', value)
                      }
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='service' defaultMessage='Service' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateService'
                      starCount={5}
                      value={review.rateService}
                      onStarClick={(value) => onChange('rateService', value)}
                    />
                  </div>
                  <div className='rating-wrap'>
                    <span className='sub-title'>
                      <FormattedMessage id='cabins' defaultMessage='Cabins' />
                    </span>
                    <StarRatingComponent
                      emptyStarColor='#dfdfe0'
                      name='rateCabins'
                      starCount={5}
                      value={review.rateCabins}
                      onStarClick={(value) => onChange('rateCabins', value)}
                    />
                  </div>
                </Fragment>
              )}
              <div className='rating-wrap'>
                <span className='sub-title'>
                  <FormattedMessage
                    id='rental_equipment'
                    defaultMessage='Rental equipment'
                  />
                </span>
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rateRentalEquipment'
                  starCount={5}
                  value={review.rateRentalEquipment}
                  onStarClick={(value) =>
                    onChange('rateRentalEquipment', value)
                  }
                />
              </div>
              <div className='rating-wrap'>
                <span className='sub-title'>
                  <FormattedMessage id='safety' defaultMessage='Safety' />
                </span>
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rateSafety'
                  starCount={5}
                  value={review.rateSafety}
                  onStarClick={(value) => onChange('rateSafety', value)}
                />
              </div>
              <div className='rating-wrap'>
                <span className='sub-title'>
                  <FormattedMessage id='food' defaultMessage='Food' />
                </span>
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rateFood'
                  starCount={5}
                  value={review.rateFood}
                  onStarClick={(value) => onChange('rateFood', value)}
                />
              </div>
              <div className='rating-wrap'>
                <span className='sub-title'>
                  <FormattedMessage id='staff' defaultMessage='Staff' />
                </span>
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rateStaff'
                  starCount={5}
                  value={review.rateStaff}
                  onStarClick={(value) => onChange('rateStaff', value)}
                />
              </div>
              <div className='rating-wrap'>
                <span className='sub-title'>
                  <FormattedMessage id='value' defaultMessage='Value' />
                </span>
                <StarRatingComponent
                  emptyStarColor='#dfdfe0'
                  name='rateValue'
                  starCount={5}
                  value={review.rateValue}
                  onStarClick={(value) => onChange('rateValue', value)}
                />
              </div>
            </div>
          </div>
          <div className='form-row '>
            <div className='row-title'>
              <FormattedMessage id='review' defaultMessage='Review' />
            </div>
            <div className='row-info'>
              <textarea
                onChange={(e) => onChange('comment', e.target.value)}
                value={review.comment}
                placeholder='Tell other divers about your experience...'
              />
            </div>
          </div>
          <div className='form-row grey'>
            <div className='row-title' />
            <div className='row-info'>
              <div className='agreement'>
                <label
                  className={`checkbox-wrap ${
                    errors.agreement ? 'has-error' : ''
                  }`}
                >
                  <input
                    type='checkbox'
                    required='required'
                    onClick={(e) => onChange('agreement', e.target.checked)}
                  />
                  <span />
                </label>
                <div className='text' translate='translate'>
                  I certify that this review is based on my own experience and
                  is my genuine opinion of this dive operator, and that I have
                  no personal or business relationship with this company, and
                  have not been offered any incentive or payment to write this
                  review. I understand that PADI Travel has a zero tolerance
                  policy on fake reviews.
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='form-bottom border-top'>
          <div className='btn-wrap'>
            <button
              className='btn-red big-btn pull-right'
              disabled={Object.keys(errors).length !== 0}
              onClick={onSubmit}
            >
              {isLoggedIn() ? (
                <FormattedMessage
                  id='submit_review'
                  defaultMessage='Submit review'
                />
              ) : (
                <FormattedMessage
                  id='login_and_submit'
                  defaultMessage='Sign in and submit review'
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
