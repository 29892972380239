import { Fragment, useState } from 'react'
import Typeahead from '../helpers/Typeahead'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import moment from 'moment'
import TravelDatepicker from '../helpers/TravelDatepicker'
import store from 'store2'

const msg = defineMessages({
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  viewLa: {
    id: 'view_la',
    defaultMessage: 'View liveaboard',
  },
  viewDr: {
    id: 'view_dr',
    defaultMessage: 'View resort',
  },
  readScubaGuied: {
    id: 'read_scuba_guide',
    defaultMessage: 'Read scuba guide',
  },
})

function HeaderSearchSection() {
  const intl = useIntl()
  const [selectedItem, getSuggestions] = useState()
  const [buttonText, setText] = useState(intl.formatMessage(msg.search))
  const [selectedDate, setDate] = useState()
  const [inputRef, setRef] = useState()
  const [loading, setLoading] = useState(false)

  function activateHeaderSearch(isMobile) {
    if(isMobile) {
      document.querySelector('body').classList.add('open-modal', 'header-search-active')
    } else {
      document.querySelector('body').classList.remove('open-modal', 'header-search-active')
    }
  }

  function getButtonText(item) {
    if(item.modelType === 'Location' || item.modelType === 'Geo-Location') {
      setText(intl.formatMessage(msg.search))
    } else if(item.modelType === 'Shop') {
      if(item.kind === 10) {
        setText(intl.formatMessage(msg.viewLa))
      } else if(item.kind === 20) {
        setText(intl.formatMessage(msg.viewDr))
      }
    } else if(item.modelType === 'Travel guides') {
      setText(intl.formatMessage(msg.readScubaGuied))
    }
    activateHeaderSearch(false)
  }

  function startSearch() {
    if(selectedItem) {
      const { item } = selectedItem
      if(item.modelType === 'Location' || item.modelType === 'Geo-Location') {
        if(selectedDate) {
          store('departure_date_custom', selectedDate)
          store('date', [selectedDate, moment(selectedDate).add(30, 'd').format('YYYY-MM-DD')])
          store.remove('departure_date')

          window.location.href = selectedItem.url
        } else {
          inputRef.click()
        }
      } else {
        window.location.href = selectedItem.url
      }
    }
  }

  return (
    <Fragment>
      <h3 className='search-title'><FormattedMessage id='search' defaultMessage='Search' /></h3>
      <div className='main-filters'>
        <div className='search-block'>
          <span onClick={() => activateHeaderSearch(false)} className='close-popup close-icon font-icons' />
          <label className='autosuggest-label'><FormattedMessage id='destionation_operator_name' defaultMessage='Destination / dive operator name' />
            <Typeahead activateHeaderSearch={activateHeaderSearch} selectCallback={(suggestion) => {
              getSuggestions(suggestion)
              getButtonText(suggestion.item)
            }}
              setLoading={setLoading}
            />
          </label>
        </div>
        {!selectedItem || (selectedItem.item.modelType === 'Location' || selectedItem.item.modelType === 'Geo-Location')
          ? (<div className='form-group form-group-datepicker'>
            <label className='mbn label-date'>
              <FormattedMessage id='earliest_date' defaultMessage='Earliest date' />
            </label>
            <TravelDatepicker className='gray-input datepicker-header-mobile' getInputRef={setRef} callback={(date) => setDate(date.dateFrom)} style={{ paddingLeft: '40px' }} />
          </div>) : ''}
        <button className='btn-red' onClick={startSearch}>{buttonText}</button>
      </div>
    </Fragment>
  )
}

export default HeaderSearchSection
