import { useMemo } from 'react'

export default function MealPlanIcon({ mealPlan }) {
  const className = useMemo(() => {
    let icon = 'font-icons '

    if(mealPlan >= 50) {
      icon += 'icon-allincl prm'
    } else if(mealPlan === 20) {
      icon += 'icon-breakfast prm'
    } else if(mealPlan > 20 && mealPlan < 50) {
      icon += 'icon-halfboard prm'
    }

    return icon
  })

  return <i className={className} />
}
