import { Component, createRef } from 'react'
import store from 'store2'
import intlTelInput from 'intl-tel-input'
import get from 'lodash/get'

export default class PhoneInput extends Component {
  constructor(props) {
    super(props)
    this.inputRef = createRef()
    this.state = {
      value: props.value,
    }
  }

  componentDidUpdate() {
    this.phoneField.setNumber(this.state.value || '')
    /* eslint-disable react/no-did-update-set-state */
    const phone = get(this.props, 'value')
    if(!this.state.value && phone) {
      this.setState({
        value: phone,
      })
    }
  }

  componentDidMount() {
    const container = this.inputRef.current.parentNode

    this.phoneField = intlTelInput(this.inputRef.current, {
      preferredCountries: ['us', 'gb', 'de', 'ch'],
      geoIpLookup: function(callback) {
        callback(store.session('diviac.countryCode') || 'US')
      },
      initialCountry: 'auto',
      utilsScript: `${window.staticPath}phone-utils/phone-utils.js`,
      dropdownContainer: container,
    })

    this.inputRef.current.addEventListener('countrychange', (e) => {
      this.changeHandler(e.target.value)
    })

    if(this.props.getPhoneInstance) {
      this.props.getPhoneInstance(this.phoneField)
    }
    this.phoneField.promise.then(() => {
      if(this.props.countryCode !== 'TW') {
        const twNodeList = document.querySelectorAll('.iti__tw') || []
        twNodeList.forEach((elem) => {
          elem.style.visibility = 'hidden'
        })
        this.inputRef.current.addEventListener("countrychange", () => {
          const currentData = this.phoneField.getSelectedCountryData()
          const twNodeList = document.querySelectorAll('.iti__tw') || []
          if(twNodeList.length === 0){
            const currentNodeList = document.querySelectorAll(`.iti__${currentData.iso2}`)
            currentNodeList.forEach((elem) => {
              elem.style.visibility = 'visible'
            })
          }else {
            twNodeList.forEach((elem) => {
              elem.style.visibility = 'hidden'
            })
          }
        });
        this.inputRef.current.addEventListener('open:countrydropdown', function() {
          const twNodeList = document.querySelectorAll('.iti__tw') || []
          twNodeList.forEach((elem) => {
            elem.style.visibility = 'hidden'
          })
        });
      }
    });
  }

  changeHandler = (value) => {
    this.setState({
      value,
    })

    this.props.onChange(this.phoneField.getNumber())
  }

  render() {
    return (
      <input
        ref={this.inputRef}
        value={this.state.value}
        placeholder={this.props.placeholder}
        onChange={(e) => this.changeHandler(e.target.value)}
      />
    )
  }
}
