import { Fragment, useState, useEffect } from 'react'

export default function InputNumber({
  isRequired,
  plusMinus,
  disabled,
  plusMinusSeparate,
  plusDisabled,
  name,
  value,
  onChange,
  minNumber,
  maxNumber,
  onValueChange,
  preventMaxValueRewrite,
  step = 1,
}) {
  const [val, changeValue] = useState(0)

  function updateValue(value, type) {
    if(isNaN(+value)) {
      value = val
    }

    if(minNumber > value) {
      value = minNumber
    }

    if(maxNumber < value && !preventMaxValueRewrite) {
      value = maxNumber
    }

    changeValue(value)
    if(typeof onChange === 'function') {
      onChange(value)
    }
    if(typeof onValueChange === 'function' && type) {
      onValueChange(value, type)
    }
  }

  useEffect(() => {
    updateValue(val)
  }, [minNumber, maxNumber])

  useEffect(() => {
    updateValue(value || 0)
  }, [value])

  return (
    <Fragment>
      {plusMinusSeparate ? (
        <span
          className={`plus-minus-btn minus ${
            val <= minNumber ? 'disabled' : ''
          }`}
          onClick={() => {
            updateValue(val - step, 'remove')
          }}
        >
          −
        </span>
      ) : (
        ''
      )}
      {disabled ? (
        <span className='value-container'>{val}</span>
      ) : (
        <input
          type='text'
          required={isRequired}
          name={name}
          value={val}
          onChange={(e) => updateValue(e.target.value, 'change')}
        />
      )}
      {plusMinusSeparate ? (
        <span
          className={`plus-minus-btn plus ${
            plusDisabled || value >= maxNumber ? 'disabled' : ''
          }`}
          onClick={() => {
            updateValue(val + step, 'add')
          }}
        >
          +
        </span>
      ) : (
        ''
      )}
      {plusMinus ? (
        <Fragment>
          <span
            className='plus'
            onClick={() => {
              updateValue(val + step, 'add')
            }}
          >
            +
          </span>
          <span
            className='minus'
            onClick={() => {
              updateValue(val - step, 'remove')
            }}
          >
            -
          </span>
        </Fragment>
      ) : (
        ''
      )}
    </Fragment>
  )
}
