import { useMemo } from 'react'
import PlaceholderResortPricing from './PlaceholderResortPricing'
import ResortPricingRowNoGuestsOrDate from './ResortPricingRowNoGuestsOrDate'
import ResortPricingRowUnavailable from './ResortPricingRowUnavailable'
import ResortPricingRowSoldOut from './ResortPricingRowSoldOut'
import ResortPricingRowOnRequest from './ResortPricingRowOnRequest'
import ResortPricingRow from './ResortPricingRow'
import ResortPricingRowNoRooms from './ResortPricingRowNoRooms'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const Placeholder = () =>
  [...new Array(4)].map((_, i) => {
    return <PlaceholderResortPricing key={i} />
  })
export default function PricingTableRow({
  isLoading,
  rooms,
  totalGuests,
  filters,
  areaFormat,
  openRoomPopup,
  handleDatesChange,
  handleGuestsChange,
  selectedGuests,
  showCalendar,
  triggerAvailabilityCalendar,
  isFixedPackage,
  id,
  title,
  onRequest,
  mealPlans,
  insurance,
  slug,
  countrySlug,
  setPopupData,
  isCheckout,
}) {
  if(isLoading) {
    return <Placeholder />
  }

  if(rooms.length < 1) {
    return <ResortPricingRowNoRooms />
  }

  const noFiltersSelected =
    !totalGuests || !filters.date_after || !filters.date_before
  const openCalendarOnLoad =
    !isFixedPackage && filters.date_after && filters.date_before

  const rows = useMemo(() => {
    return rooms.map((room) => {
      const errorMessageFromBackend = room.error && room.error.length
      const ratesAvailable = get(room, 'rates.rateIds.length', false)
      if(noFiltersSelected && !errorMessageFromBackend) {
        return (
          <ResortPricingRowNoGuestsOrDate
            handleGuestsChange={handleGuestsChange}
            room={room}
            students={selectedGuests.students}
            dateAfter={filters.date_after}
            areaFormat={areaFormat}
            handleDatesChange={handleDatesChange}
            noGuests={!totalGuests}
            openRoomPopup={openRoomPopup}
            key={room.id}
          />
        )
      } else if(room.isSoldOut) {
        return (
          <ResortPricingRowSoldOut
            room={room}
            openRoomPopup={openRoomPopup}
            triggerAvailabilityCalendar={triggerAvailabilityCalendar}
            availabilityCalendar={openCalendarOnLoad}
            isFixedPackage={isFixedPackage}
            key={room.id}
          />
        )
      } else if(!room.showRates && !ratesAvailable) {
        return (
          <ResortPricingRowUnavailable
            room={room}
            openRoomPopup={openRoomPopup}
            key={room.id}
            triggerAvailabilityCalendar={triggerAvailabilityCalendar}
            showCalendar={showCalendar}
            isFixedPackage={isFixedPackage}
            availabilityCalendar={openCalendarOnLoad}
          />
        )
      } else if(onRequest) {
        return (
          <ResortPricingRowOnRequest
            room={room}
            openRoomPopup={openRoomPopup}
            shopId={id}
            shopTitle={title}
          />
        )
      }
      return (
        <ResortPricingRow
          room={room}
          filters={filters}
          openRoomPopup={openRoomPopup}
          roomParams={selectedGuests}
          nights={filters.nights}
          key={room.id}
          areaFormat={areaFormat}
          mealPlans={mealPlans}
          insurance={insurance && insurance[0]}
          slug={slug}
          setPopupData={setPopupData}
          countrySlug={countrySlug}
          isFixedPackage={isFixedPackage}
          isCheckout={isCheckout}
        />
      )
    })
  }, [
    rooms,
    insurance,
    mealPlans,
    openCalendarOnLoad,
    noFiltersSelected,
    onRequest,
  ])

  return rows
}

PricingTableRow.propTypes = {
  isLoading: PropTypes.bool,
  rooms: PropTypes.array,
  totalGuests: PropTypes.number,
  filters: PropTypes.object,
  areaFormat: PropTypes.string,
  openRoomPopup: PropTypes.func,
  handleDatesChange: PropTypes.func,
  handleGuestsChange: PropTypes.func,
  selectedGuests: PropTypes.object,
  showCalendar: PropTypes.bool,
  triggerAvailabilityCalendar: PropTypes.func,
  isFixedPackage: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  onRequest: PropTypes.bool,
  mealPlans: PropTypes.array,
  insurance: PropTypes.array,
  slug: PropTypes.string,
  countrySlug: PropTypes.string,
  setPopupData: PropTypes.func,
  isCheckout: PropTypes.bool,
}
