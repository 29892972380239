exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".loading--2qR7k{align-items:center;display:flex;justify-content:center;height:100%;position:absolute;top:0;right:15px}.loading--2qR7k.mobile--2qQw4{right:50px}.loadingDot--1fvT8{-webkit-animation:dot--1vJyD 1s ease-in-out infinite;animation:dot--1vJyD 1s ease-in-out infinite;background-color:grey;display:inline-block;margin:4px;height:5px;width:5px;border-radius:4px}.loadingDot--1fvT8:nth-of-type(2){-webkit-animation-delay:.2s;animation-delay:.2s}.loadingDot--1fvT8:nth-of-type(3){-webkit-animation-delay:.3s;animation-delay:.3s}@-webkit-keyframes dot--1vJyD{0%{background-color:#a9a9a9;transform:scale(1)}50%{background-color:#868686;transform:scale(1.3)}to{background-color:#a9a9a9;transform:scale(1)}}@keyframes dot--1vJyD{0%{background-color:#a9a9a9;transform:scale(1)}50%{background-color:#868686;transform:scale(1.3)}to{background-color:#a9a9a9;transform:scale(1)}}", ""]);

// Exports
exports.locals = {
	"loading": "loading--2qR7k",
	"mobile": "mobile--2qQw4",
	"loadingDot": "loadingDot--1fvT8",
	"dot": "dot--1vJyD"
};