import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import './error-handler.sass'

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: undefined }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)

      this.setState({
        eventId,
      })
    })
  }

  render() {
    if(this.state.hasError) {
      return (
        <div className='technical-issue-page'>
          <h2>
            <FormattedMessage
              id='tech_problem'
              defaultMessage='Hmm, we`ve had a technical problem.'
            />
          </h2>
          <div>
            <p>
              <FormattedMessage
                id='tech_problem_fix'
                defaultMessage='Refreshing your page might fix the issue, but if that doesn`t work and you see this message again, please click "Submit crash report".'
              />
            </p>
            <div className='technical-issue-page__buttons'>
              <button
                className='btn-red'
                onClick={() => {
                  window.localStorage.clear()
                  return window.location.reload()
                }}
              >
                <FormattedMessage
                  id='refresh-page'
                  defaultMessage='Refresh page'
                />
              </button>

              <button
                className='btn-red-invert'
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                <FormattedMessage
                  id='submit_crash_report'
                  defaultMessage='Submit crash report'
                />
              </button>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
