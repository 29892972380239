import { convertCurrency } from './components/helpers/PriceConverter'
import { setGtmItemTitle } from './hooks/useResortInclusions'
import { roundPrice } from './utils'
import get from 'lodash/get'
import { getInclusionPrice } from './components/checkout-la/hooks/useGTM'

const GTM_TEXT = {
  '10': {
    shopKindVerbose: 'Liveaboards',
    itemListName: 'PADI Travel Liveaboards - Add Extras',
    padiCheckoutName: 'PADI Travel - Liveaboards',
  },
  '20': {
    shopKindVerbose: 'Dive Resort',
    itemListName: 'PADI Travel Dive Resorts - Add Extras',
    padiCheckoutName: 'PADI Travel - Dive Resorts',
  },
}

function trackEcommerce(event, ecommerceData, currencyCode) {
  if(!window.dataLayer) {
    return false
  }

  let data = { ecommerce: ecommerceData }

  if(event) {
    data.event = event
  }

  if(currencyCode) {
    data.currencyCode = currencyCode
  }

  return window.dataLayer.push(data)
}

function trackGA4Ecommerce(event, ecommerceData, padiCheckoutName) {
  if(!window.dataLayer) {
    return false
  }

  window.dataLayer.push({ ecommerce: null })

  let data = {
    event: event,
    ecommerce: ecommerceData,
  }

  if(padiCheckoutName) {
    data.padi_checkout_name = padiCheckoutName
  }

  return window.dataLayer.push(data)
}

function pushToDataLayer(data, event) {
  if(!window.dataLayer) {
    return false
  }

  if(event) {
    data.event = event
  }
  setTimeout(() => {
    window.dataLayer.push(data)
  }, 0)
}

function trackEvent(event) {
  if(!window.dataLayer) {
    return false
  }

  return window.dataLayer.push(event)
}

function calculateFloodlight(
  rate,
  shopCurrency,
  selectedCurrency,
  totalGuests,
) {
  if(rate.price) {
    // fix for LA;
    rate.compareRatePrice = rate.compareAtPrice
    rate.ratePrice = rate.price
  }
  let GTMFloodLight = {
    page_price: Math.round(
      convertCurrency(rate.ratePrice, shopCurrency, selectedCurrency) /
      totalGuests,
    ),
    page_price_sale: undefined,
    page_price_percent: undefined,
    page_price_currency: selectedCurrency,
  }
  if(rate.compareRatePrice > rate.ratePrice) {
    GTMFloodLight.page_price = Math.round(
      convertCurrency(rate.compareRatePrice, shopCurrency, selectedCurrency) /
      totalGuests,
    )
    GTMFloodLight.page_price_sale = Math.round(
      convertCurrency(rate.ratePrice, shopCurrency, selectedCurrency) /
      totalGuests,
    )
    GTMFloodLight.page_price_percent =
      (1 - (rate.compareRatePrice - rate.ratePrice) / rate.compareRatePrice) *
      100
  }
  return GTMFloodLight
}

function setBrandValue(diveCenter) {
  return `${diveCenter.title}|${
    diveCenter.affiliateId
      ? diveCenter.affiliateId
      : diveCenter.id + '-Individual'
  }`
}

function addRemoveFromCartTrack(
  type,
  quantity,
  item,
  shopKind,
) {
  if(!get(window, 'shop.currency')) {
    return null
  }

  const { shopKindVerbose, itemListName, padiCheckoutName } = GTM_TEXT[shopKind]
  const id = item.pricingId || item.extraId || item.id
  const value = roundPrice(
    convertCurrency(
      getInclusionPrice(item),
      window.shop.currency,
      'USD',
    ),
  )
  trackGA4Ecommerce(
    type === 'add' ? 'add_to_cart' : 'remove_from_cart',
    {
      currency: 'USD',
      value: Number(value),
      items: [
        {
          item_name: setGtmItemTitle(item),
          item_id: (item.divingInclusions ? `number_of_dives` : id).toString(),
          price: Number(roundPrice(
            convertCurrency(
              getInclusionPrice(item),
              window.shop.currency,
              'USD',
            ),
          )),
          item_brand: 'PADI',
          item_category: 'PADI Travel',
          item_category2: shopKindVerbose,
          item_category3: 'Extras',
          item_category4: '',
          item_category5: '',
          item_list_name: itemListName,
          quantity: 1,
        },
      ],
    },
    padiCheckoutName,
  )
}

export {
  trackEcommerce,
  pushToDataLayer,
  calculateFloodlight,
  setBrandValue,
  trackEvent,
  addRemoveFromCartTrack,
  trackGA4Ecommerce,
}
