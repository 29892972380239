/* eslint-disable react/jsx-indent-props, react/jsx-indent */
import { Fragment } from 'react'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import get from 'lodash/get'

const msg = defineMessages({
  included: {
    id: 'included',
    defaultMessage: 'Included',
  },
  notIncluded: {
    id: 'not_included',
    defaultMessage: 'Not included',
  },
})

const sectionTitle = function(type) {
  switch (type) {
    case 'mandatoryInAdvance':
      return (
        <FormattedMessage
          id='paid_with_booking'
          defaultMessage='To be paid with your booking'
        />
      )
    case 'mandatoryOnBoard':
      return (
        <FormattedMessage
          id='payable_on_board'
          defaultMessage='Payable on board'
        />
      )
    case 'mandatoryOnSite':
      return (
        <FormattedMessage
          id='payable_on_site'
          defaultMessage='Payable at the resort or dive center'
        />
      )
    case 'optionalInAdvance':
      return (
        <FormattedMessage
          id='book_and_pay_in_advance'
          defaultMessage='Bookable & payable in advance'
        />
      )
    case 'optionalBookableAdvancePaidOnBoard':
      return (
        <FormattedMessage
          id='book_advance_pay_on_board'
          defaultMessage='Bookable in advance but payable on board'
        />
      )
    case 'optionalBookableAdvancePaidOnSite':
      return (
        <FormattedMessage
          id='book_advance_pay_on_site'
          defaultMessage='Bookable in advance, but payable at the resort or dive center'
        />
      )
    case 'optionalOnBoard':
      return (
        <FormattedMessage
          id='payable_on_board'
          defaultMessage='Payable on board'
        />
      )
    case 'bookableAndPayableOnSite':
      return (
        <FormattedMessage
          id='bookable_on_site'
          defaultMessage='Bookable at the resort or dive center'
        />
      )
  }
}

const FirstLevelItem = ({
  item,
  included,
  payedInAdvance,
  startDate,
  shopType,
  currency,
}) => {
  const intl = useIntl()
  if(item.validFrom && item.validTo && startDate) {
    const utcStartDate =
      startDate.indexOf('T') !== -1
        ? moment(startDate).utc().format('YYYY-MM-DD')
        : startDate
    if(
      !moment(utcStartDate).isBetween(
        item.validFrom,
        item.validTo,
        undefined,
        '[]'
      )
    ) {
      return null
    }
  }

  const showExtraValue =
    !payedInAdvance && item.extraValue && !included && !item.priceGross
  const showPriceGross = item.priceGross && !included

  return (
    <li key={item.extraId}>
      {item.title}
      {!!showPriceGross && (
        <span>
          : {currency} {Math.round(item.priceGross)}&nbsp;(
          {selectType(
            shopType === 'liveaboard'
              ? 'LIVEABOARD_EXTRA_PAYED_PER'
              : 'RESORT_EXTRA_PAYED_PER',
            item.payedPer,
            true,
            shopType
          )}
          )
        </span>
      )}
      {!!showExtraValue && (
        <span>
          : {item.extraValue}&nbsp;(
          {selectType(
            shopType === 'liveaboard'
              ? 'LIVEABOARD_EXTRA_PAYED_PER'
              : 'RESORT_EXTRA_PAYED_PER',
            item.payedPer,
            true,
            shopType
          )}
          )
        </span>
      )}
      {!!item.fullBoardDescription && (
        <a
          className='link-color'
          data-tip={`<p>${intl.formatMessage(msg.included)}: ${
            item.fullBoardDescription.included
          }</p>
            ${
        intl.formatMessage(msg.notIncluded)
          ? `<p>${intl.formatMessage(msg.notIncluded)}: ${
            item.fullBoardDescription.notIncluded
          }</p>`
          : ''
        }
          `}
          data-html
        >
          (<FormattedMessage id='see_more' defaultMessage='see more' />)
        </a>
      )}
      {!!item.fullSetDescription && (
        <a
          className='link-color'
          data-tip={`<p>${intl.formatMessage(msg.included)}: ${
            item.fullSetDescription
          }</p>`}
          data-html
        >
          (<FormattedMessage id='see_more' defaultMessage='see more' />)
        </a>
      )}
    </li>
  )
}

function Inclusions({
  shopType = 'liveaboard',
  items,
  type,
  included,
  payedInAdvance,
  currency,
  startDate,
  insurance,
  room,
  duration,
  divesAmount,
  fixedPackage,
  diveType,
}) {
  const firstLevelItems = filter(items, { includedIn: null })
  const studentInclusions = get(room, 'studentInclusions', [])

  return (
    <Fragment>
      <ReactTooltip effect='solid' html />
      <ul className='circled-list'>
        {!!type && <li className='title'>{sectionTitle(type)}</li>}
        {room && duration ? (
          <li>
            <FormattedMessage
              id='for'
              values={{
                duration,
                roomTitle: room.title,
              }}
              defaultMessage='{roomTitle} for {duration, plural, one {# night} other {# nights}}'
            />
          </li>
        ) : (
          ''
        )}
        {!!divesAmount && (
          <li>
            {fixedPackage ? (
              <FormattedMessage
                id='pricing.dives_per_diver'
                values={{
                  divesAmount,
                }}
                defaultMessage='{ divesAmount } dives per diver'
              />
            ) : (
              <Fragment>
                <FormattedMessage
                  id='pricing.dives_per_diver_with_kind'
                  values={{
                    divesAmount,
                    diveType,
                  }}
                  defaultMessage='{ divesAmount } { diveType } dives per diver'
                />
                <p>
                  <FormattedMessage
                    id='pricing.dives_per_diver_with_kind_description'
                    defaultMessage='Your pre-booked dives are clearly marked as either boat or shore dives and take place at standard dive sites. Unless otherwise indicated, they don`t cover premium options like night dives or special dive sites and don`t include national park fees or equipment beyond weights, weight belt and tanks.'
                  />
                </p>
              </Fragment>
            )}
          </li>
        )}
        {!!insurance && (
          <li>
            <FormattedMessage
              id='DAN_insurance'
              defaultMessage='Free DAN dive accident insurance (if trip value > {price} per person)'
              values={{
                price: `${insurance.limitPriceCurrency} ${Math.round(
                  insurance.limitPrice
                )}`,
              }}
            />
          </li>
        )}
        {firstLevelItems && firstLevelItems.length ? (
          firstLevelItems.map((item) => (
            <FirstLevelItem
              key={item.extraId}
              item={item}
              included={included}
              payedInAdvance={payedInAdvance}
              startDate={startDate}
              currency={currency}
              shopType={shopType}
            />
          ))
        ) : (
          <li>
            <FormattedMessage id='none' defaultMessage='None' />
          </li>
        )}
        {studentInclusions.map((item) => {
          return <li key={item.title}>{item.title}</li>
        })}
      </ul>
    </Fragment>
  )
}

export default Inclusions

function selectType(type, id, lowercase, shopType) {
  const optionId = +id

  if(shopType === 'resort') {
    if(optionId === 51 || optionId === 53 || optionId === 56) {
      return <FormattedMessage id='per_person' defaultMessage='per person' />
    } else if(optionId === 52 || optionId === 54 || optionId === 57) {
      return <FormattedMessage id='per_vehicle' defaultMessage='per vehicle' />
    }
  }

  if(!window.info) {
    return ''
  }

  let index = findIndex(window.info.shop[type], function(val) {
    return val[0] === optionId
  })
  if(index !== -1) {
    let elem = window.info.shop[type][index][1]

    return (
      <FormattedMessage
        id='payed_per_option'
        values={{ perTypeOption: lowercase ? elem.toLowerCase() : elem }}
        defaultMessage='per { perTypeOption }'
      />
    )
  }
}
