import { Fragment } from 'react'
import DateFormat from './DateFormat'

export default function TwoDatesFormat({ dateFrom, dateTo }) {
  if(!dateFrom || !dateTo) {
    return ''
  }

  return (
    <Fragment>
      <DateFormat date={dateFrom} format={dateFrom.substr(0, 4) === dateTo.substr(0, 4) ? 'DD MMM' : 'DD MMM YYYY'} /> - <DateFormat date={dateTo} format='DD MMM YYYY' />
    </Fragment>
  )
}
