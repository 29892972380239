import { Fragment } from 'react'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import RatingStars from './RatingStars'
import { createUrl } from '../../localized-urls'

export default function ReviewLabel({ shopKind, padiRating, scubaTribe, tripAdvisor, numberOfReviews = 0, urlParams }) {
  const minimumReviewCount = (+shopKind === 10) ? 0 : 3
  const scubaTribeReviews = get(scubaTribe, 'reviewsNumber', 0)
  const tripAdvisorReviews = get(tripAdvisor, 'numReviews', 0)

  const template = () => {
    if(numberOfReviews > minimumReviewCount && scubaTribeReviews < 5 && tripAdvisorReviews <= 5) {
      if(urlParams) {
        return (
          <div className='rating-wrapper'>
            <a onClick={(e) => { e.stopPropagation() }} target='_blank' href={createUrl('la_page', urlParams) + '#reviews'}>
              <RatingStars rating={padiRating} />
              <span className='review-count'>
                <FormattedMessage id='reviews_plural'
                  values={{ qty: numberOfReviews }}
                  defaultMessage='{qty, plural, one {# review} other {# reviews}}' />
              </span>
            </a>
          </div>
        )
      } else {
        return (
          <div className='rating-wrapper'>
            <RatingStars rating={padiRating} />
            <span className='review-count'>
              <FormattedMessage id='reviews_plural'
                values={{ qty: numberOfReviews }}
                defaultMessage='{qty, plural, one {# review} other {# reviews}}' />
            </span>
          </div>
        )
      }
    } else if(tripAdvisorReviews <= 5) {
      return true
    } else if(tripAdvisorReviews > 5) {
      return (<div className='tripadvisor-rating-wrap rating-wrapper' >
        <img src={tripAdvisor.ratingImageUrl} />
        <span className='rating-count'>
          <a onClick={(e) => { e.stopPropagation() }} href={tripAdvisor.webUrl}>{tripAdvisor.numReviews || 0}&nbsp;
            <FormattedMessage id='reviews' defaultMessage='Reviews' /></a>
        </span>
      </div>)
    } else if(scubaTribeReviews > 5 && tripAdvisorReviews <= 5) {
      return (
        <div className='rating-wrapper'>
          <img className='pull-left' src={window.staticPath + 'travel_dist/images/scuba-tribe.png'} />
          <a href={scubaTribe.url} onClick={(e) => { e.stopPropagation() }} rel='nofollow' target='_blank'>
            <RatingStars rating={scubaTribe.rating} />
            <span className='review-count'>
              {scubaTribe.reviewsNumber} <FormattedMessage id='reviews' defaultMessage='Reviews' />
            </span>
          </a>
        </div>)
    }
  }
  return (
    <Fragment>
      { template() }
    </Fragment>
  )
}
