export const padiLearningLocalizedUrls = {
  en: {
    account: 'https://learning.padi.com/account',
    preferences: 'https://learning.padi.com/preferences/subscriber',
    certifications: 'https://learning.padi.com/certifications',
    dashboard: 'https://learning.padi.com/training/dashboard',
    logbook: 'https://learning.padi.com/logbook',
    settings: 'https://learning.padi.com/settings',
    mobileApps: 'https://www.padi.com/mobile-apps',
    membership: 'https://www.padi.com/dive/membership',
  },
  it: {
    account: 'https://learning.padi.com/account',
    preferences: 'https://learning.padi.com/preferences/subscriber',
    certifications: 'https://learning.padi.com/brevetti',
    dashboard: 'https://learning.padi.com/training/dashboard',
    logbook: 'https://learning.padi.com/logbook',
    settings: 'https://learning.padi.com/impostazioni',
    mobileApps: 'https://www.padi.com/it/app-mobili',
    membership: 'https://www.padi.com/it/dive/membership',
  },
  de: {
    account: 'https://learning.padi.com/account/de',
    preferences: 'https://learning.padi.com/preferences/subscriber/de',
    certifications: 'https://learning.padi.com/certifications',
    dashboard: 'https://learning.padi.com/training/dashboard/de',
    logbook: 'https://learning.padi.com/logbook/de',
    settings: 'https://learning.padi.com/settings/de',
    mobileApps: 'https://www.padi.com/de/mobile-apps?lang=de',
    membership: 'https://www.padi.com/de/dive/membership',
  },
  nl: {
    account: 'https://learning.padi.com/account',
    preferences: 'https://learning.padi.com/preferences/subscriber',
    certifications: 'https://learning.padi.com/certifications',
    dashboard: 'https://learning.padi.com/training/dashboard',
    logbook: 'https://learning.padi.com/logbook',
    settings: 'https://learning.padi.com/settings',
    mobileApps: 'https://www.padi.com/nl/mobile-apps?lang=nl',
    membership: 'https://www.padi.com/nl/dive/membership',
  },
  es: {
    account: 'https://learning.padi.com/cuenta',
    preferences: 'https://learning.padi.com/preferencias/suscriptor',
    certifications: 'https://learning.padi.com/certificaciones',
    dashboard: 'https://learning.padi.com/formación/panel-de-control',
    logbook: 'https://learning.padi.com/libro-de-inmersiones',
    settings: 'https://learning.padi.com/ajustes',
    mobileApps: 'https://www.padi.com/es/mobile-apps?lang=es',
    membership: 'https://www.padi.com/es/dive/membership',

  },
  fr: {
    account: 'https://learning.padi.com/account/fr',
    preferences: 'https://learning.padi.com/preferences/subscriber/fr',
    certifications: 'https://learning.padi.com/certifications',
    dashboard: 'https://learning.padi.com/training/dashboard',
    logbook: 'https://learning.padi.com/logbook/fr',
    settings: 'https://learning.padi.com/settings/fr',
    mobileApps: 'https://www.padi.com/fr/apps-mobile',
    membership: 'https://www.padi.com/fr/dive/membership',
  },
  'zh-hans': {
    account: 'https://learning.padi.com/account/cn',
    preferences: 'https://learning.padi.com/preferences/subscriber/cn',
    certifications: 'https://learning.padi.com/certifications/cn',
    dashboard: 'https://learning.padi.com/training/dashboard/cn',
    logbook: 'https://learning.padi.com/logbook/cn',
    settings: 'https://learning.padi.com/settings/cn',
    mobileApps: 'https://www.padi.com/zh-hant/mobile-apps?lang=zh-hans',
    membership: 'https://www.padi.com/zh-hans/dive/membership',
  },
  'zh-hant': {
    account: 'https://learning.padi.com/account/cn',
    preferences: 'https://learning.padi.com/preferences/subscriber/cn',
    certifications: 'https://learning.padi.com/certifications/cn',
    dashboard: 'https://learning.padi.com/training/dashboard/cn',
    logbook: 'https://learning.padi.com/logbook/cn',
    settings: 'https://learning.padi.com/settings/cn',
    mobileApps: 'https://www.padi.com/zh-hant/mobile-apps?lang=zh-hant',
    membership: 'https://www.padi.com/zh-hant/dive/membership',
  },
}
