import { FormattedMessage, useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import get from 'lodash/get'
import storage from 'store2'
import { copyTextToClipboard, generateAffiliateUrl } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../actions/main-actions'

const affiliatesDomain = process.env.AFFILIATE_DOMAIN
export default function AffiliateBar() {
  const user = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  if(!user) {
    return null
  }

  const longUrl = generateAffiliateUrl()
  const { affiliate, affiliates } = user

  const memberNumber = get(affiliate, 'memberNumber')

  const changeAffiliatesHandle = (memberNumber) => {
    const selectedAffiliate = affiliates.find(
      (el) => el.memberNumber === memberNumber
    )

    if(selectedAffiliate) {
      dispatch(setUser({ ...user, affiliate: selectedAffiliate }))
      storage('user', { ...user, affiliate: selectedAffiliate })
    }
  }

  const copyLinkHandler = () => {
    copyTextToClipboard(longUrl)
  }

  return (
    <div className='affiliate-bar'>
      <div className='affiliate-bar_column'>
        <ReactTooltip place='right' effect='solid' id='info' />
        <i
          data-for='info'
          data-tip={formatMessage({
            id: 'affiliate_bar_info',
            defaultMessage: `You're logged in as an affiliate, you're eligible to earn commissions for every booking you make while logged in.`,
          })}
          className='font-icons info-icon'
        />
        <span>
          <FormattedMessage
            defaultMessage='AFFILIATE (ID: {memberNumber})'
            id='affiliate_id'
            values={{ memberNumber }}
          />
          <i className='font-icons bottom-arrow-icon' />
          <div className='share-block affiliate-block'>
            {affiliates.map((el) => (
              <a
                key={el.id}
                onClick={() => changeAffiliatesHandle(el.memberNumber)}
              >
                {el?.businessInfo?.name}
              </a>
            ))}
          </div>
        </span>
      </div>
      <div className='affiliate-bar_column'>
        <a href={`${affiliatesDomain}/dashboard/?affiliate_id=${memberNumber}`}>
          <FormattedMessage defaultMessage='Go To Dashboard' />
        </a>
        <a
          href={`${affiliatesDomain}/group-trips/?group_booking_modal=true&affiliate_id=${memberNumber}`}
        >
          <FormattedMessage
            defaultMessage='Get Group Trip Quote'
            id='get_group_trip_quote'
          />
        </a>
        <a
          href={`${affiliatesDomain}/group-trips/?lead_modal=true&affiliate_id=${memberNumber}`}
        >
          <FormattedMessage defaultMessage='Send Lead' id='send_lead' />
        </a>
      </div>
      <div className='affiliate-bar_column'>
        {affiliates?.[0]?.settingInfo?.isTermsAccepted && (
          <span onClick={copyLinkHandler}>
            <i className='font-icons icon-link' />
            <FormattedMessage
              defaultMessage='Link to this page'
              id='link_to_this_page'
            />
          </span>
        )}
        {affiliates?.[0]?.settingInfo?.isTermsAccepted && (
          <span>
            <i className='font-icons icon-share' />
            <FormattedMessage
              defaultMessage=' Share this page'
              id='share_this_page'
            />
            <div className='share-block'>
              <a
                target='_blank'
                href={`https://www.facebook.com/sharer.php?u=${longUrl}`}
              >
                <i className='font-icons fb-icon' />
                Facebook
              </a>
              <a
                target='_blank'
                href={`https://twitter.com/intent/tweet?url=${longUrl}`}
              >
                <i className='font-icons twitter-icon' />
                Twitter
              </a>
              <a
                target='_blank'
                href={`https://www.pinterest.com/pin/create/button/?url=${longUrl}&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description=`}
              >
                <i className='font-icons icon-pinterest' />
                Pinterest
              </a>
              <a
                target='_blank'
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${longUrl}&title=PADI%20Travel&summary=PADI%20Travel&source=`}
              >
                <i className='font-icons icon-linkedin' />
                Linkedin
              </a>
            </div>
          </span>
        )}
      </div>
    </div>
  )
}
