import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import './password-input.scss'

export default function Input({
  isRequired,
  hasError,
  disabled,
  title,
  errorText,
  name,
  value,
  onChange,
}) {
  return (
    <label className={`${hasError ? 'error-field' : ''}`}>
      <strong>
        {typeof title === 'object' && title.id ? (
          <FormattedMessage {...title} />
        ) : (
          title
        )}
        {isRequired ? <span className='asterisk'>*</span> : ''}
      </strong>
      <input
        type='text'
        required
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      <div className='error-text'>{errorText}</div>
    </label>
  )
}

export function FinalFormInput({
  input,
  meta,
  placeholder,
  label,
  icon,
  disabled,
  hint,
  isRequired,
  onlyNumbers,
  className = '',
}) {
  const intl = useIntl()
  const error = meta.touched && (meta.error || meta.submitError)
  const onChange = ({ target }) => {
    let value = target.value
    if(onlyNumbers) {
      value = value.replace(/[^\.\+\s0-9]/g, '')
    }
    input.onChange(value)
  }
  const [passwordVisible, setPasswordVisible] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordVisible((visible) => !visible)
  }
  return (
    <div className={`input-wrapper ${error ? 'error-field' : ''} ${className}`}>
      {label && (
        <label>
          {typeof label === 'object' && label.id ? (
            <FormattedMessage {...label} />
          ) : (
            label
          )}
          {isRequired ? <span className='asterisk'>*</span> : ''}
        </label>
      )}
      <div className={icon ? 'input-container--icon' : 'relative'}>
        {icon || ''}
        <input
          className='input'
          {...input}
          type={
            input.type === 'password' && passwordVisible ? 'text' : input.type
          }
          onChange={onChange}
          placeholder={
            typeof placeholder === 'object'
              ? intl.formatMessage(placeholder)
              : placeholder
          }
          disabled={disabled}
        />
        {input.type === 'password' && (
          <i
            className={`font-icons ${
              passwordVisible ? 'icon-eye' : 'icon-eye-blocked'
            } password-button pointer`}
            onClick={togglePasswordVisibility}
          />
        )}
        {hint && (
          <div className='hint'>
            {typeof hint === 'object' && hint.id ? (
              <FormattedMessage {...hint} />
            ) : (
              hint
            )}
          </div>
        )}
        {error && meta.touched && <div className='error-text'>{error}</div>}
      </div>
    </div>
  )
}
