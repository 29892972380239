/* eslint-disable no-return-assign */
import FormRow from './FormRow'
import { Field, useForm } from 'react-final-form'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import {
  FormCheckbox,
  FormInputNumber,
  FormTextArea,
} from '../../forms/final-form-fields'

const msg = defineMessages({
  placeholderTextarea: {
    id: 'placeholder.textarea',
    defaultMessage:
      'Please tell us what you are looking for and when would be a good time to reach you?',
  },
})

function WhatAreYouLooking({ isDiving, kind, textAreaPlaceholder }) {
  const formState = useForm()
  const changeDivingHandler = () => {
    formState.change('isAccommodationAndDiving', false)
  }
  const changeAccommodationHandler = () => {
    formState.change('isDiving', false)
  }
  const intl = useIntl()
  return (
    <FormRow
      title={
        <FormattedMessage
          id='looking_for'
          defaultMessage='What are you looking for?'
        />
      }
    >
      {kind !== '60' && (
        <div className='checkbox-container'>
          <div className='checkbox-wrap'>
            <FormCheckbox
              name='isDiving'
              checkboxOnChange={changeDivingHandler}
              label={
                <strong>
                  <FormattedMessage
                    id='diving_only'
                    defaultMessage='Diving only'
                  />
                </strong>
              }
            />
          </div>
          <div className='checkbox-wrap'>
            <FormCheckbox
              name='isAccommodationAndDiving'
              checkboxOnChange={changeAccommodationHandler}
              label={
                <strong>
                  <FormattedMessage
                    id='room_and_diving'
                    defaultMessage='Room & diving'
                  />
                </strong>
              }
            />
          </div>
          <div className='checkbox-wrap'>
            <FormCheckbox
              name='isLiveaboardTrip'
              label={
                <strong>
                  <FormattedMessage
                    id='liveaboard_trip'
                    defaultMessage='Liveaboard trip'
                  />
                </strong>
              }
            />
          </div>
        </div>
      )}
      <div className='plus-minus-container'>
        <label className='plus-minus input-counter'>
          <strong>
            <FormattedMessage
              id='number_of_divers'
              defaultMessage='Number of divers'
            />
            <i className='asterisk'>*</i>
          </strong>
          <Field name={'numberDivers'}>
            {(props) => (
              <FormInputNumber
                {...props}
                isRequired
                plusMinusSeparate
                minNumber={0}
              />
            )}
          </Field>
        </label>
        <label className='plus-minus input-counter'>
          <strong>
            <FormattedMessage
              id='number_of_non_divers'
              defaultMessage='Number of non-divers'
            />
          </strong>
          <Field name={'numberGuests'}>
            {(props) => (
              <FormInputNumber {...props} plusMinusSeparate minNumber={0} />
            )}
          </Field>
        </label>
      </div>
      {/* { !isLiveaboardTrip ? (
      (<div>
        <label className='plus-minus'>
          <strong>
            <FormattedMessage id='number_of_dives_per_diver_per_trip' defaultMessage='Number of dives / per diver / for the trip' />
          </strong>
          <InputNumber name='numberDivesPerDiver' minNumber={0} plusMinus onChange={(val) => onChange('number_dives_per_diver', val)} isRequired value={numberDivesPerDiver} />
        </label>
      </div>)
    ) : '' } */}
      {!isDiving ? (
        <div>
          <label className='plus-minus input-counter'>
            <strong>
              <FormattedMessage
                id='number_of_rooms_cabins'
                defaultMessage='Number of rooms/cabins'
              />
            </strong>
            <Field name={'numberRooms'}>
              {(props) => (
                <FormInputNumber {...props} plusMinusSeparate minNumber={0} />
              )}
            </Field>
          </label>
        </div>
      ) : (
        ''
      )}
      <div>
        <FormTextArea
          name='description'
          placeholder={
            !textAreaPlaceholder
              ? intl.formatMessage(msg.placeholderTextarea)
              : intl.formatMessage(textAreaPlaceholder)
          }
        />
      </div>
    </FormRow>
  )
}

export default WhatAreYouLooking
