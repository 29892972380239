import { FormattedMessage } from 'react-intl'
import { isValidEmail } from '../../../utils'
export const contactUsValidation = (
  {
    firstName,
    lastName,
    email,
    phone,
    selectType,
    firstDay,
    lastDay,
    numberOfDivers,
    orderReference,
  },
  phoneInstance
) => {
  const validateRequiredMsg = (
    <FormattedMessage
      id='required_field'
      defaultMessage='This field is required!'
    />
  )

  const errors = {}

  if(!firstName) {
    errors.firstName = validateRequiredMsg
  }

  if(!lastName) {
    errors.lastName = validateRequiredMsg
  }

  if(!email) {
    errors.email = validateRequiredMsg
  } else if(!isValidEmail(email)) {
    errors.email = (
      <FormattedMessage
        id='error-email-length'
        defaultMessage='Invalid E-Mail'
      />
    )
  }

  if(!phone) {
    errors.phone = validateRequiredMsg
  } else if(phoneInstance && !phoneInstance.isValidNumber()) {
    errors.phone = (
      <FormattedMessage
        id='error_phone'
        defaultMessage='Invalid Phone number'
      />
    )
  }

  if(selectType === '0' || selectType === '10') {
    if(!firstDay) {
      errors.firstDay = validateRequiredMsg
    }
    if(!lastDay) {
      errors.lastDay = validateRequiredMsg
    }
    if(!numberOfDivers) {
      errors.numberOfDivers = validateRequiredMsg
    }
  } else if(selectType === '50') {
    if(!orderReference) {
      errors.orderReference = validateRequiredMsg
    }
  }
  return errors
}
