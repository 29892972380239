import useCurrentShop from './useCurrentShop'
import { roundPrice } from '../../../utils'
import { convertCurrency } from '../../helpers'
import { getBelongsToId } from '../../account/booking-details/helpers'

export const getInclusionPrice = (inclusion) => {
  if(inclusion.isIncluded) {
    return 0
  }
  if(!inclusion.isIncluded && !inclusion.payedInAdvance && !inclusion.divingInclusions) {
    // analytics ask to set 0 for inclusions that payed on site
    // if it's not included and not payed in advance, it's payed on site
    return 0
  }
  return inclusion.priceForOne || inclusion.priceGross || inclusion.price
}

export default function useGTM() {
  const { shopInfo } = useCurrentShop()

  const setGtmDataForInclusions = (selectedInclusions) => {
    let GA4Products = []
    let inclusionsValue = 0
    selectedInclusions.forEach((inc) => {
      const title = setGtmItemTitle(inc)
      inclusionsValue += inc.payedInAdvance ? inc.price : 0

      GA4Products.push({
        item_name: title,
        item_id: inc.extraId || inc.id || shopInfo.id,
        price: Number(
          roundPrice(
            convertCurrency(
              getInclusionPrice(inc),
              shopInfo.currency,
              'USD',
            ),
          ),
        ),
        item_brand: 'PADI',
        item_category1: 'PADI Travel',
        item_category2: 'Liveaboard',
        item_category3: 'Extras',
        item_list_name: 'PADI Travel Liveaboard - Add Extras',
        quantity: inc.quantity,
      })
    })
    return {
      GA4Products,
      inclusionsValue,
    }
  }

  function setGtmItemTitle(val) {
    let title = val.title
    const belongsTo = parseInt(val.belongsTo, 10)

    if(belongsTo === getBelongsToId('Rental Equipment')) {
      title += ' Hire'
    } else if(belongsTo === getBelongsToId('Sustainability')) {
      title = 'Carbon Offset - SeaGrass Grow Program'
    }

    return title
  }

  return {
    setGtmDataForInclusions,
  }
}
