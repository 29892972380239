export default function RatingStars({ rating, maxStars = 5, className = 'font-stars' }) {
  const STARS = [...new Array(maxStars)]

  return (
    <div className={className}>
      {
        STARS.map((_, index) => {
          return (<i key={index} className={index < rating ? 'active' : ''}>&#9733;</i>)
        })
      }
    </div>
  )
}
