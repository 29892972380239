import { FormattedMessage } from 'react-intl'
import { useMemo, Fragment } from 'react'
import { createUrl } from '../../../localized-urls'
import GuestPicker from '../../forms/GuestPicker'
import TravelDatepicker from '../../helpers/TravelDatepicker'

export default function PricingTableHeader({
  countrySlug,
  handleGuestsChange,
  handleDatesChange,
  countryTitleIn,
  title,
  isFixedPackage,
  availablePackageKinds,
  hasStudentCourses,
  filters,
  selectedGuests,
  minimalNumberOfNights,
  id,
  slug,
  getPrices,
  isCheckout,
}) {
  const noStudentsPackages = useMemo(() => {
    if(isFixedPackage) {
      // 40 - kind for students
      if(availablePackageKinds.includes(40)) {
        return false
      }
    } else {
      if(hasStudentCourses) {
        return false
      }
    }

    return true
  }, [isFixedPackage, availablePackageKinds, hasStudentCourses])

  return (
    <div className='resort-pricing__header'>
      <div className='header-row'>
        <div className='back-section'>
          {!isCheckout && (
            <Fragment>
              <a
                className='font-icons left-arrow-icon'
                href={createUrl('search_dr', { slug: countrySlug })}
              />
              <span className='title'>{title}</span>
              <div className='details'>
                <FormattedMessage
                  id='see_more_in_location'
                  values={{
                    location: countryTitleIn,
                  }}
                  defaultMessage='See more {location}'
                />
              </div>
            </Fragment>
          )}
        </div>
        <div className='filters-wrapper'>
          <div className='field-wrapper'>
            <GuestPicker
              noStudentsPackages={noStudentsPackages}
              onSelect={handleGuestsChange}
              showIcon
              selectedGuests={selectedGuests}
            />
          </div>
          <div className='field-wrapper'>
            <i className='font-icons calendar-icon' />
            <TravelDatepicker
              callback={handleDatesChange}
              shopId={id}
              shopSlug={slug}
              showLegend
              className='travel-datepicker'
              isFixedPackage={isFixedPackage}
              guestsSplit={filters.guests_split}
              minDuration={minimalNumberOfNights}
              maxDuration={21}
              range
              datesInOneField
              minDurationForStudents={5}
              validateStudentsPackage
              students={selectedGuests.students}
              dateFrom={filters.date_after}
              dateTo={filters.date_before}
              updateDuration
            />
          </div>
          <button className='search-btn' onClick={getPrices}>
            <i className='font-icons find-icon' />
          </button>
        </div>
      </div>
      <div className='header-row'>
        <div className='resort-pricing__rooms header-title'>
          <FormattedMessage id='rooms' defaultMessage='Rooms' />
        </div>
        <div className='resort-pricing__whats-included header-title'>
          <FormattedMessage
            id='whats_included'
            defaultMessage='What`s included'
          />
        </div>
        <div className='resort-pricing__meal-plan header-title'>
          <FormattedMessage id='meal_plan' defaultMessage='Meal plan' />
        </div>
        <div className='resort-pricing__price header-title'>
          <FormattedMessage id='today_price' defaultMessage='Today’s price' />
        </div>
      </div>
    </div>
  )
}
