import Modal from 'react-modal'
import { getTextFromInfo } from '../../utils'
import { MealPlanIcon } from '../resort/pricing/partials'

export default function MealPlanPopup({
  isOpen,
  closeModal,
  description,
  mealPlan,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className='Modal react-popup form-popup'
      overlayClassName='Overlay'
      contentLabel='Booking enquire popup'
    >
      <span className='close-btn' onClick={closeModal} />
      <h2>
        <span>{getTextFromInfo(mealPlan, 'shop', 'RESORT_MEAL_PLANS')}</span>
        &nbsp;
        <MealPlanIcon mealPlan={mealPlan} />
      </h2>
      <div
        className='wysiwyg'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Modal>
  )
}
