import { Component, Fragment, createRef } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import moment from 'moment'
import { APP_LANGUAGE } from '../../localized-urls'
import PriceConverter from '../helpers/PriceConverter'
import API from '../../api'
import get from 'lodash/get'
import each from 'lodash/each'
import groupBy from 'lodash/groupBy'
import memoize from 'lodash/memoize'
import DateFormat from '../helpers/DateFormat'
import { getRelativePos } from '../../utils'

const msg = defineMessages({
  finalConfirmation: {
    id: 'finalConfirmation',
    defaultMessage: 'Subject to final confirmation by the resort',
  },
})

const CELL_WIDTH = 30
const today = moment().format('YYYY-MM-DD')
const ROW_HEIGHT = 35

export const isDateInRange = function(currentDate, dateFrom, dateTo) {
  let current = moment(currentDate)
  let from = moment(dateFrom).format('YYYY-MM-DD')
  let to = moment(dateTo).format('YYYY-MM-DD')
  return current.isBetween(from, to, null, '[)')
}

class AvailabilityCalendar extends Component {
  constructor(props) {
    super(props)
    this.rowRef = createRef()
    this.selectedRangeRef = createRef()
    this.firstMainRowRef = createRef()

    this.state = {
      monthes: [],
      disabledLeftArrow: today === this.props.dateFrom,
      loading: true,
    }
  }

  componentDidMount() {
    this.updateCalendar()

    window.onresize = this.updateWindowPosition
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidUpdate(prevProps) {
    if(
      prevProps.dateFrom !== this.props.dateFrom ||
      prevProps.dateTo !== this.props.dateTo ||
      JSON.stringify(prevProps.guestsSplit) !==
        JSON.stringify(this.props.guestsSplit)
    ) {
      this.updateCalendar()
      this.handleDateChange()
    }
  }

  updateWindowPosition = () => {
    const firstRow = this.firstMainRowRef.current
    if(!firstRow) {
      return false
    }
    const firstActiveCell = firstRow.getElementsByClassName('active')[0]
    const roomTitle = document.getElementsByClassName('room-title')[0]

    const styles = {
      border: this.props.duration > this.state.maximumDays ? 'none' : '',
      width: this.props.duration * CELL_WIDTH + 7 + 'px',
      height: ROW_HEIGHT * this.state.roomsNumber + 'px',
      top: firstRow ? getRelativePos(firstRow).top - 5 + 'px' : '',
      left: firstActiveCell
        ? getRelativePos(firstActiveCell).left +
          roomTitle.offsetWidth -
          5 +
          'px'
        : '',
    }

    Object.assign(this.selectedRangeRef.current.style, styles)
  }

  updateCalendar = () => {
    this.setState({
      loading: true,
    })

    if(window.innerWidth < 768) {
      return false
    }

    const { duration, dateFrom, dateTo } = this.props

    let maximumDays =
      Math.floor((this.rowRef.current.offsetWidth - 10) / CELL_WIDTH) - 1

    if(maximumDays % 2 !== 0) {
      maximumDays -= 1
    }

    let startDate = Math.round((maximumDays - duration) / 2)
    let endDate = maximumDays - startDate - duration
    let filter = {
      date_after: moment(dateFrom)
        .subtract(startDate, 'days')
        .format('YYYY-MM-DD'),
      date_before: moment(dateTo).add(endDate, 'days').format('YYYY-MM-DD'),
      nights: duration,
      dateStart: dateFrom,
      guests_split: this.props.guestsSplit,
    }

    API(`booking/resort/${this.props.shopSlug}/rooms/availability/`)
      .get(filter)
      .then((data) => {
        let roomsNumber = data.length
        each(data, function(val) {
          val.totalPrice = val.priceForDives

          each(val.details, function(v) {
            if(isDateInRange(v.date, dateFrom, dateTo)) {
              v.selected = true
              val.totalPrice += +v.minPrice

              if(!v.isAvailable) {
                val.notAvailable = true
              }
            }
          })
        })
        let availabilityCalendar = data
        let monthes = []
        const details = get(availabilityCalendar, '[0].details', [])
        let groupedMonthes = groupBy(details, function(result) {
          return moment(result.date).startOf('month')
        })

        each(
          groupedMonthes,
          memoize(function(val, key) {
            each(val, function(v, k) {
              monthes.push(
                k === 0
                  ? moment(key).locale(APP_LANGUAGE).format('MMM YYYY')
                  : ''
              )
            })
          })
        )

        this.setState(
          {
            loading: false,
            monthes,
            availabilityCalendar,
            roomsNumber,
            maximumDays,
          },
          this.updateWindowPosition
        )
      })
  }

  handleDateChange(type) {
    this.setState(
      {
        disabledLeftArrow: today === this.props.dateFrom,
      },
      () => {
        if(type === 'subtract' && this.state.disabledLeftArrow) {
          return false
        }

        if(type && typeof this.props.updateDate === 'function') {
          this.props.updateDate(type)
        }
      }
    )
  }

  render() {
    const { intl, triggerAvailabilityCalendar, showCalendar } = this.props

    const { availabilityCalendar, loading, disabledLeftArrow } = this.state

    const details = get(availabilityCalendar, '[0].details', [])

    return (
      <div
        id='availiability-calendar-wrapper'
        className={`availiability-calendar-wrapper ${
          showCalendar ? 'expanded' : ''
        }`}
      >
        <div className={`dr-availability-calendar ${loading ? 'loading' : ''}`}>
          <div id='availability-calendar'>
            <div className='dr-availability-calendar-row'>
              <div className='room-title main-section-title'>
                <FormattedMessage
                  id='room-availability.title'
                  defaultMessage='Room availability calendar'
                />
              </div>
              <div className='calendar-row' ref={this.rowRef}>
                {this.state.monthes.map((month, index) => {
                  return (
                    <div
                      key={month || index}
                      className={`calendar-row-date-text ${
                        month ? 'first-elem' : ''
                      }`}
                    >
                      <span>{month}</span>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='dr-availability-calendar-row'>
              <div className='room-title' />
              <div className='calendar-row mbm'>
                {details.map((d) => {
                  return (
                    <div
                      key={'top' + d.date}
                      className='calendar-row-date-text'
                    >
                      {moment(d.date).locale(APP_LANGUAGE).format('D')}
                    </div>
                  )
                })}
              </div>
            </div>
            {availabilityCalendar &&
              availabilityCalendar.map((data, index) => {
                return (
                  <div
                    className='dr-availability-calendar-row main-graph'
                    key={data.roomCategoryId}
                    ref={index === 0 ? this.firstMainRowRef : false}
                  >
                    <a
                      className='room-title'
                      smooth-scroll='smooth-scroll'
                      href={`#room_${data.roomCategoryId}`}
                    >
                      {data.notAvailable ? (
                        <Fragment>
                          <span className='title sold-out'>
                            {data.roomTitle}
                          </span>
                          <span className='room-price sold-out'>
                            <FormattedMessage
                              id='sold_out'
                              defaultMessage='Sold out'
                            />
                          </span>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className='title'>{data.roomTitle}</span>
                          <span className='room-price'>
                            <PriceConverter
                              amount={data.totalPrice}
                              currency={this.props.currency}
                            />
                          </span>
                        </Fragment>
                      )}
                    </a>
                    <div className='calendar-row'>
                      {data.details.map((option) => {
                        return (
                          <div
                            key={data.roomCategoryId + option.date}
                            className={`calendar-row-date ${
                              option.isAvailable && option.minPrice
                                ? 'open'
                                : ''
                            } ${option.selected ? 'active' : ''} ${
                              !option.isAvailable && !option.minPrice
                                ? 'no-price'
                                : ''
                            }`}
                          />
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </div>
          <span
            className={`font-icons left-arrow-icon ${
              disabledLeftArrow ? 'disabled' : ''
            }`}
            onClick={() => this.handleDateChange('subtract')}
          />
          <span
            className='font-icons right-arrow-icon'
            onClick={() => this.handleDateChange('add')}
          />

          <div className='legend'>
            <div className='legend-available'>
              <FormattedMessage
                id='room_available'
                defaultMessage='Room available'
              />
              <i
                className='font-icons info-icon pointer'
                data-tip={intl.formatMessage(msg.finalConfirmation)}
              />
            </div>
            <div className='legend-sold-out'>
              <FormattedMessage
                id='room_sold-out'
                defaultMessage='Room sold-out'
              />
            </div>
            <div className='legend-no-info'>
              <FormattedMessage
                id='room_no-prices'
                defaultMessage='No prices & availability'
              />
            </div>
          </div>

          <div className='selected-range' ref={this.selectedRangeRef}>
            <span className='selected-range-text'>
              <DateFormat date={this.props.dateFrom} format='DD MMM' /> -{' '}
              <DateFormat date={this.props.dateTo} format='DD MMM' />
            </span>
          </div>
        </div>
        <span
          className={`more ${showCalendar ? 'active' : ''}`}
          onClick={() => triggerAvailabilityCalendar(!showCalendar)}
        >
          {showCalendar ? (
            <FormattedMessage
              id='hide_calendar'
              defaultMessage='Hide calendar'
            />
          ) : (
            <FormattedMessage
              id='show_calendar'
              defaultMessage='Show calendar'
            />
          )}
        </span>
      </div>
    )
  }
}

AvailabilityCalendar.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(AvailabilityCalendar)
