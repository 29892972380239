import styles from './LoadingIndicator.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'

export default function LoadingIndicator({ isMobile, customStyles }) {
  return (
    <div className={cx(styles.loading, customStyles, { [styles.mobile]: isMobile })}>
      <span className={styles.loadingDot} />
      <span className={styles.loadingDot} />
      <span className={styles.loadingDot} />
    </div>
  )
}

LoadingIndicator.propTypes = {
  isMobile: PropTypes.bool,
  customStyles: PropTypes.string,
}
