import { FormattedMessage } from 'react-intl'
import PriceConverter from '../../helpers/PriceConverter'
import { isLastMinuteBooking } from '../../../utils'
import ErrorCalendar from '../../helpers/ErrorCalendar'
import { RoomInfo } from './partials'

export default function ResortPricingRowNoGuestsOrDate({
  room,
  openRoomPopup,
  noGuests,
  dateAfter,
  areaFormat,
}) {
  return (
    <div className='resort-pricing-row'>
      <RoomInfo
        room={room}
        openRoomPopup={openRoomPopup}
        areaFormat={areaFormat}
        showDetailedDescription
        showPicture
      />
      <div className='resort-pricing__whats-included' />
      <div className='resort-pricing__meal-plan' />
      <div className='resort-pricing__price center-align'>
        <div className='pricing-wrapper'>
          <div className='price-per-guest-wrapper no-filters-selected'>
            <p className='price-per-guest__text mbn'>
              <FormattedMessage
                id='packages_from'
                defaultMessage='Packages from'
              />
            </p>
            <strong className='price-per-guest'>
              <PriceConverter amount={room.minPrice} currency={room.currency} />
            </strong>
            <p className='price-per-guest__text'>
              <FormattedMessage
                id='per_diver_for_7nights'
                defaultMessage='Per diver for 7 nights'
              />
            </p>
          </div>
          {noGuests ? (
            <ErrorCalendar
              className='fake-input with-arrow'
              selector='.rooms-and-guest-selector'
              icon='font-icons people-icon'
              defaultMessage={
                <FormattedMessage
                  id='select_guest_package'
                  defaultMessage='Select guest package'
                />
              }
            />
          ) : (
            <ErrorCalendar
              className='fake-input'
              selector='.travel-datepicker'
              icon='font-icons calendar-icon'
              defaultMessage={
                <FormattedMessage
                  id='select_date'
                  defaultMessage='Select date'
                />
              }
            />
          )}
        </div>
        {!isLastMinuteBooking(dateAfter) && (
          <p className='benefit'>
            <i className='font-icons ok-icon-new' />
            <FormattedMessage
              id='48h_free_cancellation'
              defaultMessage='48h free cancellation period'
            />
          </p>
        )}
        <p className='price-explanation'>
          <FormattedMessage
            id='prices_included_taxes'
            defaultMessage='Prices include taxes & service fees'
          />
        </p>
      </div>
    </div>
  )
}
