import { useMemo, useState } from 'react'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import { getTextFromInfo } from '../../../utils'
import ResortPricingRowUnavailable from './ResortPricingRowUnavailable'
import { RoomInclusions, RoomInfo, RoomMealPlan, RoomPrice } from './partials'

function ResortPricingRow({
  room,
  isFixedPackage,
  slug,
  mealPlans,
  roomParams,
  insurance,
  openRoomPopup,
  areaFormat,
  filters,
  duration,
  setPopupData,
  countrySlug,
  isCheckout,
}) {
  const [mealPlan, setMealPlan] = useState(
    get(room, 'rates[0].mealPlan', false)
  )
  const pricingObject = useMemo(
    () => room.rates[findIndex(room.rates, { mealPlan })],
    [room.rates, mealPlan]
  )
  const totalGuests =
    roomParams.divers + roomParams.students + roomParams.nonDivers
  const diveType = getTextFromInfo(
    room.diveType,
    'shop',
    'DIVE_TYPE'
  ).toLowerCase()

  if(!pricingObject) {
    return (
      <ResortPricingRowUnavailable
        room={room}
        openRoomPopup={openRoomPopup}
        key={room.id}
      />
    )
  }

  return (
    <div className='resort-pricing-row' id={`room_${room.id}`}>
      <RoomInfo
        room={room}
        openRoomPopup={openRoomPopup}
        areaFormat={areaFormat}
        showDetailedDescription
        showPicture
      />
      <RoomInclusions
        room={room}
        roomParams={roomParams}
        slug={slug}
        mealPlanDescription={mealPlans[mealPlan]}
        filters={filters}
        isFixedPackage={isFixedPackage}
        diveType={diveType}
        setPopupData={setPopupData}
        duration={duration}
        insurance={insurance}
      />
      <RoomMealPlan
        room={room}
        mealPlans={mealPlans}
        selectedMealPlan={mealPlan}
        setMealPlan={setMealPlan}
        setPopupData={setPopupData}
        totalGuests={totalGuests}
        pricingObject={pricingObject}
      />
      <RoomPrice
        pricingObject={pricingObject}
        roomParams={roomParams}
        totalGuests={totalGuests}
        isFixedPackage={isFixedPackage}
        room={room}
        slug={slug}
        countrySlug={countrySlug}
        filters={filters}
        isCheckout={isCheckout}
      />
    </div>
  )
}

export default ResortPricingRow
