import Select from 'react-select'
import { FormattedMessage } from 'react-intl'
import find from 'lodash/find'

export const autosuggestStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 2,
    border: '1px solid #dde3e6',
    backgroundColor: '#f9f9f9',
    height: '38px',
  }),
  valueContainer: (styles) => ({
    ...styles,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? '#f23d4e' : null,
      color: state.isFocused ? '#fff' : null,
      '&:active': {
        backgroundColor: '#ce4028',
        color: '#fff',
      },
    }
  },
}

const DropdownField = function({
  isRequired,
  title,
  errorText,
  name,
  value,
  onChange,
  options = [],
  optionLabelKey = 'title',
  optionValueKey = 'id',
  placeholder,
}) {
  const handleChange = (value) => {
    onChange(name, value[optionValueKey], value)
  }
  const fullValue = find(options, (val) => val[optionValueKey] === value)
  return (
    <div
      className={`form-item-row dropdown-wrapper ${
        errorText ? 'error-field' : ''
      }`}
    >
      <label>
        <strong>
          {typeof title === 'object' && title.id ? (
            <FormattedMessage {...title} />
          ) : (
            title
          )}
          {isRequired ? <span className='asterisk'>*</span> : ''}
        </strong>
      </label>
      <div className='form-item-row__container'>
        <Select
          onChange={handleChange}
          value={fullValue}
          options={options}
          styles={autosuggestStyles}
          placeholder={
            typeof placeholder === 'object' && placeholder.id ? (
              <FormattedMessage {...placeholder} />
            ) : (
              placeholder
            )
          }
          isSearchable
          getOptionLabel={(option) => option[optionLabelKey]}
          getOptionValue={(option) => option[optionValueKey]}
          isClearable
          className='select-container'
          classNamePrefix='select-container'
        />
        <div className='error-text'>
          {typeof errorText === 'object' && errorText.id ? (
            <FormattedMessage {...errorText} />
          ) : (
            errorText
          )}
        </div>
      </div>
    </div>
  )
}

export default DropdownField
