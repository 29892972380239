import { createAction } from 'redux-actions'

const SHOW_MAIN_LOADER = 'SHOW_MAIN_LOADER'
const showLoader = createAction(SHOW_MAIN_LOADER)
const DEFAULT_LOADER_STATE = false

function pageLoader(state = DEFAULT_LOADER_STATE, { type, payload }) {
  switch (type) {
    case SHOW_MAIN_LOADER:
      return payload
    default:
      return state
  }
}

const SET_CURRENCY = 'SET_CURRENCY'
const setCurrency = createAction(SET_CURRENCY)

function selectedCurrency(state = window.currency, { type, payload }) {
  switch (type) {
    case SET_CURRENCY:
      return payload
    default:
      return state
  }
}

const SHOW_ITINERARY_POPUP = 'SHOW_ITINERARY_POPUP'
const showItineraryPopup = createAction(SHOW_ITINERARY_POPUP)

function itineraryPopupOpen(state = false, { type, payload }) {
  switch (type) {
    case SHOW_ITINERARY_POPUP:
      return payload
    default:
      return state
  }
}

const ITINERARY_POPUP_DATA = 'ITINERARY_POPUP_DATA'
const setItineraryData = createAction(ITINERARY_POPUP_DATA)

function itineraryPopupData(state = {}, { type, payload }) {
  switch (type) {
    case ITINERARY_POPUP_DATA:
      return payload
    default:
      return state
  }
}

const SHOW_RESORT_INCLUSIONS_POPUP = 'SHOW_RESORT_INCLUSIONS_POPUP'
const showResortInclusions = createAction(SHOW_RESORT_INCLUSIONS_POPUP)

function resortInclusionsOpen(state = false, { type, payload }) {
  switch (type) {
    case SHOW_RESORT_INCLUSIONS_POPUP:
      return payload
    default:
      return state
  }
}

const DR_INCLUSION_POPUP_DATA = 'DR_INCLUSION_POPUP_DATA'
const setResortInclusionsData = createAction(DR_INCLUSION_POPUP_DATA)

function resortInclusionPopupData(state = {}, { type, payload }) {
  switch (type) {
    case DR_INCLUSION_POPUP_DATA:
      return payload
    default:
      return state
  }
}

const SET_SHOP_CURRENCY = 'SET_SHOP_CURRENCY'
const setShopCurrency = createAction(SET_SHOP_CURRENCY)

function shopCurrency(state = window.shopCurrency || 'USD', { type, payload }) {
  switch (type) {
    case SET_SHOP_CURRENCY:
      return payload
    default:
      return state
  }
}

const SET_USER = 'SET_USER'
const setUser = createAction(SET_USER)

function user(state = null, { type, payload }) {
  switch (type) {
    case SET_USER:
      return payload
    default:
      return state
  }
}

export {
  setCurrency,
  selectedCurrency,
  showItineraryPopup,
  itineraryPopupOpen,
  itineraryPopupData,
  showLoader,
  pageLoader,
  setItineraryData,
  setResortInclusionsData,
  resortInclusionPopupData,
  showResortInclusions,
  resortInclusionsOpen,
  setShopCurrency,
  shopCurrency,
  user,
  setUser,
}
