import { Component, Fragment, createRef } from 'react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import clone from 'lodash/clone'
import store from 'store2'
import InputNumber from './InputNumber'
import { scrollTo, getOffset } from '../../utils'

export const generateGuestsText = (option, total) => {
  if(option) {
    if(option.title) {
      return option.title
    }
    if(!option.divers && !option.nonDivers && !option.students) {
      return '—'
    }
    if(!option.students) {
      option.students = 0
    }

    if(total) {
      return (
        <FormattedMessage
          id='guest-picker.total_guests'
          values={{
            totalGuests: option.divers + option.nonDivers + option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='For {totalGuests, plural, one {# guest} other {# guests}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    }
    if(option.divers > 0 && option.students > 0 && option.nonDivers > 0) {
      return (
        <FormattedMessage
          id='guest-picker.all-selected'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{diversNumber, plural, one {# diver} other {# divers}}, {studentsNumber, plural, one {# student} other {# students}} and {nonDiversNumber, plural, one {# non-diver} other {# non-divers}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(option.divers > 0 && option.students > 0 && !option.nonDivers) {
      return (
        <FormattedMessage
          id='guest-picker.divers_and_students'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{diversNumber, plural, one {# diver} other {# divers}} and {studentsNumber, plural, one {# student} other {# students}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(option.divers > 0 && !option.students && !option.nonDivers) {
      return (
        <FormattedMessage
          id='guest-picker.divers_only'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{diversNumber, plural, one {# diver} other {# divers}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(option.divers > 0 && !option.students && option.nonDivers > 0) {
      return (
        <FormattedMessage
          id='guest-picker.divers_and_non-divers'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{diversNumber, plural, one {# diver} other {# divers}} and {nonDiversNumber, plural, one {# non-diver} other {# non-divers}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(!option.divers && option.students > 0 && option.nonDivers > 0) {
      return (
        <FormattedMessage
          id='guest-picker.student_and_non-diver'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{ studentsNumber, plural, one {# student} other {# students}} and {nonDiversNumber, plural, one {# non-diver} other {# non-divers}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(!option.divers && option.students > 0 && !option.nonDivers) {
      return (
        <FormattedMessage
          id='guest-picker.student'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{ studentsNumber, plural, one {# student} other {# students}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    } else if(!option.divers && !option.students && option.nonDivers > 0) {
      return (
        <FormattedMessage
          id='guest-picker.non-diver'
          values={{
            diversNumber: option.divers,
            nonDiversNumber: option.nonDivers,
            studentsNumber: option.students,
            roomsNumber: option.rooms,
          }}
          defaultMessage='{nonDiversNumber, plural, one {# non-diver} other {# non-divers}} in {roomsNumber, plural, one {# room} other {# rooms}}'
        />
      )
    }
  }
}
export default class GuestPicker extends Component {
  constructor(props) {
    super(props)
    let storageRoomParams = store.get('roomParams')
    if(storageRoomParams.students) {
      storageRoomParams = null
    }
    const roomsAndGuestsParams = get(storageRoomParams, 'details', [
      {
        divers: 2,
        students: 0,
        nonDivers: 0,
        rooms: 1,
      },
    ])
    this.elementRef = createRef()

    if(roomsAndGuestsParams.length && !roomsAndGuestsParams[0].students) {
      roomsAndGuestsParams[0].students = 0
    }

    let selectedRooms = roomsAndGuestsParams.reduce(function(prev, current) {
      return prev + current.divers + current.nonDivers + current.students
    }, 0)

    this.state = {
      isOpen: false,
      selectedRooms: selectedRooms > 0,
      showError: false,
      roomsAndGuests: roomsAndGuestsParams,
      totalText: generateGuestsText(
        storageRoomParams || roomsAndGuestsParams[0]
      ),
    }
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const storageRoomParams = store.get('roomParams')
      const roomsAndGuestsParams = get(storageRoomParams, 'details', [
        {
          divers: 0,
          students: 0,
          nonDivers: 0,
          rooms: 1,
        },
      ])

      if(roomsAndGuestsParams[0] && !roomsAndGuestsParams[0].students) {
        roomsAndGuestsParams[0].students = 0
      }

      let selectedRooms = roomsAndGuestsParams.reduce(function(prev, current) {
        return prev + current.divers + current.nonDivers + current.students
      }, 0)

      this.setState({
        isOpen: false,
        selectedRooms: selectedRooms > 0,
        showError: false,
        roomsAndGuests: roomsAndGuestsParams,
        totalText: generateGuestsText(
          storageRoomParams || roomsAndGuestsParams[0]
        ),
      })
    }
  }

  componentDidMount() {
    document.addEventListener('click', (e) => {
      if(this.state.isOpen) {
        let element = e.target
        let parents = []
        let clickedOutside = true
        while(element) {
          parents.unshift(element)
          element = element.parentNode
        }
        parents.forEach((elem) => {
          if(
            elem.classList &&
            (elem.classList.contains('rooms-popup') ||
              elem.classList.contains('rooms-and-guest-selector') ||
              elem.classList.contains('p-row'))
          ) {
            clickedOutside = false
          }
        })
        if(clickedOutside) {
          this.save()
        }
      }
    })
  }

  handleChange(type, index, value) {
    const currentRoomsAndGuests = clone(this.state.roomsAndGuests)

    currentRoomsAndGuests[index] = {
      ...currentRoomsAndGuests[index],
      [type]: value,
    }

    this.setState({
      roomsAndGuests: currentRoomsAndGuests,
    })
  }

  addRoom = () => {
    const newRoom = {
      divers: 1,
      nonDivers: 0,
      students: 0,
    }
    const currentRoomsAndGuests = clone(this.state.roomsAndGuests)
    currentRoomsAndGuests.push(newRoom)

    this.setState({
      roomsAndGuests: currentRoomsAndGuests,
    })
  }

  delRoom(index) {
    if(this.state.roomsAndGuests.length > 1) {
      const currentRoomsAndGuests = clone(this.state.roomsAndGuests)
      currentRoomsAndGuests.splice(index, 1)
      this.setState({
        roomsAndGuests: currentRoomsAndGuests,
      })
    }
  }

  triggerOpen(isOpen) {
    if(window.innerWidth < 1024) {
      document.body.style.overflow = 'hidden'
    }
    this.setState({
      isOpen,
    })
  }

  save = () => {
    let divers = 0
    let nonDivers = 0
    let students = 0
    this.state.roomsAndGuests.forEach((room, index) => {
      if(!room.divers && !room.nonDivers && !room.students) {
        this.delRoom(index)
      } else {
        divers += room.divers
        nonDivers += room.nonDivers
        students = 0
      }
    })
    if(divers + nonDivers < 1) {
      this.setState({
        showError: true,
      })
      const offset = getOffset(this.elementRef.current).top - 60
      scrollTo(document.documentElement, offset, 150)
    } else {
      this.setState(
        {
          showError: false,
          isOpen: false,
          selectedRooms: true,
        },
        () => {
          const result = {
            id: 'auto',
            rooms: this.state.roomsAndGuests.length,
            divers,
            nonDivers,
            students,
            totalGuests: divers + students + nonDivers,
            guests_split: this.state.roomsAndGuests,
            details: this.state.roomsAndGuests,
          }
          if(typeof this.props.onSelect === 'function') {
            this.props.onSelect(result)
          }
          if(divers || students || nonDivers) {
            this.setState({
              totalText: generateGuestsText(result),
            })
          } else {
            this.setState({
              selectedRooms: false,
            })
          }
          if(result.totalGuests) {
            store('roomParams', result)
          } else {
            store.remove('roomParams')
          }
        }
      )
      document.body.style.overflow = ''
    }
  }

  render() {
    const {
      isOpen,
      selectedRooms,
      totalText,
      roomsAndGuests,
      showError,
    } = this.state
    const {
      showLabel,
      showIcon,
      // noStudentsPackages,
      className,
      hideError,
      isLiveaboard,
    } = this.props
    return (
      <Fragment>
        <div
          smooth-scroll='smooth-scroll'
          scroll-to='.rooms-and-guest-selector'
          className={`rooms-and-guest-selector ${
            !selectedRooms && !hideError ? 'has-error' : ''
          } ${className}`}
          onClick={() => this.triggerOpen(true)}
          ref={this.elementRef}
        >
          {showLabel ? (
            <label>
              <FormattedMessage
                id='guests_and_rooms'
                defaultMessage='Guests & Rooms'
              />
            </label>
          ) : (
            ''
          )}
          <div
            className={`rooms-and-guest-selector__input ${
              showIcon ? 'w_icon' : ''
            }`}
          >
            {showIcon ? <i className='font-icons people-icon' /> : ''}
            <p>
              {!isOpen && !selectedRooms ? (
                <span className='rooms-and-guest-selector__empty'>
                  <FormattedMessage id='guests' defaultMessage='Guests' />
                </span>
              ) : (
                totalText
              )}
            </p>
          </div>
        </div>
        {isOpen ? (
          <div className='rooms-popup-backdrop'>
            <div
              className={`rooms-popup ${
                roomsAndGuests.length > 2 ? 'add-scroll' : ''
              }`}
            >
              <p className='rooms-popup__title'>
                <FormattedMessage
                  id='guest_packages'
                  defaultMessage='Guest packages'
                />
              </p>
              <ul className='rooms'>
                {roomsAndGuests.map((_, index) => {
                  return (
                    <li className='p-row' key={index}>
                      <p className='room-title'>
                        {isLiveaboard ? (
                          <FormattedMessage id='cabin' defaultMessage='Cabin' />
                        ) : (
                          <FormattedMessage id='room' defaultMessage='Room' />
                        )}
                        &nbsp;{index + 1}
                      </p>
                      {index > 0 ? (
                        <span
                          className='del'
                          onClick={() => this.delRoom(index)}
                        >
                          <i className='font-icons close-icon' />
                        </span>
                      ) : (
                        ''
                      )}
                      <div className='guest-row'>
                        <div className='guest-type'>
                          <label htmlFor=''>
                            <FormattedMessage
                              id='divers'
                              defaultMessage='Divers'
                            />
                          </label>
                          <p>
                            <FormattedMessage
                              id='diver_explanation'
                              defaultMessage='Diving holiday for certified divers (Open Water Diver or higher)'
                            />
                          </p>
                        </div>
                        <div className='input-counter'>
                          <InputNumber
                            plusMinusSeparate
                            minNumber={0}
                            maxNumber={99}
                            disabled
                            onChange={(value) =>
                              this.handleChange('divers', index, value)
                            }
                            value={this.state.roomsAndGuests[index].divers}
                          />
                        </div>
                      </div>
                      <div className='guest-row'>
                        <div className='guest-type'>
                          <label htmlFor=''>
                            <FormattedMessage
                              id='non_divers'
                              defaultMessage='Non-divers / Dive courses'
                            />
                          </label>
                          <p>
                            <FormattedMessage
                              id='nondiver_explanation'
                              defaultMessage='Guests who will not be diving, or guests who want to learn to dive'
                            />
                          </p>
                        </div>
                        <div className='input-counter'>
                          <InputNumber
                            plusMinusSeparate
                            minNumber={0}
                            maxNumber={99}
                            disabled
                            onChange={(value) =>
                              this.handleChange('nonDivers', index, value)
                            }
                            value={this.state.roomsAndGuests[index].nonDivers}
                          />
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              <div className='p-row'>
                <a className='add-room' onClick={this.addRoom}>
                  +{' '}
                  {isLiveaboard ? (
                    <FormattedMessage
                      id='add_cabin'
                      defaultMessage='Add another cabin'
                    />
                  ) : (
                    <FormattedMessage
                      id='add_room'
                      defaultMessage='Add another room'
                    />
                  )}
                </a>
              </div>
              {showError ? (
                <div className='p-row error'>
                  <FormattedMessage
                    id='guest_required'
                    defaultMessage='At least one guest must be included in the package. Please update your selection'
                  />
                </div>
              ) : (
                ''
              )}
              <div className='p-row total'>
                {/* <span className='result'><strong>
                <FormattedMessage id='total' defaultMessage='Total' />:&nbsp;{ totalText }</strong></span> */}
                <button className='btn-red btn-done' onClick={this.save}>
                  <FormattedMessage id='done' defaultMessage='Done' />
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}
