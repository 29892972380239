import { useState } from 'react'
import Typeahead from '../helpers/Typeahead'
import LoadingIndicator from '../helpers/LoaderIndicator'

export default function HeaderTypeahead() {
  const [focused, setFocus] = useState(0)
  const [loading, setLoading] = useState(false)

  return (
    <label
      className={`header-search desktop ${focused ? 'search-focused' : ''}`}
      onBlur={() =>
        setTimeout(function() {
          setFocus(false)
        }, 100)
      }
      onFocus={() => setFocus(true)}
    >
      <i className='search-icon font-icons find-icon' onClick={() => setFocus(true)} />
      <Typeahead setLoading={setLoading} />
      {loading && <LoadingIndicator />}

      <i className='font-icons close-icon' />
    </label>
  )
}
