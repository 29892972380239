import PropTypes from 'prop-types'
export default function Loader({ isLoading, size = 'medium', fixed, zIndex, customPosition }) {
  return (
    <div
      className={`ripple-loader${isLoading ? ' show' : ''} ${
        fixed ? 'fixed' : ''
      }`}
      style={{ zIndex }}
    >
      <div className={`ripple ${size} ${fixed ? 'fixed' : ''}`} style={customPosition}>
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  fixed: PropTypes.bool,
  zIndex: PropTypes.number,
  customPosition: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
}
