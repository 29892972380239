export default function ErrorCalendar({ defaultMessage, selector, className, icon }) {
  return (
    <div
      className={className}
      onClick={() => {
        setTimeout(function() {
          document.querySelector(selector).click()
        })
      }}
      smooth-scroll='smooth-scroll'
      scroll-to='#resort-pricing'>
      <i className={icon} />
      {defaultMessage}
    </div>
  )
}
